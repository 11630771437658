<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <span class="input-container">
    <input
      [formControl]="baseTextControl"
      [ngxTimepicker]="timePicker"
      [placeholder]="placeholder"
      aria-label="default time"
      matInput
      readonly
    />
    <ngx-material-timepicker
      #timePicker
      [appendToInput]="true"
      [cancelBtnTmpl]="cancelButtonTemplate"
      [confirmBtnTmpl]="confirmButtonTemplate"
      [format]="12"
      [theme]="allevaTheme"
      (closed)="baseControl.markAsTouched()"
    ></ngx-material-timepicker>
    <alleva-button
      (click)="timePicker.open()"
      [icon]="{
        color: baseControl.touched && baseControl.invalid ? 'warn' : 'accent',
        colorHex: baseControl.disabled ? 'var(--gray-mid-dark)' : undefined,
        name: 'schedule',
      }"
      class="suffix-icon"
      label="Open Time Picker"
      styling="icon"
      matSuffix
    ></alleva-button>
  </span>
  <mat-hint *ngIf="!hideHint && hint" [title]="hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="baseControl.invalid && baseControl.touched">
    <ng-container *ngIf="baseControl.hasError('required')">
      {{ label }} is required.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('min')">
      {{ label }} must be at least {{ baseControl.errors?.['min'].min }}.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('max')">
      {{ label }} cannot exceed {{ baseControl.errors?.['max'].max }}.
    </ng-container>
  </mat-error>
</mat-form-field>

<ng-template #cancelButtonTemplate>
  <alleva-button label="Cancel" styling="stroked"> Cancel </alleva-button>
  <alleva-button color="warn" label="Clear" styling="stroked" (click)="clear()">
    Clear
  </alleva-button>
</ng-template>

<ng-template #confirmButtonTemplate>
  <alleva-button color="accent" label="Save & Close">
    Save & Close
  </alleva-button>
</ng-template>
