import { Observable, catchError, map, of } from 'rxjs';
import { CodeStatus } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the client code status API.
 */
@Injectable()
export class CodeStatusService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return data for all client status codes by client id.
   *
   * @returns All client model allergy data on success, undefined on error.
   */
  public getList(): Observable<readonly CodeStatus[] | undefined> {
    return this.httpClient
      .get<readonly CodeStatus[] | undefined>(`${config.api.url}/code-status`)
      .pipe(
        map((response) =>
          response ? CodeStatus.deserializeList(response) : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
