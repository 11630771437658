import { Observable, catchError, mergeMap, throwError } from 'rxjs';
import { AuthenticatedUser } from 'src/app/models';
import { AuthenticationService } from 'src/app/services';
import { config } from 'src/configs/config';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AlertService } from './services/root/alert.service';

/**
 * A class for intercepting all HTTP requests and responses and applying custom
 * logic to them.
 */
@Injectable()
export class RootHttpInterceptor implements HttpInterceptor {
  public constructor(
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.authenticationService.userChanges.pipe(
      mergeMap((user) => {
        // Handle API requests globally.
        if (user && request.url.startsWith(config.api.url)) {
          // Handle API headers
          request = handleApiHeaders(request, user);

          /**
           * Handle/intercept requests by method.
           *
           * Note: We can add other handlers here for 'DELETE', 'PATCH',
           * 'POST', and 'PUT' as needed in the future.
           */
          switch (request.method) {
            case 'GET': {
              request = handleGetRequestCharacterLimit(
                request,
                this.alertService,
              );
              break;
            }
          }
        }

        return next.handle(request).pipe(
          // Intercept errors and handle them.
          catchError((error: unknown) => {
            if (!(error instanceof HttpErrorResponse)) {
              // Not an HTTP error, rethrow and let the global error handler
              // take care of it.
              return throwError(() => error);
            }

            // Intercept 401 responses and redirect to login page.
            if (error.status === 401) {
              this.authenticationService.logout(undefined, false);
            }

            // Unhandled HTTP error, rethrow and let the global error handler
            // take care of it.
            return throwError(() => error);
          }),
        );
      }),
    );
  }
}

/**
 * Auto assign headers on all API requests.
 *
 * @param request The request to modify.
 * @param user The authenticated user.
 * @returns The modified request.
 */
function handleApiHeaders(
  request: HttpRequest<unknown>,
  user: AuthenticatedUser,
): HttpRequest<unknown> {
  const { isProduction } = config;
  const isLocalHost = window.location.port === '4200';
  const shouldDisableCache = isLocalHost || !isProduction;

  // Provide the API the user's authentication token.
  const authorization = `Bearer ${user.accessToken}`;

  const cacheControl = shouldDisableCache
    ? 'no-cache, no-store, must-revalidate'
    : (request.headers.get('cache-control') ?? '');

  const expires = shouldDisableCache
    ? '0'
    : (request.headers.get('expires') ?? '');

  const pragma = shouldDisableCache
    ? 'no-cache'
    : (request.headers.get('pragma') ?? '');

  return request.clone({
    setHeaders: {
      'Cache-Control': cacheControl,
      Authorization: authorization,
      Expires: expires,
      Pragma: pragma,
    },
  });
}

/**
 * Handle GET requests that exceed the URI character limit.
 *
 * @param request The request to modify.
 * @param alertService The alert service to show error messages.
 * @returns The modified request.
 */
function handleGetRequestCharacterLimit(
  request: HttpRequest<unknown>,
  alertService: AlertService,
): HttpRequest<unknown> {
  const maxUrlLength = config.api.maxUrlLength;
  const fullUrl = request.urlWithParams;

  // Check if the total URL length exceeds the maximum allowed
  if (fullUrl.length > maxUrlLength) {
    const url = new URL(request.url);
    const queryParams = request.params
      .keys()
      // Get query params with potential duplicate keys (e.g. `foo=1&foo=2`)
      .map((key) => [key, request.params.getAll(key)] as [string, string[]]);

    const flattenedParams: Array<[string, string]> = [];
    queryParams.forEach(([key, values]) => {
      values.forEach((value) => {
        if (value !== null) {
          flattenedParams.push([key, value]);
        }
      });
    });

    const truncatedParams = [...flattenedParams];
    let newUrl = `${url.origin}${url.pathname}`;
    const removedParams: Array<[string, string]> = [];

    // Remove query parameters until the full URL length is within the limit
    while (
      newUrl.length +
        (truncatedParams.length
          ? '?' +
            new URLSearchParams(
              truncatedParams as Array<[string, string]>,
            ).toString()
          : ''
        ).length >
        maxUrlLength &&
      truncatedParams.length > 0
    ) {
      // Remove last in array
      const removedParam = truncatedParams.pop();
      if (removedParam) {
        // Add removed param for tracking
        removedParams.push(removedParam);
      }
    }

    if (removedParams.length > 0) {
      // Log a warning if any query parameters were removed that gives more
      // detailed information for us devs.
      console.warn(
        `URL length exceeded the maximum allowed for "${fullUrl}". The ` +
          'following parameters were removed:',
        removedParams.map(([key, value]) => `${key}=${value}`),
      );

      // Show error toast message to the user
      alertService.error({
        message:
          'Request too large, please simplify your request and try again or contact support.',
      });

      // Rebuild the URL with the truncated parameters
      if (truncatedParams.length > 0) {
        newUrl +=
          '?' +
          new URLSearchParams(
            truncatedParams as Array<[string, string]>,
          ).toString();
      }

      return request.clone({
        params: new HttpParams(),
        url: newUrl,
      });
    }
  }

  return request;
}
