import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, firstValueFrom } from 'rxjs';
import { VALIDATION } from 'src/app/constants';
import {
  MeasurementSystemEnum,
  TemperatureUnitsEnum,
} from 'src/app/enumerators';
import { VitalsTemperature } from 'src/app/models';
import { FacilityService } from 'src/app/services';
import {
  getCelsiusFrom,
  getFahrenheitFrom,
  isNonEmptyValue,
} from 'src/app/utilities';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormControl, FormGroup, NgControl, Validators } from '@angular/forms';

import { CustomFormGroupDirective } from 'src/app/components/forms/custom-form-group.directive';

/**
 * An input component for tracking temperature.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-input-temperature[formControl][label]',
  templateUrl: './input-temperature.component.html',
  styleUrls: ['./input-temperature.component.scss'],
})
export class InputTemperatureComponent
  extends CustomFormGroupDirective<VitalsTemperature>
  implements OnInit
{
  public constructor(
    @Optional() @Self() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    private readonly facilityService: FacilityService,
  ) {
    super(ngControl, changeDetectorRef, elementRef);
  }

  /**
   * The id of the units form control, automatically generated if not provided.
   */
  @Input()
  public unitsId =
    `alleva-input-temperature-units${++uniqueTemperatureUnitsFormId}`;

  /**
   * The id of the value form control, automatically generated if not provided.
   */
  @Input()
  public valueId =
    `alleva-input-temperature-value${++uniqueTemperatureValueFormId}`;

  // Override the following defaults to undefined to show they're unused here.
  public override placeholder = undefined;

  protected readonly TemperatureUnitsEnum = TemperatureUnitsEnum;

  protected override readonly formGroup = new FormGroup<TemperatureFormGroup>({
    units: new FormControl<VitalsTemperature['units'] | null>(null, [
      Validators.required,
    ]),
    value: new FormControl<VitalsTemperature['value'] | null>(null, [
      // Validation based on unit type is set on init.
      Validators.required,
    ]),
  });

  protected readonly ctrlUnits = this.formGroup.controls.units;
  protected readonly ctrlValue = this.formGroup.controls.value;

  public override async ngOnInit(): Promise<void> {
    super.ngOnInit();

    if (!this.baseControl) {
      throw new Error(
        'InputTemperatureComponent requires a formControl attribute to be set.',
      );
    }

    const facility = await firstValueFrom(
      this.facilityService.currentFacilityChanges,
    );
    const facilityMeasurementSystem = facility.settings.measurementSystem;
    let facilityTemperatureUnits: TemperatureUnitsEnum | null = null;

    switch (facilityMeasurementSystem) {
      case MeasurementSystemEnum.AMERICAN:
      case MeasurementSystemEnum.IMPERIAL:
        facilityTemperatureUnits = TemperatureUnitsEnum.FAHRENHEIT;
        break;
      case MeasurementSystemEnum.METRIC:
        facilityTemperatureUnits = TemperatureUnitsEnum.CELSIUS;
        break;
      default:
        throw new Error(
          `Unhandled measurement system for temperature: ${facilityMeasurementSystem}`,
        );
    }

    const currentTemperature = this.baseControl.value;
    const currentTemperatureUnits =
      currentTemperature?.units ?? facilityTemperatureUnits;
    let currentTemperatureValue = currentTemperature?.value ?? null;

    // Convert the current value to the facility's measurement system.
    if (
      currentTemperature &&
      currentTemperatureValue &&
      currentTemperatureUnits !== facilityTemperatureUnits
    ) {
      switch (facilityTemperatureUnits) {
        case TemperatureUnitsEnum.FAHRENHEIT: {
          switch (currentTemperatureUnits) {
            case TemperatureUnitsEnum.FAHRENHEIT: {
              break; // Do nothing, the value is already in Fahrenheit.
            }
            case TemperatureUnitsEnum.CELSIUS: {
              currentTemperatureValue = getFahrenheitFrom({
                celsius: currentTemperatureValue,
              });
              break;
            }
          }
          break;
        }
        case TemperatureUnitsEnum.CELSIUS: {
          switch (currentTemperatureUnits) {
            case TemperatureUnitsEnum.CELSIUS: {
              break; // Do nothing, the value is already in Celsius.
            }
            case TemperatureUnitsEnum.FAHRENHEIT: {
              currentTemperatureValue = getCelsiusFrom({
                fahrenheit: currentTemperatureValue,
              });
              break;
            }
          }
          break;
        }
        default: {
          throw new Error(
            'Unhandled facility measurement system' +
              `"${facilityMeasurementSystem}" for temperature.`,
          );
        }
      }
    }

    // Set initial values.
    this.formGroup.setValue(
      {
        units: facilityTemperatureUnits,
        value: currentTemperatureValue,
      },
      { emitEvent: false },
    );

    if (!this.baseControl.hasValidator(Validators.required)) {
      this.ctrlValue.removeValidators(Validators.required);
      this.ctrlUnits.removeValidators(Validators.required);
    }

    // Set initial validators based on facility temperature units.
    switch (facilityTemperatureUnits) {
      default:
      case TemperatureUnitsEnum.FAHRENHEIT: {
        this.ctrlValue.addValidators([
          Validators.min(VALIDATION.vitals.temperature.fahrenheit.min),
          Validators.max(VALIDATION.vitals.temperature.fahrenheit.max),
        ]);
        break;
      }
      case TemperatureUnitsEnum.CELSIUS: {
        this.ctrlValue.addValidators([
          Validators.min(VALIDATION.vitals.temperature.celsius.min),
          Validators.max(VALIDATION.vitals.temperature.celsius.max),
        ]);
        break;
      }
    }

    this.ctrlValue.updateValueAndValidity();
    this.ctrlValue.markAsUntouched();

    this.ctrlUnits.updateValueAndValidity();
    this.ctrlUnits.markAsUntouched();

    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged(
          (
            { units: oldUnits, value: oldValue },
            { units: newUnits, value: newValue },
          ) => oldUnits === newUnits && oldValue === newValue,
        ),
        untilDestroyed(this),
      )
      .subscribe(({ units, value }) => {
        if (
          isNonEmptyValue(units) &&
          isNonEmptyValue(value) &&
          this.formGroup.valid
        ) {
          this.baseControl.patchValue({ units, value });
          this.baseControl.markAsDirty();
        } else if (!isNonEmptyValue(units) && !isNonEmptyValue(value)) {
          this.baseControl.patchValue(null);
          this.baseControl.markAsPristine();
        } else {
          this.baseControl.patchValue(null, { emitEvent: false });
          this.baseControl.markAsPristine();
        }
      });

    // Dynamically update form validation based on unit changes.
    this.formGroup.controls.units.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((units) => {
        if (units === TemperatureUnitsEnum.FAHRENHEIT) {
          this.formGroup.controls.value.setValidators([
            Validators.required,
            Validators.min(VALIDATION.vitals.temperature.fahrenheit.min),
            Validators.max(VALIDATION.vitals.temperature.fahrenheit.max),
          ]);
        } else if (units === TemperatureUnitsEnum.CELSIUS) {
          this.formGroup.controls.value.setValidators([
            Validators.required,
            Validators.min(VALIDATION.vitals.temperature.celsius.min),
            Validators.max(VALIDATION.vitals.temperature.celsius.max),
          ]);
        } else {
          this.formGroup.controls.units.clearValidators();
          this.formGroup.controls.value.clearValidators();
        }
        this.formGroup.controls.units.updateValueAndValidity();
        this.formGroup.controls.value.updateValueAndValidity();
      });

    // If any values are invalid (cm, ft, in, unit), mark the baseControl as invalid.
    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.formGroup.invalid) {
        this.baseControl.setErrors({ required: true }, { emitEvent: false });
        this.baseControl.markAsTouched();
        this.baseControl.markAsDirty();
      } else {
        this.baseControl.setErrors(null, { emitEvent: false });
      }
    });
  }
}

interface TemperatureFormGroup {
  units: FormControl<VitalsTemperature['units'] | null>;
  value: FormControl<VitalsTemperature['value'] | null>;
}

/** A unique ID for each form field temperature `units` input. */
let uniqueTemperatureUnitsFormId = 0;

/** A unique ID for each form field temperature `value` input. */
let uniqueTemperatureValueFormId = 0;
