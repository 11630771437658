<div class="inputs-container">
  <mat-form-field [appearance]="'outline'" class="weight-value">
    <mat-label>Weight</mat-label>
    <div class="prefix-icon-container">
      <alleva-icon
        [colorHex]="baseControl.disabled ? 'var(--gray-mid-dark)' : undefined"
        [color]="hasErrors ? 'warn' : 'primary'"
        class="prefix-icon"
        name="weight"
      ></alleva-icon>
    </div>
    <span class="input-container">
      <input
        [formControl]="formGroup.controls['value']"
        [id]="valueId"
        [required]="required"
        autocomplete="off"
        matInput
        name="Weight"
        type="number"
      />
    </span>
  </mat-form-field>
  <mat-form-field [appearance]="'outline'" class="weight-units">
    <mat-label>Units</mat-label>
    <span class="input-container">
      <mat-select
        [formControl]="formGroup.controls['units']"
        [id]="unitsId"
        [multiple]="false"
        [required]="required"
        name="Units"
      >
        <mat-option [value]="WeightUnitsEnum.POUNDS">
          {{ WeightUnitsEnum.POUNDS }}
        </mat-option>
        <mat-option [value]="WeightUnitsEnum.KILOGRAMS" disabled>
          {{ WeightUnitsEnum.KILOGRAMS }}
        </mat-option>
        <mat-option [value]="null">None</mat-option>
      </mat-select>
    </span>
  </mat-form-field>
</div>
<!-- HINT -->
<mat-hint
  *ngIf="!hasErrors && !hideHint && hint; else errorsTemplate"
  [title]="hint"
  >{{ hint }}</mat-hint
>
<!-- ERRORS -->
<ng-template #errorsTemplate>
  <div class="errors-container" *ngIf="hasErrors || !hideHint">
    <!-- WEIGHT VALUE INPUT VALIDATION ERRORS -->
    <ng-container *ngIf="ctrlValue.invalid && ctrlValue.touched">
      <mat-error>
        <ng-container *ngIf="ctrlValue.hasError('required')">
          Weight is required.
        </ng-container>
        <ng-container *ngIf="ctrlValue.hasError('min')">
          Weight must be at least {{ ctrlValue.errors?.['min'].min }}.
        </ng-container>
        <ng-container *ngIf="ctrlValue.hasError('max')">
          Weight cannot exceed {{ ctrlValue.errors?.['max'].max }}.
        </ng-container>
      </mat-error>
    </ng-container>
    <!-- WEIGHT UNITS INPUT VALIDATION ERRORS -->
    <ng-container *ngIf="ctrlUnits.invalid && ctrlUnits.touched">
      <mat-error *ngIf="ctrlValue.valid || ctrlValue.untouched">
        <ng-container *ngIf="ctrlUnits.hasError('required')">
          Units is required.
        </ng-container>
      </mat-error>
    </ng-container>
  </div>
</ng-template>
