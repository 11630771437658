<div class="inputs-container">
  <mat-form-field [appearance]="'outline'" class="systolic">
    <mat-label>Systolic</mat-label>
    <div class="prefix-icon-container">
      <alleva-icon
        [colorHex]="baseControl.disabled ? 'var(--gray-mid-dark)' : undefined"
        [color]="hasErrors ? 'warn' : 'primary'"
        class="prefix-icon"
        name="blood_pressure_alt"
      ></alleva-icon>
    </div>
    <span class="input-container">
      <input
        [formControl]="formGroup.controls['systolic']"
        [id]="systolicId"
        [required]="required"
        autocomplete="off"
        matInput
        name="Systolic"
        type="number"
      />
    </span>
  </mat-form-field>
  <mat-form-field [appearance]="'outline'" class="diastolic">
    <mat-label>Diastolic</mat-label>
    <span class="input-container">
      <input
        [formControl]="formGroup.controls['diastolic']"
        [id]="diastolicId"
        [required]="required"
        autocomplete="off"
        matInput
        name="Diastolic"
        type="number"
      />
    </span>
  </mat-form-field>
</div>
<!-- HINT -->
<mat-hint
  *ngIf="!hasErrors && !hideHint && hint; else errorsTemplate"
  [title]="hint"
  >{{ hint }}</mat-hint
>
<!-- ERRORS -->
<ng-template #errorsTemplate>
  <div class="errors-container" *ngIf="hasErrors || !hideHint">
    <!-- SYSTOLIC INPUT VALIDATION ERRORS -->
    <ng-container *ngIf="ctrlSystolic.invalid && ctrlSystolic.touched">
      <mat-error>
        <ng-container *ngIf="ctrlSystolic.hasError('required')">
          Systolic is required.
        </ng-container>
        <ng-container *ngIf="ctrlSystolic.hasError('min')">
          Systolic must be at least {{ ctrlSystolic.errors?.['min'].min }}.
        </ng-container>
        <ng-container *ngIf="ctrlSystolic.hasError('max')">
          Systolic cannot exceed {{ ctrlSystolic.errors?.['max'].max }}.
        </ng-container>
      </mat-error>
    </ng-container>
    <!-- DIASTOLIC INPUT VALIDATION ERRORS -->
    <ng-container *ngIf="ctrlDiastolic.invalid && ctrlDiastolic.touched">
      <mat-error *ngIf="ctrlSystolic.valid || ctrlSystolic.untouched">
        <ng-container *ngIf="ctrlDiastolic.hasError('required')">
          Diastolic is required.
        </ng-container>
        <ng-container *ngIf="ctrlDiastolic.hasError('min')">
          Diastolic must be at least {{ ctrlDiastolic.errors?.['min'].min }}.
        </ng-container>
        <ng-container *ngIf="ctrlDiastolic.hasError('max')">
          Diastolic cannot exceed {{ ctrlDiastolic.errors?.['max'].max }}.
        </ng-container>
      </mat-error>
    </ng-container>
  </div>
</ng-template>
