import { ClientCOWSApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  Client,
  ClientCOWS,
  ClientCOWSDeserializationArgs,
  ClientCOWSUpdate,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Client COWS API.
 */
@Injectable()
export class ClientCOWSService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Create a new client COWS assessment.
   *
   * @param clientId The client id to create the COWS assessment object for.
   * @param clientCOWS The COWS assessment object to create.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The created COWS assessment object on success, undefined on error.
   */
  public create(
    clientId: Client['id'],
    clientCOWSUpdate: ClientCOWSUpdate,
    deserializationArgs: ClientCOWSDeserializationArgs,
  ): Observable<ClientCOWS | undefined> {
    return this.httpClient
      .post<
        ClientCOWSApi | undefined
      >(`${config.api.url}/clients/${clientId}/cows`, clientCOWSUpdate.serialize())
      .pipe(
        map((response) =>
          response
            ? ClientCOWS.deserialize(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Delete the given client COWS by id.
   *
   * @param clientId The associated client id of the COWS to delete.
   * @param clientCowsId The id of the client COWS to delete.
   * @returns True on success, false on error.
   */
  public delete(
    clientId: Client['id'],
    clientCOWSId: ClientCOWS['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(
        `${config.api.url}/clients/${clientId}/cows/${clientCOWSId}`,
      )
      .pipe(
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Fetch and return all client COWS history data for the given client.
   *
   * @param clientId The client id to get the COWS history for.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The client COWS history on success, undefined on error.
   */
  public getAll(
    clientId: Client['id'],
    deserializationArgs: ClientCOWSDeserializationArgs,
  ): Observable<readonly ClientCOWS[] | undefined> {
    return this.httpClient
      .get<
        readonly ClientCOWSApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/cows`)
      .pipe(
        map((response) =>
          response
            ? ClientCOWS.deserializeList(response, deserializationArgs)
            : [],
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Patch a client's COWS assessment record.
   *
   * @param clientId The client id to patch the COWS assessment object for.
   * @param clientCOWSId The client COWS assessment object id to patch.
   * @param clientCOWSUpdate The updated COWS assessment object.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The updated COWS assessment object on success, undefined on error.
   */
  public update(
    clientId: Client['id'],
    clientCOWSId: ClientCOWS['id'],
    clientCOWSUpdate: ClientCOWSUpdate,
    deserializationArgs: ClientCOWSDeserializationArgs,
  ): Observable<ClientCOWS | undefined> {
    return this.httpClient
      .patch<
        ClientCOWSApi | undefined
      >(`${config.api.url}/clients/${clientId}/cows/${clientCOWSId}`, clientCOWSUpdate.serialize())
      .pipe(
        map((response) =>
          response
            ? ClientCOWS.deserialize(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
