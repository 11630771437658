<div class="inputs-container">
  <ng-container [ngSwitch]="formGroup.controls['units'].value">
    <!-- CENTIMETERS -->
    <mat-form-field
      *ngSwitchCase="HeightUnitsEnum.CENTIMETERS"
      [appearance]="'outline'"
      class="height-value cm"
    >
      <mat-label>{{ label }} (cm)</mat-label>
      <ng-template
        [ngTemplateOutlet]="prefixIconContainerTemplate"
      ></ng-template>
      <span class="input-container">
        <input
          [formControl]="formGroup.controls['centimeters']"
          [id]="centimetersId"
          [required]="required"
          [step]="0.1"
          autocomplete="off"
          matInput
          name="Height (cm)"
          type="number"
        />
      </span>
    </mat-form-field>
    <!-- FEET/INCHES -->
    <ng-container *ngSwitchCase="HeightUnitsEnum.INCHES">
      <!-- FEET -->
      <mat-form-field [appearance]="'outline'" class="height-value ft">
        <mat-label>Feet</mat-label>
        <ng-template
          [ngTemplateOutlet]="prefixIconContainerTemplate"
        ></ng-template>
        <span class="input-container">
          <input
            [formControl]="formGroup.controls['feet']"
            [id]="feetId"
            [required]="required"
            [step]="1"
            matInput
            name="Feet"
            type="number"
          />
        </span>
      </mat-form-field>
      <!-- INCHES -->
      <mat-form-field [appearance]="'outline'" class="height-value in">
        <mat-label>Inches</mat-label>
        <span class="input-container">
          <input
            [formControl]="formGroup.controls['inches']"
            [id]="inchesId"
            [required]="required"
            [step]="1"
            matInput
            name="Inches"
            type="number"
          />
        </span>
      </mat-form-field>
    </ng-container>
    <!-- EMPTY -->
    <mat-form-field
      *ngSwitchDefault
      [appearance]="'outline'"
      class="height-value empty"
    >
      <mat-label>Height</mat-label>
      <ng-template
        [ngTemplateOutlet]="prefixIconContainerTemplate"
      ></ng-template>
      <span class="input-container">
        <input
          [disabled]="baseControl.disabled"
          [id]="valueId"
          [required]="required"
          [value]="null"
          autocomplete="off"
          matInput
          name="Height"
          type="number"
        />
      </span>
    </mat-form-field>
  </ng-container>
  <!-- UNITS -->
  <mat-form-field
    [appearance]="'outline'"
    [class.cm]="
      formGroup.controls['units'].value === HeightUnitsEnum.CENTIMETERS
    "
    [class.empty]="formGroup.controls['units'].value === null"
    [class.ft-in]="formGroup.controls['units'].value === HeightUnitsEnum.INCHES"
    class="height-units"
  >
    <mat-label>Units</mat-label>
    <span class="input-container">
      <mat-select
        [formControl]="formGroup.controls['units']"
        [id]="unitsId"
        [multiple]="false"
        [required]="required"
        name="Units"
      >
        <mat-option [value]="HeightUnitsEnum.INCHES">ft/in</mat-option>
        <mat-option [value]="HeightUnitsEnum.CENTIMETERS">
          {{ HeightUnitsEnum.CENTIMETERS }}
        </mat-option>
        <mat-option [value]="null">None</mat-option>
      </mat-select>
    </span>
  </mat-form-field>
</div>
<!-- HINT -->
<mat-hint
  *ngIf="!hasErrors && !hideHint && hint; else errorsTemplate"
  [title]="hint"
  >{{ hint }}</mat-hint
>

<!-- ERRORS -->
<ng-template #errorsTemplate>
  <div
    class="errors-container"
    *ngIf="formGroup.invalid && (formGroup.dirty || formGroup.touched)"
  >
    @if (
      ctrls.units.value === HeightUnitsEnum.CENTIMETERS &&
      (ctrls.centimeters.touched || ctrls.centimeters.dirty)
    ) {
      <!-- CENTIMETERS INPUT VALIDATION ERRORS -->
      @if (ctrls.centimeters.errors) {
        @if (ctrls.centimeters.hasError('required')) {
          <mat-error>Centimeter value is required.</mat-error>
        } @else if (ctrls.centimeters.hasError('min')) {
          <mat-error
            >Centimeters must be at least
            {{
              ctrls.centimeters.errors['min'].min | number: '1.2-2'
            }}.</mat-error
          >
        }
      }
    } @else if (
      ctrls.units.value === HeightUnitsEnum.INCHES &&
      (ctrls.feet.touched ||
        ctrls.inches.touched ||
        ctrls.feet.dirty ||
        ctrls.inches.dirty)
    ) {
      <!-- INCHES/FEET INPUT VALIDATION ERRORS -->
      @if (ctrls.feet.errors) {
        @if (ctrls.feet.hasError('required')) {
          <mat-error>Feet value is required.</mat-error>
        } @else if (ctrls.feet.hasError('min')) {
          <mat-error
            >Feet must be at least
            {{ ctrls.feet.errors['min'].min | number }}.</mat-error
          >
        }
      } @else if (ctrls.inches.errors) {
        @if (ctrls.inches.hasError('required')) {
          <mat-error>Inches value is required.</mat-error>
        } @else if (ctrls.inches.hasError('min')) {
          <mat-error
            >Inches must be at least
            {{ ctrls.inches.errors['min'].min | number }}.</mat-error
          >
        }
      }
    } @else if (ctrls.units.touched && ctrls.units.errors) {
      <!-- UNITS VALIDATION ERRORS -->
      @if (ctrls.units.hasError('required')) {
        <mat-error>Units are required.</mat-error>
      }
    }
  </div>
</ng-template>

<ng-template #prefixIconContainerTemplate>
  <div class="prefix-icon-container">
    <alleva-icon
      [colorHex]="baseControl.disabled ? 'var(--gray-mid-dark)' : undefined"
      [color]="hasErrors ? 'warn' : 'primary'"
      class="prefix-icon"
      name="height"
    ></alleva-icon>
  </div>
</ng-template>
