import { PriorityApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { NameId } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class InsurancePrioritiesService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Gets the insurance priorities list from the API.
   *
   * @returns The insurance priorities, undefined on error.
   */
  public getList(): Observable<readonly NameId[] | undefined> {
    return this.httpClient
      .get<
        readonly PriorityApi[] | undefined
      >(`${config.api.url}/insurance/priorities`)
      .pipe(
        map((response) =>
          response ? NameId.deserializeList(response) : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
