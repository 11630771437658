import { ALLEVA_ROUTES } from 'src/app/constants';
import {
  LegacyRouteGuard,
  UserPermissionGuard,
  authenticationGuard,
  facilityGuard,
  facilityRedirectGuard,
  screenLockGuard,
} from 'src/app/guards';
import { ClientResolverService, ClientsService } from 'src/app/services';
import { config } from 'src/configs/config';

import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule } from '@angular/router';

import { LoadingIndicatorComponent } from 'src/app/components/loading-indicator/loading-indicator.component';

const rootRoutingOptions: ExtraOptions = {
  // Set `true` for local debugging of router events.
  enableTracing: false,
  // We need to enable this so any page that we allow the facility selector to
  // be on can be refreshed/reloaded with the new facility details.
  onSameUrlNavigation: 'reload',
};

const homeRoute: PageRoute = config.isLegacyRoutingEnabled
  ? '/clients/list'
  : '/dashboard';
const notFoundRoute: PageRoute = '/not-found';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: homeRoute,
        },
        // The 404 (not found) page.
        ALLEVA_ROUTES.unauthenticated['/not-found'],
        // Routes that do not require authentication but must have routed
        // facility data in order for the page to be displayed correctly.
        {
          canActivate: [facilityGuard()],
          children: Object.values([
            ALLEVA_ROUTES.unauthenticated['/forbidden'],
          ]),
          path: 'facility/:facilityId',
          pathMatch: 'prefix',
        },
        // Routes that require authentication and being prefixed with facility.
        {
          canActivate: [
            authenticationGuard(),
            facilityGuard(),
            UserPermissionGuard,
            screenLockGuard(),
          ],
          children: Object.values(ALLEVA_ROUTES.authenticated),
          path: 'facility/:facilityId',
          pathMatch: 'prefix',
        },
        // Route rewrites.
        ...ALLEVA_ROUTES.rewrites,
        // A catch all for authenticated, facility redirected routes.
        {
          canActivate: [
            authenticationGuard(),
            facilityRedirectGuard(),
            UserPermissionGuard,
            screenLockGuard(),
          ],
          component: LoadingIndicatorComponent,
          path: '**',
        },
        // The catch all for the remaining, unhandled routes. Redirect to 404.
        {
          path: '**',
          redirectTo: notFoundRoute,
        },
      ],
      rootRoutingOptions,
    ),
  ],
  exports: [RouterModule],
  providers: [ClientResolverService, ClientsService, LegacyRouteGuard],
})
export class RootRoutingModule {}
