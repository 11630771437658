import { InsuranceCompanyApi, InsuranceCompanyPagedListApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  InsuranceCompany,
  InsuranceCompanyCreate,
  InsuranceCompanyUpdate,
} from 'src/app/models';
import { PagedListCodec, decode, parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class InsuranceCompaniesService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Get the insurance company paged list from the API.
   *
   * @param requestParameters The request parameters to use for the API call.
   * @returns The insurance companies paged list, or undefined on error.
   */
  public getPagedList(
    requestParameters?: readonly InsuranceCompanyPagedListParam[],
  ): Observable<PagedList<InsuranceCompany> | undefined> {
    // If both 'active' true and false are provided, remove the 'active' key.
    if (
      requestParameters?.some(
        (param) => param.key === 'active' && param.value === true,
      ) &&
      requestParameters?.some(
        (param) => param.key === 'active' && param.value === false,
      )
    ) {
      requestParameters = requestParameters.filter(
        (param) => param.key !== 'active',
      );
    }
    return this.httpClient
      .get<
        InsuranceCompanyPagedListApi | undefined
      >(`${config.api.url}/insurance/companies`, { params: parseHttpParams(requestParameters) })
      .pipe(
        map((response) => {
          if (response === undefined) {
            return undefined;
          }

          const insuranceCompanyPagedList: PagedList<InsuranceCompany> = {
            ...decode(InsuranceCompanyPagedListCodec, response),
            results: InsuranceCompany.deserializeList(response.results),
          };
          return insuranceCompanyPagedList;
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Add a new insurance company record.
   *
   * @returns The insurance company post response, or undefined when an
   * unhandled error occurs.
   */
  public post(
    insuranceCompanyCreate: InsuranceCompanyCreate,
  ): Observable<InsuranceCompany | HttpErrorResponseApi> {
    return this.httpClient
      .post<InsuranceCompanyApi>(
        `${config.api.url}/insurance/companies`,
        insuranceCompanyCreate.serialize(),
        { observe: 'response' },
      )
      .pipe(
        map((response) => {
          if (!response.body) {
            throw new Error(
              'Insurance Company POST response from API is empty!',
            );
          }
          return InsuranceCompany.deserialize(response.body);
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of(error);
          }
          throw error;
        }),
      );
  }

  /**
   * Update an Insurance Company resource.
   *
   * @param insuranceCompanyUpdate The Insurance Company update data.
   * @returns The successfully updated Insurance Company, or undefined on
   * error.
   */
  public put(
    insuranceCompanyUpdate: InsuranceCompanyUpdate,
  ): Observable<InsuranceCompany | HttpErrorResponseApi> {
    return this.httpClient
      .put<InsuranceCompany>(
        `${config.api.url}/insurance/companies/${insuranceCompanyUpdate.id}`,
        insuranceCompanyUpdate.serialize(),
        { observe: 'response' },
      )
      .pipe(
        map((response) => {
          if (!response.body) {
            throw new Error(
              'Insurance Company PUT response from API is empty!',
            );
          }
          return InsuranceCompany.deserialize(response.body);
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of(error);
          }
          throw error;
        }),
      );
  }
}

const InsuranceCompanyPagedListCodec = PagedListCodec(
  InsuranceCompany.Codec,
  'InsuranceCompanyPagedListApi',
);

/** Request parameter interface for use with the Insurance Company API. */
export interface InsuranceCompanyPagedListParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'active' | 'facilityId' | 'order' | 'pageNumber' | 'pageSize' | 'search';
  /** The value to use for the query parameter. */
  value: boolean | number | string | keyof InsuranceCompanyApi;
}
