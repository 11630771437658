import { ClientApi } from 'api/models';
import { Observable, catchError, map, of, withLatestFrom } from 'rxjs';
import { AuthenticatedUser, Client, FileAttachment } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FacilityService } from './root/facility.service';

@Injectable()
export class FilesService {
  public constructor(
    private readonly facilityService: FacilityService,
    private readonly httpClient: HttpClient,
  ) {}

  /**
   * Get a file from a file attachment.
   *
   * @param fileAttachment The file attachment to download.
   * @param user The user to download the file for.
   * @returns The file or undefined if there was an error.
   */
  public getFileFromAttachment(
    fileAttachment: FileAttachment,
    user: AuthenticatedUser,
  ): Observable<File | undefined> {
    return this.httpClient
      .get(fileAttachment.path, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      })
      .pipe(
        map(
          (blob) =>
            new File([blob], fileAttachment.fileName, { type: blob.type }),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Upload and replace the current client image with a new one.
   *
   * @param clientId The client ID to upload the image for.
   * @param file The file to upload.
   * @returns The updated client on success, undefined on error.
   */
  public postClientImage(
    clientId: Client['id'],
    file: File,
  ): Observable<Client | undefined> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient
      .post<
        ClientApi | undefined
      >(`${config.api.url}/clients/${clientId}/image`, formData)
      .pipe(
        withLatestFrom(this.facilityService.currentFacilityChanges),
        map(([result, currentFacility]) =>
          result
            ? Client.deserialize(result, {
                facilityTimeZone: currentFacility.timeZone,
              })
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
