import { InsurancePlanApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { InsurancePlan } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class InsurancePlansService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Gets the insurance plans list from the API.
   *
   * @returns The insurance plans, undefined on error.
   */
  public getList(): Observable<readonly InsurancePlan[] | undefined> {
    return this.httpClient
      .get<
        readonly InsurancePlanApi[] | undefined
      >(`${config.api.url}/insurance/plans`)
      .pipe(
        map((response) =>
          response ? InsurancePlan.deserializeList(response) : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Gets a specified insurance plan from the API by id.
   *
   * @returns The insurance plan, undefined on error.
   */
  public get(
    insurancePlanId: InsurancePlan['id'],
  ): Observable<InsurancePlan | undefined> {
    return this.httpClient
      .get<
        InsurancePlanApi | undefined
      >(`${config.api.url}/insurance/plan/${insurancePlanId}`)
      .pipe(
        map((response) =>
          response ? InsurancePlan.deserialize(response) : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
