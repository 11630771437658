import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, map } from 'rxjs';
import { FacilityBase } from 'src/app/models';
import {
  AlertService,
  AuthenticationService,
  FacilityService,
  ScreenLockService,
} from 'src/app/services';
import {
  getFirstNonEmptyValueFrom,
  isNonEmptyValue,
  shareSingleReplay,
} from 'src/app/utilities';
import { config } from 'src/configs/config';

import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

/**
 * A component that displays the user's image, name, and various actionable
 * items such as switching facilities and logging out.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-layout-navbar-user-actions',
  templateUrl: './layout-navbar-user-actions.component.html',
  styleUrls: ['./layout-navbar-user-actions.component.scss'],
})
export class LayoutNavbarUserActionsComponent implements OnInit {
  public constructor(
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly facilityService: FacilityService,
    private readonly router: Router,
    private readonly screenLockService: ScreenLockService,
  ) {}

  protected readonly isScreenAutoLockEnabled =
    config.features.isScreenAutoLockEnabled;

  protected readonly availableFacilityBaseChanges =
    this.authenticationService.userChanges.pipe(
      filter(isNonEmptyValue),
      map((user) => user.facilities.filter((facility) => facility.active)),
    );

  /**
   * Whether the user actions should use a collapsed view.
   */
  @HostBinding('class.is-collapsed') @Input() public isCollapsed = false;

  protected isLoggingOut = false;

  protected readonly facilityBaseControl = new FormControl<FacilityBase | null>(
    null,
  );

  private readonly currentFacilityChanges =
    this.facilityService.currentFacilityChanges.pipe(shareSingleReplay());

  protected readonly settingsProfileAccountUrlChanges =
    this.currentFacilityChanges.pipe(
      map((currentFacility) => {
        return (
          `/facility/${currentFacility.id}` +
          ('/settings/profile' satisfies PageRoute)
        );
      }),
    );

  protected readonly userChanges = this.authenticationService.userChanges;

  public async ngOnInit(): Promise<void> {
    let currentFacility = await getFirstNonEmptyValueFrom(
      this.currentFacilityChanges,
    );
    const availableBaseFacilities = await getFirstNonEmptyValueFrom(
      this.availableFacilityBaseChanges,
    );
    const facilityBaseMatch = availableBaseFacilities?.find(
      (facility) => facility.id === currentFacility.id,
    );

    if (!facilityBaseMatch) {
      this.alertService.error({
        message:
          'User Facility cannot be found, please try again or contact support.',
      });
      await this.authenticationService.logout();
      return;
    }

    // Initially set the facility control to the facility that matches the
    // user's facility.
    this.facilityBaseControl.setValue(facilityBaseMatch);

    // Subscribe to changes to the facility control and route the user to the
    // facility's dashboard.
    this.facilityBaseControl.valueChanges
      .pipe(
        filter(isNonEmptyValue),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(async (facilityBase) => {
        if (currentFacility !== facilityBase) {
          const switchSuccess = await this.facilityService.setFacilityById(
            facilityBase.id,
          );

          if (!switchSuccess) {
            this.alertService.error({
              message:
                'Failed to switch facilities, please try again or contact support.',
            });
            this.facilityBaseControl.setValue(currentFacility, {
              emitEvent: false,
            });
            return;
          }

          currentFacility = await getFirstNonEmptyValueFrom(
            this.currentFacilityChanges,
          );
          // Route to the facility's dashboard.
          const dashboardUrl: PageRoute = '/dashboard';
          this.router.navigate([dashboardUrl]);
        }
      });

    // Subscribe to the applications current facility changes and update the
    // facility control to the new facility when necessary.
    this.currentFacilityChanges
      .pipe(filter(isNonEmptyValue), untilDestroyed(this))
      .subscribe((facility) => {
        if (currentFacility !== facility) {
          this.facilityBaseControl.setValue(
            {
              active: facility.active,
              id: facility.id,
              name: facility.name,
            },
            { emitEvent: false },
          );
          currentFacility = facility;
        }
      });
  }

  protected async lockScreen(): Promise<void> {
    this.screenLockService.isLocked = true;
  }

  protected logout(): void {
    this.isLoggingOut = true;
    this.authenticationService.logout().finally(() => {
      this.isLoggingOut = false;
    });
  }
}
