import {
  BillingRuleApi,
  BillingRuleClaimTypeOfBillDetailsApi,
  BillingRulePlaceOfServiceApi,
  BillingRulesPagedListApi,
  ValueCodeApi,
} from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { ServiceTypeEnum, SortOrderEnum } from 'src/app/enumerators';
import {
  BillingRule,
  BillingRuleClaimTypeOfBillDetails,
  BillingRuleCreate,
  BillingRuleDeserializationArgs,
  BillingRulePlaceOfService,
  BillingRuleServiceDetail,
  BillingRuleUpdate,
  NameId,
  ValueCode,
} from 'src/app/models';
import { PagedListCodec, decode, parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class BillingRulesService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return data for a single Payor Rule by id.
   *
   * @param id The id of the Payor Rule to fetch.
   * @returns Full model on success, null on not found, undefined on error.
   */
  public get(
    billingPayorRuleId: BillingRule['id'],
    deserializationArgs: BillingRuleDeserializationArgs,
  ): Observable<BillingRule | null | undefined> {
    return this.httpClient
      .get<
        BillingRuleApi | undefined
      >(`${config.api.url}/billing/rules/${billingPayorRuleId}`)
      .pipe(
        map((response) =>
          response
            ? BillingRule.deserialize(response, deserializationArgs)
            : null,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetches a list of Billing Payor Rules
   *
   * @returns A list of Billing Payor Rules. Undefined on error.
   */
  public getPagedList(
    requestParameters: readonly BillingRulesPagedListParam[],
    deserializationArgs: BillingRuleDeserializationArgs,
  ): Observable<PagedList<BillingRule> | undefined> {
    return this.httpClient
      .get<BillingRulesPagedListApi | undefined>(
        `${config.api.url}/billing/rules`,
        {
          params: parseHttpParams(requestParameters),
        },
      )
      .pipe(
        map((response) => {
          if (response === undefined) {
            return undefined;
          }
          const billingPayorRulesPagedList: PagedList<BillingRule> = {
            ...decode(BillingRulesPagedListCodec, response),
            results: BillingRule.deserializeList(
              response.results,
              deserializationArgs,
            ),
          };
          return billingPayorRulesPagedList;
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetches the edit history list for a Billing Payor Rule.
   *
   * @param billingRuleId The id of the Billing Payor Rule to fetch edit
   * history list for.
   * @returns A list of historical Billing Payor Rule updates. Undefined on
   * error.
   */
  public getEditHistoryList(
    billingRuleId: BillingRule['id'],
    deserializationArgs: BillingRuleDeserializationArgs,
    billingRulesEditHistoryRequestParams?: readonly BillingRulesEditHistoryRequestParam[],
  ): Observable<readonly BillingRule[] | undefined> {
    return this.httpClient
      .get<readonly BillingRuleApi[] | undefined>(
        `${config.api.url}/billing/rules/${billingRuleId}/history`,
        {
          params: parseHttpParams(billingRulesEditHistoryRequestParams),
        },
      )
      .pipe(
        map((response) =>
          response
            ? BillingRule.deserializeList(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch a list of Billing Payor Rule Place of Service objects.
   *
   * @returns A list of Billing Payor Rule Place of Service objects or undefined on error.
   */
  public getPlacesOfService(): Observable<
    readonly BillingRulePlaceOfService[] | undefined
  > {
    return this.httpClient
      .get<
        readonly BillingRulePlaceOfServiceApi[] | undefined
      >(`${config.api.url}/place-of-service`)
      .pipe(
        map((response) =>
          response
            ? response.map(BillingRulePlaceOfService.deserialize)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch a list of Service objects.
   *
   * @returns A list of Services. Undefined on error.
   */
  public getServicesByType(
    serviceType: ServiceTypeEnum,
    billingRulesServicesRequestParams?: readonly BillingRulesServicesRequestParam[],
  ): Observable<readonly BillingRuleServiceDetail[] | undefined> {
    return this.httpClient
      .get<readonly BillingRuleServiceDetail[] | undefined>(
        `${config.api.url}/billing/rules/service-types/${serviceType}/services`,
        {
          params: parseHttpParams(billingRulesServicesRequestParams),
        },
      )
      .pipe(
        map((response) =>
          response ? BillingRuleServiceDetail.deserializeList(response) : [],
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch a list of Service Type objects.
   *
   * @returns A list of Service Types. Undefined on error.
   */
  public getServiceTypes(): Observable<readonly ServiceTypeEnum[] | undefined> {
    return this.httpClient
      .get<
        readonly ServiceTypeEnum[] | undefined
      >(`${config.api.url}/billing/rules/service-types`)
      .pipe(
        map((response) => (response ? response : [])),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch a list of Type of Bill objects.
   *
   * @returns A list of Type of Bill objects or undefined on error.
   */
  public getTypeOfBillList(
    billingRulesTypeOfBillRequestParams?: readonly BillingRulesTypeOfBillRequestParam[],
  ): Observable<readonly BillingRuleClaimTypeOfBillDetails[] | undefined> {
    const params = parseHttpParams(billingRulesTypeOfBillRequestParams);
    return this.httpClient
      .get<
        readonly BillingRuleClaimTypeOfBillDetailsApi[] | undefined
      >(`${config.api.url}/type-of-bill`, { params })
      .pipe(
        map((response) =>
          response
            ? response.map(BillingRuleClaimTypeOfBillDetails.deserialize)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch a list of Billing Payor Rule - Value Codes.
   *
   * @returns A list of Value Codes. Undefined on error.
   */
  public getValueCodes(): Observable<readonly ValueCode[] | undefined> {
    return this.httpClient
      .get<
        readonly ValueCodeApi[] | undefined
      >(`${config.api.url}/billing/value-codes`)
      .pipe(
        map((response) =>
          response ? ValueCode.deserializeList(response) : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Add a new Billing Payor Rule.
   *
   * @param billingPayorRuleCreate The Billing Payor Rule to create.
   * @returns The Billing Payor Rule post response, or undefined when an
   * unhandled error occurs.
   */
  public post(
    billingPayorRuleCreate: BillingRuleCreate,
    deserializationArgs: BillingRuleDeserializationArgs,
  ): Observable<BillingRule | HttpErrorResponseApi> {
    return this.httpClient
      .post<BillingRuleApi>(
        `${config.api.url}/billing/rules`,
        billingPayorRuleCreate.serialize(),
        { observe: 'response' },
      )
      .pipe(
        map((response) => {
          if (!response.body) {
            throw new Error('Modifier POST response from API is empty!');
          }
          return BillingRule.deserialize(response.body, deserializationArgs);
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of(error);
          }
          throw error;
        }),
      );
  }

  /**
   * Update a Billing Payor Rule.
   *
   * @param billingCodeModifierUpdate The Billing Payor Rule update data.
   * @returns The successfully updated billing code modifier, or undefined on error.
   */
  public put(
    billingPayorRuleUpdate: BillingRuleUpdate,
    deserializationArgs: BillingRuleDeserializationArgs,
  ): Observable<BillingRule | HttpErrorResponseApi> {
    return this.httpClient
      .put<BillingRuleApi>(
        `${config.api.url}/billing/rules/${billingPayorRuleUpdate.id}`,
        billingPayorRuleUpdate.serialize(),
        { observe: 'response' },
      )
      .pipe(
        map((response) => {
          if (!response.body) {
            throw new Error('Modifier PUT response from API is empty!');
          }
          return BillingRule.deserialize(response.body, deserializationArgs);
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of(error);
          }
          throw error;
        }),
      );
  }
}

const BillingRulesPagedListCodec = PagedListCodec(
  BillingRule.Codec,
  'BillingRulesPagedListApi',
);

export interface BillingRulesEditHistoryRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'limit' | 'order' | 'sort';
  /** The value to use for the query parameter. */
  value: number | string | SortOrderEnum;
}

export interface BillingRulesServicesRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'active' | 'facility' | 'order';
  /** The value to use for the query parameter. */
  value: number | keyof NameId | boolean;
}

export interface BillingRulesTypeOfBillRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'active';
  /** The value to use for the query parameter. */
  value: boolean;
}

export interface BillingRulesPagedListParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key:
    | 'active'
    | 'claimType'
    | 'facility'
    | 'levelOfCareId'
    | 'order'
    | 'pageNumber'
    | 'pageSize'
    | 'payorId'
    | 'providers'
    | 'search';
  /** The value to use for the query parameter. */
  value: string | number | boolean;
}
