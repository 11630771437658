<p class="red-notice">
  Screen locked, please enter your password below to continue.
</p>

<p>
  You will be automatically logged out in:
  <span class="red-notice">
    {{ timeUntilLogoutLabelChanges | async }} minute(s)
  </span>
</p>

<form [formGroup]="formGroup">
  <alleva-input
    [formControl]="formGroup.controls['password']"
    [prefixIcon]="{
      color: 'primary',
      name: 'lock',
      type: 'two-tone',
    }"
    hideHint
    label="Password"
    placeholder="Re-enter your password."
    type="password"
  ></alleva-input>
</form>

<div class="actions">
  <alleva-button
    (click)="logout()"
    [disabled]="isSubmitting"
    color="warn"
    label="Logout"
    styling="flat"
  >
    Logout
  </alleva-button>
  <alleva-button
    (click)="submit()"
    [disabled]="formGroup.disabled || isSubmitting"
    color="accent"
    label="Unlock"
    styling="flat"
  >
    Unlock
  </alleva-button>
</div>
