import { MonoTypeOperatorFunction, Observable, firstValueFrom } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';

import { isNonEmptyValue } from 'src/app/utilities/type-check';

/**
 * Gets async the first non-empty value emitted by the source observable.
 *
 * @param observable The observable to wait for.
 * @returns The first non-empty value emitted by the source observable.
 */
export function getFirstNonEmptyValueFrom<T>(
  observable: Observable<T | null | undefined>,
): Promise<T> {
  return firstValueFrom(observable.pipe(filter(isNonEmptyValue)));
}

/**
 * Share the last emitted value and replay it to new subscribers. The operator
 * will keep the last emitted value in memory and ensure that the buffer size
 * is limited to 1.
 *
 * @returns A single replay operator function that shares the last emitted value
 * and replays it to new subscribers.
 *
 * @see https://rxjs.dev/api/operators/shareReplay#reference-counting
 */
export function shareSingleReplay<T>(): MonoTypeOperatorFunction<T> {
  return shareReplay({ bufferSize: 1, refCount: true });
}
