import { PAGE_PERMISSIONS } from './page-permissions';

/**
 * Default navigation menu items.
 */
export const NAV_MENU_ITEMS: readonly MenuItem[] = [
  {
    label: 'My Dashboard',
    iconName: 'dashboard',
    path: '/dashboard',
    permissions: PAGE_PERMISSIONS['/dashboard'],
  },
  {
    label: 'Favorites',
    iconName: 'star',
    topLevelMenuItems: [
      {
        label: 'Client List',
        iconName: 'client_list',
        path: '/clients/list',
        permissions: PAGE_PERMISSIONS['/clients/list'],
      },
      {
        label: 'Client',
        iconName: null,
        isClientMenu: true,
        permissions: [
          ...PAGE_PERMISSIONS['/clients/:id'],
          ...PAGE_PERMISSIONS['/clients/:id/calendar'],
          ...PAGE_PERMISSIONS['/clients/:id/meal-plan'],
          ...PAGE_PERMISSIONS['/clients/:id/discharge-planning'],
          ...PAGE_PERMISSIONS['/clients/:id/doctors-orders'],
          ...PAGE_PERMISSIONS['/clients/:id/document-manager'],
          ...PAGE_PERMISSIONS['/clients/:id/homework'],
          ...PAGE_PERMISSIONS['/clients/:id/medication-administration-record'],
          ...PAGE_PERMISSIONS['/clients/:id/medication'],
          ...PAGE_PERMISSIONS['/clients/:id/notes'],
          ...PAGE_PERMISSIONS['/clients/:id/rec-therapy'],
          ...PAGE_PERMISSIONS['/clients/:id/treatment-plan'],
        ],
        subLevelMenuItems: [
          {
            label: 'Chart',
            path: '/clients/:id',
            permissions: PAGE_PERMISSIONS['/clients/:id'],
          },
          {
            label: 'Calendar',
            path: '/clients/:id/calendar',
            permissions: PAGE_PERMISSIONS['/clients/:id/calendar'],
          },
          {
            label: 'Dietary Plan',
            path: '/clients/:id/meal-plan',
            permissions: PAGE_PERMISSIONS['/clients/:id/meal-plan'],
          },
          {
            label: 'Discharge Plan',
            path: '/clients/:id/discharge-planning',
            permissions: PAGE_PERMISSIONS['/clients/:id/discharge-planning'],
          },
          {
            label: 'Doctors Orders',
            path: '/clients/:id/doctors-orders',
            permissions: PAGE_PERMISSIONS['/clients/:id/doctors-orders'],
          },
          {
            label: 'Document Manager',
            path: '/clients/:id/document-manager',
            permissions: PAGE_PERMISSIONS['/clients/:id/document-manager'],
          },
          {
            label: 'Homework',
            path: '/clients/:id/homework',
            permissions: PAGE_PERMISSIONS['/clients/:id/homework'],
          },
          {
            label: 'MAR',
            path: '/clients/:id/medication-administration-record',
            permissions:
              PAGE_PERMISSIONS['/clients/:id/medication-administration-record'],
          },
          {
            label: 'Medication',
            path: '/clients/:id/medication',
            permissions: PAGE_PERMISSIONS['/clients/:id/medication'],
          },
          {
            label: 'Notes',
            path: '/clients/:id/notes',
            permissions: PAGE_PERMISSIONS['/clients/:id/notes'],
          },
          {
            label: 'Rec Therapy',
            path: '/clients/:id/rec-therapy',
            permissions: PAGE_PERMISSIONS['/clients/:id/rec-therapy'],
          },
          {
            label: 'Treatment Plan',
            path: '/clients/:id/treatment-plan',
            permissions: PAGE_PERMISSIONS['/clients/:id/treatment-plan'],
          },
        ],
      },
      {
        label: 'Calendar',
        iconName: 'calendar',
        permissions: [
          ...PAGE_PERMISSIONS['/calendar/scheduler'],
          ...PAGE_PERMISSIONS['/calendar/medical-provider-scheduler'],
          ...PAGE_PERMISSIONS['/calendar/historical-events'],
        ],
        subLevelMenuItems: [
          {
            label: 'Scheduler',
            path: '/calendar/scheduler',
            permissions: PAGE_PERMISSIONS['/calendar/scheduler'],
          },
          {
            label: 'Medical Provider',
            path: '/calendar/medical-provider-scheduler',
            permissions:
              PAGE_PERMISSIONS['/calendar/medical-provider-scheduler'],
          },
          {
            label: 'Historical Events',
            path: '/calendar/historical-events',
            permissions: PAGE_PERMISSIONS['/calendar/historical-events'],
          },
        ],
      },
      {
        label: 'Medications',
        iconName: 'medications',
        permissions: [
          ...PAGE_PERMISSIONS['/medications/management'],
          ...PAGE_PERMISSIONS['/medications/count'],
          ...PAGE_PERMISSIONS['/medications/destruction'],
        ],
        subLevelMenuItems: [
          {
            label: 'Med Management',
            path: '/medications/management',
            permissions: PAGE_PERMISSIONS['/medications/management'],
          },
          {
            label: 'Med Count',
            path: '/medications/count',
            permissions: PAGE_PERMISSIONS['/medications/count'],
          },
          {
            label: 'Med Destruction',
            path: '/medications/destruction',
            permissions: PAGE_PERMISSIONS['/medications/destruction'],
          },
        ],
      },
    ],
  },
  {
    label: 'A-Z',
    topLevelMenuItems: [
      {
        label: 'Alumni',
        iconName: 'alum',
        path: '/alumni',
        permissions: PAGE_PERMISSIONS['/alumni'],
      },
      {
        label: 'Billing',
        iconName: 'billing',
        permissions: PAGE_PERMISSIONS['/billing-offsite'],
        subLevelMenuItems: [
          {
            label: 'Alleva Billing',
            path: '/billing-offsite',
            permissions: PAGE_PERMISSIONS['/billing-offsite'],
          },
          {
            label: 'Encounter Transmission',
            path: '/encounter-transmission',
            permissions: PAGE_PERMISSIONS['/encounter-transmission'],
          },
        ],
      },
      {
        label: 'Chef Dashboard',
        iconName: 'chef',
        path: '/chef-dashboard',
        permissions: PAGE_PERMISSIONS['/chef-dashboard'],
      },
      {
        label: 'Client Prospects',
        iconName: 'prospect',
        permissions: [
          ...PAGE_PERMISSIONS['/prospective/add'],
          ...PAGE_PERMISSIONS['/prospective/list'],
        ],
        subLevelMenuItems: [
          {
            label: 'Add Prospect',
            path: '/prospective/add',
            permissions: PAGE_PERMISSIONS['/prospective/add'],
          },
          {
            label: 'Prospective Client List',
            path: '/prospective/list',
            permissions: PAGE_PERMISSIONS['/prospective/list'],
          },
        ],
      },
      {
        label: 'Doctors Orders',
        iconName: 'doctors_orders',
        path: '/doctors-orders',
        permissions: PAGE_PERMISSIONS['/doctors-orders'],
      },
      {
        label: 'Incidents',
        iconName: 'incidents',
        path: '/incidents',
        permissions: PAGE_PERMISSIONS['/incidents'],
      },
      {
        label: 'Lab Orders',
        iconName: 'lab_orders',
        path: '/lab-orders',
        permissions: PAGE_PERMISSIONS['/lab-orders'],
      },
      {
        label: 'Occupancy',
        iconName: 'occupancy',
        path: '/occupancy',
        permissions: PAGE_PERMISSIONS['/occupancy'],
      },
      {
        label: 'Referrals',
        iconName: 'referrals',
        path: '/referrals',
        permissions: PAGE_PERMISSIONS['/referrals'],
      },
      {
        label: 'Reports',
        iconName: 'reports',
        permissions: [
          ...PAGE_PERMISSIONS['/reports/insights'],
          ...PAGE_PERMISSIONS['/reports/discharge-reports'],
          ...PAGE_PERMISSIONS['/reports/meds-count-reports'],
        ],
        subLevelMenuItems: [
          {
            label: 'Insights',
            path: '/reports/insights',
            permissions: PAGE_PERMISSIONS['/reports/insights'],
          },
          {
            label: 'Discharge Report',
            path: '/reports/discharge-reports',
            permissions: PAGE_PERMISSIONS['/reports/discharge-reports'],
          },
          {
            label: 'Med Count Report',
            path: '/reports/meds-count-reports',
            permissions: PAGE_PERMISSIONS['/reports/meds-count-reports'],
          },
        ],
      },
      {
        label: 'Review Screen',
        iconName: 'review_screen',
        permissions: [
          ...PAGE_PERMISSIONS['/review-screen/signature-review'],
          ...PAGE_PERMISSIONS['/review-screen/notes-audit'],
          ...PAGE_PERMISSIONS['/review-screen/notes-review'],
        ],
        subLevelMenuItems: [
          {
            label: 'Signature Review',
            path: '/review-screen/signature-review',
            permissions: PAGE_PERMISSIONS['/review-screen/signature-review'],
          },
          {
            label: 'Notes Audit',
            path: '/review-screen/notes-audit',
            permissions: PAGE_PERMISSIONS['/review-screen/notes-audit'],
          },
          {
            label: 'Notes Review',
            path: '/review-screen/notes-review',
            permissions: PAGE_PERMISSIONS['/review-screen/notes-review'],
          },
        ],
      },
      {
        label: 'Shifts',
        iconName: 'shifts',
        permissions: [
          ...PAGE_PERMISSIONS['/shifts/shift-rounds'],
          ...PAGE_PERMISSIONS['/shifts/shift-notes'],
        ],
        subLevelMenuItems: [
          {
            label: 'Rounds',
            path: '/shifts/shift-rounds',
            permissions: PAGE_PERMISSIONS['/shifts/shift-rounds'],
          },
          {
            label: 'Shift Notes',
            path: '/shifts/shift-notes',
            permissions: PAGE_PERMISSIONS['/shifts/shift-notes'],
          },
        ],
      },
      {
        label: 'Utilization Review',
        iconName: 'utilization_review',
        path: '/utilization-review',
        permissions: PAGE_PERMISSIONS['/utilization-review'],
      },
    ],
  },
  {
    label: 'Settings',
    iconName: 'settings',
    topLevelMenuItems: [
      {
        label: 'Company Settings',
        iconName: 'company_settings',
        permissions: [
          ...PAGE_PERMISSIONS['/settings/company-information'],
          ...PAGE_PERMISSIONS['/settings/roles'],
          ...PAGE_PERMISSIONS['/settings/users'],
        ],
        subLevelMenuItems: [
          {
            label: 'Company Info',
            path: '/settings/company-information',
            permissions: PAGE_PERMISSIONS['/settings/company-information'],
          },
          {
            label: 'Role Management',
            path: '/settings/roles',
            permissions: PAGE_PERMISSIONS['/settings/roles'],
          },
          {
            label: 'User Management',
            path: '/settings/users',
            permissions: PAGE_PERMISSIONS['/settings/users'],
          },
        ],
      },
      {
        label: 'System Settings',
        iconName: 'system_settings',
        permissions: [
          ...PAGE_PERMISSIONS['/settings/admission-checklist'],
          ...PAGE_PERMISSIONS['/settings/beds'],
          ...PAGE_PERMISSIONS['/settings/billing'],
          ...PAGE_PERMISSIONS['/settings/client-transfer'],
          ...PAGE_PERMISSIONS['/settings/daily-questionnaire'],
          ...PAGE_PERMISSIONS['/settings/dietary'],
          ...PAGE_PERMISSIONS['/settings/digital-dashboard'],
          ...PAGE_PERMISSIONS['/settings/discharge'],
          ...PAGE_PERMISSIONS['/settings/family-portal'],
          ...PAGE_PERMISSIONS['/settings/forms'],
          ...PAGE_PERMISSIONS['/settings/forms-two'],
          ...PAGE_PERMISSIONS['/settings/advanced-forms'],
          ...PAGE_PERMISSIONS['/settings/groups'],
          ...PAGE_PERMISSIONS['/settings/intakes'],
          ...PAGE_PERMISSIONS['/settings/labs'],
          ...PAGE_PERMISSIONS['/settings/master-dropdowns'],
          ...PAGE_PERMISSIONS['/settings/medical-providers'],
          ...PAGE_PERMISSIONS['/settings/office-templates'],
          ...PAGE_PERMISSIONS['/settings/alerts-frequency'],
          ...PAGE_PERMISSIONS['/settings/alerts-permission'],
          ...PAGE_PERMISSIONS['/settings/alerts-template'],
          ...PAGE_PERMISSIONS['/settings/orders'],
          ...PAGE_PERMISSIONS['/settings/surveys'],
          ...PAGE_PERMISSIONS['/settings/templates'],
          ...PAGE_PERMISSIONS['/settings/treatment-plan-review'],
        ],
        subLevelMenuItems: [
          {
            label: 'Admission Checklist',
            path: '/settings/admission-checklist',
            permissions: PAGE_PERMISSIONS['/settings/admission-checklist'],
          },
          {
            label: 'Alleva AI',
            path: '/settings/alleva-ai',
            permissions: PAGE_PERMISSIONS['/settings/alleva-ai'],
          },
          {
            label: 'Bed Management',
            path: '/settings/beds',
            permissions: PAGE_PERMISSIONS['/settings/beds'],
          },
          {
            label: 'Billing',
            path: '/settings/billing',
            permissions: PAGE_PERMISSIONS['/settings/billing'],
          },
          {
            label: 'Client Transfer',
            path: '/settings/client-transfer',
            permissions: PAGE_PERMISSIONS['/settings/client-transfer'],
          },
          {
            label: 'Daily Questionnaire',
            path: '/settings/daily-questionnaire',
            permissions: PAGE_PERMISSIONS['/settings/daily-questionnaire'],
          },
          {
            label: 'Dietary',
            path: '/settings/dietary',
            permissions: PAGE_PERMISSIONS['/settings/dietary'],
          },
          {
            label: 'Digital Dashboard',
            path: '/settings/digital-dashboard',
            permissions: PAGE_PERMISSIONS['/settings/digital-dashboard'],
          },
          {
            label: 'Discharge',
            path: '/settings/discharge',
            permissions: PAGE_PERMISSIONS['/settings/discharge'],
          },
          {
            label: 'Family Portal',
            path: '/settings/family-portal',
            permissions: PAGE_PERMISSIONS['/settings/family-portal'],
          },
          {
            label: 'Forms Manager',
            path: '/settings/forms',
            permissions: PAGE_PERMISSIONS['/settings/forms'],
          },
          {
            label: 'Forms 2.0 Manager',
            path: '/settings/forms-two',
            permissions: PAGE_PERMISSIONS['/settings/forms-two'],
          },
          {
            label: 'Forms 3.0 Manager',
            path: '/settings/advanced-forms',
            permissions: PAGE_PERMISSIONS['/settings/advanced-forms'],
          },
          {
            label: 'Groups',
            path: '/settings/groups',
            permissions: PAGE_PERMISSIONS['/settings/groups'],
          },
          {
            label: 'Intakes',
            path: '/settings/intakes',
            permissions: PAGE_PERMISSIONS['/settings/intakes'],
          },
          {
            label: 'Labs',
            path: '/settings/labs',
            permissions: PAGE_PERMISSIONS['/settings/labs'],
          },
          {
            label: 'Master Drop-Downs',
            path: '/settings/master-dropdowns',
            permissions: PAGE_PERMISSIONS['/settings/master-dropdowns'],
          },
          {
            label: 'Medical Provider',
            path: '/settings/medical-providers',
            permissions: PAGE_PERMISSIONS['/settings/medical-providers'],
          },
          {
            label: 'MTP Templates',
            path: '/settings/office-templates',
            permissions: PAGE_PERMISSIONS['/settings/office-templates'],
          },
          {
            label: 'Notification Frequency',
            path: '/settings/alerts-frequency',
            permissions: PAGE_PERMISSIONS['/settings/alerts-frequency'],
          },
          {
            label: 'Notification Permissions',
            path: '/settings/alerts-permission',
            permissions: PAGE_PERMISSIONS['/settings/alerts-permission'],
          },
          {
            label: 'Notification Templates',
            path: '/settings/alerts-template',
            permissions: PAGE_PERMISSIONS['/settings/alerts-template'],
          },
          {
            label: 'Orders',
            path: '/settings/orders',
            permissions: PAGE_PERMISSIONS['/settings/orders'],
          },
          {
            label: 'Surveys',
            path: '/settings/surveys',
            permissions: PAGE_PERMISSIONS['/settings/surveys'],
          },
          {
            label: 'Templates',
            path: '/settings/templates',
            permissions: PAGE_PERMISSIONS['/settings/templates'],
          },
          {
            label: 'TPR',
            path: '/settings/treatment-plan-review',
            permissions: PAGE_PERMISSIONS['/settings/treatment-plan-review'],
          },
        ],
      },
      {
        label: 'Sandbox',
        iconName: 'science',
        permissions: [], // Only available to admins.
        subLevelMenuItems: [
          {
            label: 'Buttons',
            path: '/sandbox/buttons',
            permissions: PAGE_PERMISSIONS['/sandbox/buttons'],
          },
          {
            label: 'Editor',
            path: '/sandbox/editor',
            permissions: PAGE_PERMISSIONS['/sandbox/editor'],
          },
          {
            label: 'Forms',
            path: '/sandbox/forms',
            permissions: PAGE_PERMISSIONS['/sandbox/forms'],
          },
          {
            label: 'Icons',
            path: '/sandbox/icons',
            permissions: PAGE_PERMISSIONS['/sandbox/icons'],
          },
          {
            label: 'Loading Indicators',
            path: '/sandbox/loading-indicators',
            permissions: PAGE_PERMISSIONS['/sandbox/loading-indicators'],
          },
          {
            label: 'Messages',
            path: '/sandbox/messages',
            permissions: PAGE_PERMISSIONS['/sandbox/messages'],
          },
          {
            label: 'Print',
            path: '/sandbox/print',
            permissions: PAGE_PERMISSIONS['/sandbox/print'],
          },
          {
            label: 'Signatures',
            path: '/sandbox/signatures',
            permissions: PAGE_PERMISSIONS['/sandbox/signatures'],
          },
          {
            label: 'Tables',
            path: '/sandbox/tables',
            permissions: PAGE_PERMISSIONS['/sandbox/tables'],
          },
        ],
      },
    ],
  },
];
