import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, firstValueFrom } from 'rxjs';
import { VALIDATION } from 'src/app/constants';
import { HeightUnitsEnum, MeasurementSystemEnum } from 'src/app/enumerators';
import { VitalsHeight } from 'src/app/models';
import { FacilityService } from 'src/app/services';
import {
  getCentimetersFrom,
  getInchesFrom,
  isNonEmptyValue,
} from 'src/app/utilities';

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormControl, FormGroup, NgControl, Validators } from '@angular/forms';

import { CustomFormGroupDirective } from 'src/app/components/forms/custom-form-group.directive';

/**
 * An input component for tracking height.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-input-height[formControl][label]',
  templateUrl: './input-height.component.html',
  styleUrls: ['./input-height.component.scss'],
})
export class InputHeightComponent
  extends CustomFormGroupDirective<VitalsHeight>
  implements OnInit
{
  public constructor(
    @Optional() @Self() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    private readonly facilityService: FacilityService,
  ) {
    super(ngControl, changeDetectorRef, elementRef);
  }

  /** The id of the centimeter form control, automatically generated if not provided. */
  @Input()
  public centimetersId =
    `alleva-input-height-centimeters${++uniqueHeightCentimetersFormId}`;
  /** The id of the feet form control, automatically generated if not provided. */
  @Input()
  public feetId = `alleva-input-height-feet${++uniqueHeightFeetFormId}`;
  /** The id of the inches form control, automatically generated if not provided. */
  @Input()
  public inchesId = `alleva-input-height-inches${++uniqueHeightInchesFormId}`;
  /** The id of the units form control, automatically generated if not provided. */
  @Input()
  public unitsId = `alleva-input-height-units${++uniqueHeightUnitsFormId}`;
  /** The id of the value form control, automatically generated if not provided. */
  @Input()
  public valueId = `alleva-input-height-value${++uniqueHeightValueFormId}`;

  // Override the following defaults to undefined to show they're unused here.
  public override placeholder = undefined;

  protected readonly HeightUnitsEnum = HeightUnitsEnum;

  protected override readonly formGroup = new FormGroup<HeightFormGroup>({
    centimeters: new FormControl<VitalsHeight['centimeters']>(null, [
      Validators.required,
      Validators.min(VALIDATION.height.metric.centimeters.min),
      Validators.max(VALIDATION.height.metric.centimeters.max),
    ]),
    displayValue: new FormControl<VitalsHeight['displayValue'] | null>(null),
    feet: new FormControl<VitalsHeight['feet']>(null, [
      Validators.required,
      Validators.min(VALIDATION.height.imperial.feet.min),
      Validators.max(VALIDATION.height.imperial.feet.max),
    ]),
    inches: new FormControl<VitalsHeight['inches']>(null, [
      Validators.required,
      Validators.min(VALIDATION.height.imperial.inches.min),
      Validators.max(VALIDATION.height.imperial.inches.max),
    ]),
    units: new FormControl<VitalsHeight['units']>(null, Validators.required),
    value: new FormControl<VitalsHeight['value']>(null, [
      Validators.required,
      Validators.min(0),
    ]),
  });

  protected readonly ctrls = this.formGroup.controls;

  public override async ngOnInit(): Promise<void> {
    super.ngOnInit();

    if (!this.baseControl) {
      throw new Error(
        'InputHeightComponent requires a formControl attribute to be set.',
      );
    }

    const facility = await firstValueFrom(
      this.facilityService.currentFacilityChanges,
    );
    const facilityMeasurementSystem = facility.settings.measurementSystem;
    let facilityHeightUnits: HeightUnitsEnum | null = null;

    switch (facilityMeasurementSystem) {
      case MeasurementSystemEnum.AMERICAN:
      case MeasurementSystemEnum.IMPERIAL:
        facilityHeightUnits = HeightUnitsEnum.INCHES;
        break;
      case MeasurementSystemEnum.METRIC:
        facilityHeightUnits = HeightUnitsEnum.CENTIMETERS;
        break;
      default:
        throw new Error(
          `Unhandled measurement system for height: ${facilityMeasurementSystem}`,
        );
    }

    const currentHeight = this.baseControl.value;
    const currentHeightUnits = currentHeight?.units ?? facilityHeightUnits;
    let currentHeightValue = currentHeight?.value ?? null;

    // Convert the current value to the facility's measurement system.
    if (
      currentHeight &&
      currentHeightValue &&
      currentHeightUnits !== facilityHeightUnits
    ) {
      switch (facilityHeightUnits) {
        case HeightUnitsEnum.INCHES: {
          switch (currentHeightUnits) {
            case HeightUnitsEnum.INCHES: {
              break; // Do nothing, the value is already in inches.
            }
            case HeightUnitsEnum.CENTIMETERS: {
              currentHeightValue = getInchesFrom({
                centimeters: currentHeightValue,
              });
              break;
            }
            default: {
              throw new Error(
                `Unhandled conversion from: ${currentHeightUnits} to ${facilityHeightUnits}`,
              );
            }
          }
          break;
        }
        case HeightUnitsEnum.CENTIMETERS: {
          switch (currentHeightUnits) {
            case HeightUnitsEnum.CENTIMETERS: {
              break; // Do nothing, the value is already in centimeters.
            }
            case HeightUnitsEnum.INCHES: {
              currentHeightValue = getCentimetersFrom({
                feet: currentHeightValue,
              });
              break;
            }
            default: {
              throw new Error(
                `Unhandled conversion from: ${currentHeightUnits} to ${facilityHeightUnits}`,
              );
            }
          }
          break;
        }
        default: {
          throw new Error(
            'Unhandled facility measurement system' +
              `"${facilityMeasurementSystem}" for height.`,
          );
        }
      }
    }

    // Set initial values.
    this.formGroup.setValue(
      new VitalsHeight({
        units: facilityHeightUnits,
        value: currentHeightValue,
      }),
      { emitEvent: false },
    );

    if (!this.baseControl.hasValidator(Validators.required)) {
      this.ctrls.centimeters.removeValidators(Validators.required);
      this.ctrls.centimeters.updateValueAndValidity({ emitEvent: false });
      this.ctrls.centimeters.markAsUntouched();

      this.ctrls.feet.removeValidators(Validators.required);
      this.ctrls.feet.updateValueAndValidity({ emitEvent: false });
      this.ctrls.feet.markAsUntouched();

      this.ctrls.inches.removeValidators(Validators.required);
      this.ctrls.inches.updateValueAndValidity({ emitEvent: false });
      this.ctrls.inches.markAsUntouched();

      this.ctrls.units.removeValidators(Validators.required);
      this.ctrls.units.updateValueAndValidity({ emitEvent: false });
      this.ctrls.units.markAsUntouched();

      this.ctrls.value.removeValidators(Validators.required);
      this.ctrls.value.updateValueAndValidity({ emitEvent: false });
      this.ctrls.value.markAsUntouched();
    } else if (facilityHeightUnits === HeightUnitsEnum.CENTIMETERS) {
      this.ctrls.feet.removeValidators(Validators.required);
      this.ctrls.feet.updateValueAndValidity({ emitEvent: false });
      this.ctrls.feet.markAsUntouched();

      this.ctrls.inches.removeValidators(Validators.required);
      this.ctrls.inches.updateValueAndValidity({ emitEvent: false });
      this.ctrls.inches.markAsUntouched();
    } else if (facilityHeightUnits === HeightUnitsEnum.INCHES) {
      this.ctrls.centimeters.removeValidators(Validators.required);
      this.ctrls.centimeters.updateValueAndValidity({ emitEvent: false });
      this.ctrls.centimeters.markAsUntouched();
    }

    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged(
          (
            {
              centimeters: oldCentimeters,
              inches: oldInches,
              feet: oldFeet,
              units: oldUnits,
              value: oldValue,
            },
            {
              centimeters: newCentimeters,
              inches: newInches,
              feet: newFeet,
              units: newUnits,
              value: newValue,
            },
          ) =>
            oldCentimeters === newCentimeters &&
            oldInches === newInches &&
            oldFeet === newFeet &&
            oldUnits === newUnits &&
            oldValue === newValue,
        ),
        untilDestroyed(this),
      )
      .subscribe(({ centimeters, inches, feet, units, value }) => {
        if (
          units === HeightUnitsEnum.CENTIMETERS &&
          isNonEmptyValue(centimeters)
        ) {
          this.baseControl.patchValue(
            new VitalsHeight({ units, value: centimeters }),
          );
          this.baseControl.markAsDirty();
        } else if (
          units === HeightUnitsEnum.INCHES &&
          (isNonEmptyValue(feet) || isNonEmptyValue(inches))
        ) {
          if (isNonEmptyValue(feet) && isNonEmptyValue(inches)) {
            const totalInches = getInchesFrom({ feet }) + inches;
            this.baseControl.patchValue(
              new VitalsHeight({ units, value: totalInches }),
            );
          } else {
            // User isnt done entering the height.
            return;
          }
          this.baseControl.markAsDirty();
        } else if (!isNonEmptyValue(units) && !isNonEmptyValue(value)) {
          this.baseControl.patchValue(null);
          this.baseControl.markAsPristine();
        } else {
          this.baseControl.patchValue(null, { emitEvent: false });
          this.baseControl.markAsPristine();
        }
      });

    this.ctrls.units.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((unit) => {
        if (unit === HeightUnitsEnum.CENTIMETERS) {
          this.ctrls.centimeters.setValidators([
            Validators.required,
            Validators.min(VALIDATION.height.metric.centimeters.min),
            Validators.max(VALIDATION.height.metric.centimeters.max),
          ]);
          this.ctrls.feet.clearValidators();
          this.ctrls.inches.clearValidators();
          this.ctrls.feet.updateValueAndValidity();
          this.ctrls.inches.updateValueAndValidity();
        } else if (unit === HeightUnitsEnum.INCHES) {
          this.ctrls.feet.setValidators([
            Validators.required,
            Validators.min(VALIDATION.height.imperial.feet.min),
            Validators.max(VALIDATION.height.imperial.feet.max),
          ]);
          this.ctrls.inches.setValidators([
            Validators.required,
            Validators.min(VALIDATION.height.imperial.inches.min),
            Validators.max(VALIDATION.height.imperial.inches.max),
          ]);
          this.ctrls.centimeters.clearValidators();
          this.ctrls.centimeters.updateValueAndValidity();
        } else {
          // Clear values
          this.ctrls.centimeters.setValue(null, { emitEvent: false });
          this.ctrls.feet.setValue(null, { emitEvent: false });
          this.ctrls.inches.setValue(null, { emitEvent: false });

          // Clear validators
          this.ctrls.centimeters.clearValidators();
          this.ctrls.feet.clearValidators();
          this.ctrls.inches.clearValidators();
          this.ctrls.units.clearValidators();

          // Update validity
          this.ctrls.feet.updateValueAndValidity();
          this.ctrls.inches.updateValueAndValidity();
          this.ctrls.centimeters.updateValueAndValidity();
          this.ctrls.units.updateValueAndValidity();

          // Reset the form group to null.
          this.formGroup.setValue(
            new VitalsHeight({
              units: null,
              value: null,
            }),
          );
        }
      });

    // If any values are invalid (cm, ft, in, unit), mark the baseControl as invalid.
    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.formGroup.invalid) {
        this.baseControl.setErrors({ required: true }, { emitEvent: false });
        this.baseControl.markAsTouched();
        this.baseControl.markAsDirty();
      } else {
        this.baseControl.setErrors(null, { emitEvent: false });
      }
    });
  }
}

interface HeightFormGroup {
  centimeters: FormControl<VitalsHeight['centimeters']>;
  displayValue: FormControl<VitalsHeight['displayValue'] | null>;
  feet: FormControl<VitalsHeight['feet']>;
  inches: FormControl<VitalsHeight['inches']>;
  units: FormControl<VitalsHeight['units']>;
  value: FormControl<VitalsHeight['value']>;
}

/** A unique ID for each form field height `centimeters` input. */
let uniqueHeightCentimetersFormId = 0;

/** A unique ID for each form field height `feet` input. */
let uniqueHeightFeetFormId = 0;

/** A unique ID for each form field height `inches` input. */
let uniqueHeightInchesFormId = 0;

/** A unique ID for each form field height `units` input. */
let uniqueHeightUnitsFormId = 0;

/** A unique ID for each form field height `value` input. */
let uniqueHeightValueFormId = 0;
