<div class="sign-me-label">
  <label *ngIf="label; else defaultLabel" for="sign-me">
    <h6>{{ label }}{{ required ? '*' : '' }}</h6>
  </label>
  <ng-template #defaultLabel>
    <label
      [ngClass]="{
        required: baseControl.hasValidator(Validators.required),
      }"
      for="sign-me"
    >
      <h6 [ngSwitch]="appearance">
        <ng-container *ngSwitchCase="'client'"> Client </ng-container>
        <ng-container *ngSwitchCase="'guardian'"> Guardian </ng-container>
        <ng-container *ngSwitchCase="'staff'"> Staff </ng-container>
        Signature{{ required ? '*' : '' }}
      </h6>
    </label>
  </ng-template>
</div>
<mat-hint *ngIf="!hideHint && hint" [title]="hint">{{ hint }}</mat-hint>
<div class="sign-me-container" id="sign-me">
  <alleva-input-sign-me-canvas
    [appearance]="appearance"
    [disabled]="disabled"
    [hidden]="showPin"
    [placeholder]="placeholder"
  ></alleva-input-sign-me-canvas>
  <alleva-input-sign-me-pin
    (signatureFromPINUpdate)="onSignatureFromPINUpdate($event)"
    *ngIf="showPin"
    [disabled]="disabled"
  ></alleva-input-sign-me-pin>
  <alleva-input-sign-me-controls
    (clear)="clear()"
    (enterPIN)="showPin = !showPin"
    [appearance]="appearance"
    [disabled]="disabled"
    [enablePin]="enablePin"
  ></alleva-input-sign-me-controls>
</div>

<mat-error *ngIf="baseControl.invalid && baseControl.touched">
  <ng-container *ngIf="baseControl.hasError('required')">
    Signature is required.
  </ng-container>
</mat-error>
