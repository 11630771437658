<alleva-button
  (click)="onEnterPIN()"
  *ngIf="(userHasPin | async) && enablePin && !isEnteringPin"
  [disabled]="disabled"
  [icon]="{
    colorHex: 'var(--white)',
    name: 'password',
    scale: 0.75,
  }"
  [matTooltip]="disabled ? '' : 'Retrieve by PIN'"
  label="Retrieve by PIN"
  styling="mini-fab"
></alleva-button>
<alleva-button
  (click)="onClear()"
  [disabled]="disabled"
  [icon]="{
    colorHex: 'var(--white)',
    name: 'clear',
    scale: 0.75,
  }"
  [matTooltip]="
    disabled ? '' : isEnteringPin ? 'Cancel PIN' : 'Clear Signature'
  "
  label="Clear"
  styling="mini-fab"
></alleva-button>
