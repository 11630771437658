<alleva-button
  (click)="isMenuOpen = !isMenuOpen"
  [icon]="{
    colorHex: 'var(--color-white)',
    name: isMenuOpen ? 'close' : 'add',
    scale: 1.5,
  }"
  [label]="(isMenuOpen ? 'Close' : 'Open') + ' Quick Launch'"
  [matTooltip]="(isMenuOpen ? 'Close' : 'Open') + ' Quick Launch'"
  color="primary"
  matTooltipPosition="before"
  styling="fab"
></alleva-button>
<div [class.expanded]="isMenuOpen" class="quick-launch-submenu">
  <alleva-button
    [icon]="{ name: 'rocket_launch', scale: 1.05 }"
    [routerLink]="['/prospective', 'add']"
    color="primary"
    label="Add Prospect"
    matTooltip="Add Prospect"
    matTooltipPosition="before"
    styling="fab"
  ></alleva-button>
  <alleva-button
    (click)="onClickMasterUDSBA()"
    [icon]="{ name: 'calendar_month', scale: 1.05 }"
    color="primary"
    label="Master UDS/BA"
    matTooltip="Master UDS/BA"
    matTooltipPosition="before"
    styling="fab"
  ></alleva-button>
  <alleva-button
    [icon]="{ name: 'sync_alt', scale: 1.05 }"
    [routerLink]="['/shifts', 'shift-rounds']"
    color="primary"
    label="Shift Rounds"
    matTooltip="Shift Rounds"
    matTooltipPosition="before"
    styling="fab"
  ></alleva-button>
  <alleva-button
    [icon]="{ name: 'horizontal_split', scale: 1.05 }"
    [routerLink]="['/progress-notes']"
    color="primary"
    label="Progress Notes"
    matTooltip="Progress Notes"
    matTooltipPosition="before"
    styling="fab"
  ></alleva-button>
  <alleva-button
    (click)="openAddNoteDialog()"
    [icon]="{ name: 'note', scale: 1.05 }"
    color="primary"
    label="Communication Log"
    matTooltip="Communication Log"
    matTooltipPosition="before"
    styling="fab"
  ></alleva-button>
  <alleva-button
    [icon]="{ name: 'sticky_note_2', scale: 1.05 }"
    [routerLink]="['/shifts', 'shift-notes']"
    color="primary"
    label="Add Shift Notes"
    matTooltip="Add Shift Notes"
    matTooltipPosition="before"
    styling="fab"
  ></alleva-button>
  <alleva-button
    (click)="onClickProcessPayment()"
    [icon]="{ name: 'attach_money', scale: 1.05 }"
    color="primary"
    label="Process Payment Transaction"
    matTooltip="Process Payment Transaction"
    matTooltipPosition="before"
    styling="fab"
  ></alleva-button>
  <alleva-button
    (click)="onClickAllevaAI()"
    [icon]="{ name: 'contact_support', type: 'outlined', scale: 1.05 }"
    color="primary"
    label="Alleva AI"
    matTooltip="Alleva AI"
    matTooltipPosition="before"
    styling="fab"
  ></alleva-button>
</div>
