import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import { ALLEVA_PAGES } from 'src/app/constants';
import {
  AuthenticationService,
  ClientResolverService,
  CurrentClientService,
  FacilityService,
} from 'src/app/services';
import {
  getFirstNonEmptyValueFrom,
  isNonEmptyString,
  isRouteData,
  navigateOffsiteTo,
} from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class LegacyRouteGuard {
  public constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly clientResolverService: ClientResolverService,
    private readonly currentClientService: CurrentClientService,
    private readonly facilityService: FacilityService,
  ) {}

  /**
   * Checks if legacy routing is enabled and performs redirections based on the route's component.
   * @param route The activated route snapshot.
   * @returns A promise resolving to true if the navigation is allowed, false otherwise.
   */
  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    if (config.isLegacyRoutingEnabled) {
      // Retrieve the current route data
      const routeData = isRouteData(route.data) ? route.data : null;
      const pageTitle = routeData?.meta.title || null;

      if (!pageTitle) {
        throw new Error('Route does not have a title!');
      }

      // All legacy route mappings that are available.
      const legacyPathMap = pathMap;

      switch (pageTitle) {
        case 'Client Chart': {
          await this.clientResolverService.resolve(route, state);
          break;
        }
      }

      const legacyPath = legacyPathMap[ALLEVA_PAGES[pageTitle]];
      if (isNonEmptyString(legacyPath)) {
        await this.navigateToLegacyRoute(legacyPath);
        return false;
      }
    }

    return true;
  }

  /**
   * Navigates to a legacy route with optional query parameters.
   *
   * @param relativeUrl The relative URL for the legacy route.
   */
  private async navigateToLegacyRoute(relativeUrl: string): Promise<void> {
    // Retrieve client tenant ID from user changes
    const { clientTenantId } = await getFirstNonEmptyValueFrom(
      this.authenticationService.userChanges,
    );

    // Retrieve selected client information
    const selectedClient = await firstValueFrom(
      this.currentClientService.selectedChanges,
    );

    // Retrieve current facility information
    const currentFacility = await firstValueFrom(
      this.facilityService.currentFacilityChanges,
    );

    // Extract chartId and clientId from the selected client
    const chartId = selectedClient?.chartId;
    const clientId = selectedClient?.id;

    // Initialize an array to store query parameters
    const EXT_CHART_ID = 'ext_chart_id';
    const EXT_CLIENT_ID = 'ext_client_id';
    const EXT_LAST_ACTIVE = 'ext_last_active';
    const EXT_FACILITY_ID = 'ext_facility_id';
    let queryParams: HttpParams = new HttpParams();

    // Push available query parameters
    if (chartId) {
      queryParams = queryParams.append(EXT_CHART_ID, chartId.toString());
    }
    if (clientId) {
      queryParams = queryParams.append(EXT_CLIENT_ID, clientId.toString());
    }

    // Push last active datetime
    const now = DateTime.now().toMillis();
    queryParams = queryParams.append(EXT_LAST_ACTIVE, now);

    // Push current facility
    queryParams = queryParams.append(EXT_FACILITY_ID, currentFacility.id);

    const url = `https://${clientTenantId}.allevasoft.com${relativeUrl}`;

    // Construct the offsite URL with the generated query parameters
    await navigateOffsiteTo(url, { queryParams });
  }
}

// Possibly convert to string literals in the future for better type safety,
// similar to the PageRoute type.
type LegacyRoute = string;

// Legacy route path map. This maps the new route to the legacy route.
const pathMap: Record<string, LegacyRoute | null> = {
  [ALLEVA_PAGES['Add Prospect']]: '/#/quickAdd',
  [ALLEVA_PAGES['Alumni']]: '/#/Alumni',
  [ALLEVA_PAGES['Billing Application']]: null,
  [ALLEVA_PAGES['Chef Dashboard']]: '/#/ChefDashBoard',
  [ALLEVA_PAGES['Client Admission Screening']]: null,
  [ALLEVA_PAGES['Client Billing Intake']]: null,
  [ALLEVA_PAGES['Client Biopsychosocial Intake']]: null,
  [ALLEVA_PAGES['Client Calendar']]: '/#/myCalendar',
  [ALLEVA_PAGES['Client Chart']]: '/#/ClientChart',
  [ALLEVA_PAGES['Client Discharge Planning']]: '/#/DischargePlanning',
  [ALLEVA_PAGES['Client Doctors Orders']]: '/#/doctorsorders/',
  [ALLEVA_PAGES['Client Document Manager']]: '/#/documentManager',
  [ALLEVA_PAGES['Client Edit']]: null,
  [ALLEVA_PAGES['Client Face to Face']]: null,
  [ALLEVA_PAGES['Client Homework']]: '/#/clientHomework',
  [ALLEVA_PAGES['Client List']]: null,
  [ALLEVA_PAGES['Client Meal Plan']]: '/#/ClientMealPlan',
  [ALLEVA_PAGES['Client Medical Intake']]: null,
  [ALLEVA_PAGES['Client Medication Administration Record']]: '/#/clientMAR',
  [ALLEVA_PAGES['Client Medication']]: '/#/ClientMedication/',
  [ALLEVA_PAGES['Client Notes']]: '/#/Notes',
  [ALLEVA_PAGES['Client Rec Therapy']]: '/#/RecTherapyAssessment',
  [ALLEVA_PAGES['Client Treatment Plan']]: '/#/clientTreatmentPlan',
  [ALLEVA_PAGES['Dashboard']]: '/#/dashboardv2',
  [ALLEVA_PAGES['Discharge Reports']]: '/#/Reports',
  [ALLEVA_PAGES['Doctors Orders']]: '/#/doctorsorders//',
  [ALLEVA_PAGES['Encounter Transmission']]: '/#/encounter-transmission',
  [ALLEVA_PAGES['Historical Events']]: '/#/historicalEvents',
  [ALLEVA_PAGES['Incidents']]: '/#/clientIncidentList',
  [ALLEVA_PAGES['Lab Orders']]: '/#/LabOrders',
  [ALLEVA_PAGES['Medical Provider Scheduler']]: '/#/medicalProviderScheduler',
  [ALLEVA_PAGES['Medication Destruction']]: '/#/MedicationDestruction',
  [ALLEVA_PAGES['Medications Count']]: '/#/MedsCount',
  [ALLEVA_PAGES['Medications Management']]: '/#/MedsManagement',
  [ALLEVA_PAGES['Meds Count Reports']]: '/#/MedsCountReport',
  [ALLEVA_PAGES['Notes Audit']]: '/#/notesaudit',
  [ALLEVA_PAGES['Notes Review']]: '/#/notesReview',
  [ALLEVA_PAGES['Notifications']]: '/#/Notifications',
  [ALLEVA_PAGES['Occupancy']]: '/#/Occupancy/',
  [ALLEVA_PAGES['Page Not Found (404)']]: null,
  [ALLEVA_PAGES['Progress Notes']]: null,
  [ALLEVA_PAGES['Prospect Client List']]: '/#/LeadList/',
  [ALLEVA_PAGES['Referrals']]: '/#/Referral',
  [ALLEVA_PAGES['Restricted Access']]: null,
  [ALLEVA_PAGES['Sandbox Buttons']]: null,
  [ALLEVA_PAGES['Sandbox Editor']]: null,
  [ALLEVA_PAGES['Sandbox Forms']]: null,
  [ALLEVA_PAGES['Sandbox Icons']]: null,
  [ALLEVA_PAGES['Sandbox Loading Indicators']]: null,
  [ALLEVA_PAGES['Sandbox Messages']]: null,
  [ALLEVA_PAGES['Sandbox Print']]: null,
  [ALLEVA_PAGES['Sandbox Signatures']]: null,
  [ALLEVA_PAGES['Sandbox Tables']]: null,
  [ALLEVA_PAGES['Scheduler']]: '/#/Scheduler',
  [ALLEVA_PAGES['Settings Admission Checklist']]: '/#/ManageAdmissionCheckList',
  [ALLEVA_PAGES['Settings Alerts Frequency']]: '/#/AlertsTimeShift',
  [ALLEVA_PAGES['Settings Alerts Permission']]: '/#/AlertsPermission',
  [ALLEVA_PAGES['Settings Alerts Template']]: '/#/AlertsTemplate',
  [ALLEVA_PAGES['Settings Alleva AI']]: null,
  [ALLEVA_PAGES['Settings Bed Management']]: '/#/manage-beds',
  [ALLEVA_PAGES['Settings Client Transfer']]: '/#/TransferredClient',
  [ALLEVA_PAGES['Settings Company Information']]: '/#/companyInformation',
  [ALLEVA_PAGES['Settings Daily Questionnaire']]: '/#/DailyQuestionnaire',
  [ALLEVA_PAGES['Settings Dietary']]: '/#/Dietary/',
  [ALLEVA_PAGES['Settings Digital Dashboard']]: '/#/digitalDashboard',
  [ALLEVA_PAGES['Settings Family Portal']]: '/#/familyportalsettings',
  [ALLEVA_PAGES['Settings Forms 2.0 Manager']]: '/#/formManager2/',
  [ALLEVA_PAGES['Settings Forms 3.0 Manager']]: '/#/forms.list',
  [ALLEVA_PAGES['Settings Forms Manager']]: '/#/formManager/',
  [ALLEVA_PAGES['Settings Lab Management']]: '/#/labManagement',
  [ALLEVA_PAGES['Settings Manage Discharge']]: '/#/manage-discharge',
  [ALLEVA_PAGES['Settings Manage Groups']]: '/#/ManageGroups',
  [ALLEVA_PAGES['Settings Manage Intake']]: '/#/IntakeCustomForm',
  [ALLEVA_PAGES['Settings Manage Survey']]: '/#/SurveyMonkey',
  [ALLEVA_PAGES['Settings Manage Treatment Plan Review']]: '/#/TPRSortOrder',
  [ALLEVA_PAGES['Settings Master Drop-Downs']]: '/#/MasterDropdowns',
  [ALLEVA_PAGES['Settings Medical Provider']]: '/#/medicalProviders',
  [ALLEVA_PAGES['Settings Office Templates']]: '/#/OfficeTemplate',
  [ALLEVA_PAGES['Settings Order']]: '/#/MedsSettings',
  [ALLEVA_PAGES['Settings Pharmacy Management']]: null,
  [ALLEVA_PAGES['Settings Profile']]: '/#/profile',
  [ALLEVA_PAGES['Settings Role Management']]: '/#/RoleManagement/',
  [ALLEVA_PAGES['Settings Templates']]: '/#/Templates',
  [ALLEVA_PAGES['Settings User Management']]: '/#/UserManagement',
  [ALLEVA_PAGES['Shift Notes']]: '/#/ShiftNotes',
  [ALLEVA_PAGES['Shift Rounds']]: '/#/ShiftRounds/0/0',
  [ALLEVA_PAGES['Signature Review']]: '/#/signatureReview',
  [ALLEVA_PAGES['Utilization Review']]: '/#/URDashboard',
};
