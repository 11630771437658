<mat-form-field
  *ngIf="
    (options && options.length) || (optionGroups && optionGroups.length);
    else loadingTemplate
  "
  [appearance]="'outline'"
>
  <mat-label *ngIf="label as inputLabel">{{ inputLabel }}</mat-label>
  <div *ngIf="prefixIcon" class="prefix-icon-container">
    <alleva-icon
      [colorHex]="baseControl.disabled ? 'var(--gray-mid-dark)' : undefined"
      [color]="
        baseControl.touched && baseControl.invalid ? 'warn' : prefixIcon.color
      "
      [name]="prefixIcon.name"
      [scale]="prefixIcon.scale"
      [type]="prefixIcon.type"
    ></alleva-icon>
  </div>
  <mat-select
    [ariaLabel]="label"
    [compareWith]="compareWith"
    [formControl]="baseControl"
    [multiple]="multiple"
    [placeholder]="placeholder || ''"
  >
    <!-- CUSTOM SELECTION VIEW -->
    <mat-select-trigger>
      <!-- DISPLAY SELECTED COUNTS FOR MULTI LIST -->
      <ng-container *ngIf="displayCounter; else defaultListView">
        {{ selectedOptions.length }} selected
      </ng-container>
      <!-- DEFAULT LIST/SINGLE VIEW -->
      <ng-template #defaultListView>
        <!-- MULTI LIST DISPLAY -->
        <ng-container *ngIf="multiple; else singleSelectView">
          <ng-container
            [ngTemplateOutlet]="labelView"
            [ngTemplateOutletContext]="{
              label: getSelectedOptionsLabels(selectedOptions),
            }"
          ></ng-container>
        </ng-container>
        <!-- SINGLE ITEM DISPLAY -->
        <ng-template #singleSelectView>
          <ng-container *ngIf="selectedOptions[0] as selectedOption">
            <ng-container
              [ngTemplateOutlet]="labelView"
              [ngTemplateOutletContext]="{
                fontColor: selectedOption.fontColor,
                iconName: selectedOption.iconName,
                label: selectedOption.element.textContent,
              }"
            ></ng-container>
          </ng-container>
        </ng-template>
      </ng-template>
    </mat-select-trigger>
    <div
      *ngIf="(selectAllEnabled && multiple) || isClearable"
      class="clear-select-all-container"
    >
      <!-- SELECT ALL OPTION -->
      <button
        (click)="toggleAll()"
        *ngIf="selectAllEnabled && multiple"
        [disabled]="!options.length || baseControl.disabled"
        class="select-all-dropdown-option"
        label="Select All"
        mat-button
      >
        <alleva-icon
          [name]="
            selectedOptions.length === options.length
              ? 'check_box'
              : 'check_box_outline_blank'
          "
        ></alleva-icon>
        Select All
      </button>
      <!-- CLEAR ALL OPTION -->
      <button
        (click)="clear()"
        *ngIf="isClearable"
        [disabled]="!selectedOptions.length || baseControl.disabled"
        class="clear-dropdown-option"
        label="Clear Dropdown"
        mat-button
      >
        <alleva-icon color="warn" name="close"></alleva-icon>
        {{ clearLabel || 'Clear' }}
      </button>
    </div>
    <!-- OPTION GROUPINGS -->
    <mat-optgroup *ngFor="let group of optionGroups" [label]="group.label">
      <mat-option
        *ngFor="let option of group.options"
        [class]="option.class"
        [class.has-icon]="!!option.iconName"
        [disabled]="option.disabled"
        [value]="option.value"
      >
        <ng-container
          [ngTemplateOutlet]="labelView"
          [ngTemplateOutletContext]="{
            fontColor: option.fontColor,
            iconName: option.iconName,
            label: option.element.textContent,
          }"
        ></ng-container>
      </mat-option>
    </mat-optgroup>
    <!-- SINGLE OPTIONS -->
    <mat-option
      *ngFor="let option of options"
      [class]="option.class"
      [class.has-icon]="!!option.iconName"
      [disabled]="option.disabled"
      [value]="option.value"
    >
      <ng-container
        [ngTemplateOutlet]="labelView"
        [ngTemplateOutletContext]="{
          fontColor: option.fontColor,
          iconName: option.iconName,
          label: option.element.textContent,
        }"
      ></ng-container>
    </mat-option>
  </mat-select>
  <mat-hint *ngIf="!hideHint && hint" [title]="hint">{{ hint }}</mat-hint>
  <mat-hint
    *ngIf="
      !hideHint &&
      awaitForHint &&
      ((awaitForChanges | async) === null ||
        (awaitForChanges | async) === undefined)
    "
    [title]="awaitForHint"
    >{{ awaitForHint }}</mat-hint
  >
  <mat-error *ngIf="baseControl.invalid && baseControl.touched">
    <ng-container *ngIf="baseControl.hasError('required')">
      {{ label }} is required.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('maxSelection')">
      You can only select up to {{ max }} items.
    </ng-container>
  </mat-error>
</mat-form-field>

<ng-template #loadingTemplate>
  <alleva-loading-text
    [awaitFor]="awaitForChanges | async"
    [awaitForHint]="awaitForHint"
    [label]="label"
    type="input"
  ></alleva-loading-text>
</ng-template>

<ng-template
  #labelView
  let-fontColor="fontColor"
  let-iconName="iconName"
  let-label="label"
>
  <span class="label" [class.has-icon]="!!iconName" [style.color]="fontColor">
    <alleva-icon
      *ngIf="iconName"
      [name]="iconName"
      [colorHex]="fontColor"
    ></alleva-icon>
    {{ label }}
  </span>
</ng-template>
