import { SearchAllApi } from 'api/models';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { Client, Facility, ReferralContact, UserBase } from 'src/app/models';
import { shareSingleReplay } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FacilityService } from './facility.service';

/**
 * A service for interacting with the Search API.
 */
@Injectable({ providedIn: 'root' })
export class SearchService {
  public constructor(
    private readonly facilityService: FacilityService,
    private readonly httpClient: HttpClient,
  ) {}

  public set isSearchOpen(value: boolean) {
    this.isSearchOpenSubject.next(value);
  }
  private readonly isSearchOpenSubject = new BehaviorSubject<boolean>(false);
  public readonly isSearchOpenChanges = this.isSearchOpenSubject
    .asObservable()
    .pipe(shareSingleReplay());

  /**
   * Search for clients, prospects, referrals, and users.
   *
   * @param query The search query.
   * @param facility The facility to search within.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The search results on success, undefined on error.
   */
  public searchAll(
    query: string,
    facility: Facility | null,
    deserializationArgs: SearchDeserializationArgs,
  ): Observable<SearchAll | undefined> {
    let params: HttpParams = new HttpParams().append('search', query);
    if (facility) {
      params = params.append('facilityId', facility.id.toString());
    }

    return this.httpClient
      .get<SearchAllApi | undefined>(`${config.api.url}/search-all`, { params })
      .pipe(
        map((response) => {
          if (!response) {
            throw new Error('Error, empty search response from API.');
          }
          return {
            clients: response.clients
              ? Client.deserializeList(response.clients, {
                  facilityTimeZone: deserializationArgs.facilityTimeZone,
                })
              : [],
            prospects: response.prospects
              ? Client.deserializeList(response.prospects, {
                  facilityTimeZone: deserializationArgs.facilityTimeZone,
                })
              : [],
            referrals: response.referrals
              ? ReferralContact.deserializeList(response.referrals)
              : [],
            users: response.users
              ? UserBase.deserializeList(response.users)
              : [],
          };
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

export interface SearchAll {
  clients: readonly Client[] | null;
  prospects: readonly Client[] | null;
  referrals: readonly ReferralContact[] | null;
  users: readonly UserBase[] | null;
}

export interface SearchDeserializationArgs {
  facilityTimeZone: Facility['timeZone'];
}
