import { AllevaAIPromptApi } from 'api/models/alleva-ai-api.model';
import { Observable, catchError, map, of } from 'rxjs';
import { AllevaAIPrompt, AllevaAIPromptUpdate } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/** Service for interacting with the Alleva AI API. */
@Injectable()
export class AllevaAIService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch (GET) the prompt data from the API.
   *
   * @returns The prompt data from the API, or undefined if an error occurred.
   */
  public getPrompts(): Observable<readonly AllevaAIPrompt[] | undefined> {
    return this.httpClient
      .get<
        AllevaAIPromptApi[] | null | undefined
      >(`${config.api.url}/ai/settings/admin-prompts`)
      .pipe(
        map((response) =>
          response ? response.map(AllevaAIPrompt.deserialize) : [],
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update (POST) the prompts in the API.
   *
   * @param prompts The prompts to update.
   * @returns Whether the prompts were successfully updated (bool).
   */
  public updatePrompts(
    prompts: readonly AllevaAIPromptUpdate[],
  ): Observable<boolean> {
    return this.httpClient
      .post<boolean>(
        `${config.api.url}/ai/settings/admin-prompts`,
        prompts.map((prompt) => prompt.serialize()),
      )
      .pipe(
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Reset (POST) the prompts in the API to their previous values.
   *
   * @returns Whether the prompts were successfully reset (bool).
   */
  public resetPrompts(): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${config.api.url}/ai/settings/admin-prompts/reset`, [])
      .pipe(
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }
}
