import { Observable, catchError, map, of } from 'rxjs';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the signature API.
 */
@Injectable()
export class SignatureService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Gets the signature of the user with the given pin.
   *
   * Note: No need to pass in user id, the API will use the authenticated user
   * header data attached to the request itself.
   *
   * @param pin The pin of the user to get the signature for.
   * @returns The signature of the user with the given pin.
   */
  public getByPin(pin: number): Observable<Base64<'png'> | undefined> {
    return this.httpClient
      .post<
        { signature: Base64<'png'> } | undefined
      >(`${config.api.url}/users/pin/check`, { pin })
      .pipe(
        map((response) =>
          response?.signature ? response.signature : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
