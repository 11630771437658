<table
  *ngIf="dataChanges | async as dataSource; else loadingTemplate"
  [dataSource]="dataSource"
  mat-table
  multiTemplateDataRows
>
  <ng-container
    *ngFor="let column of columnsChanges | async"
    [matColumnDef]="column.key"
  >
    <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
    <!-- DISPLAYED COLUMN WITH DYNAMIC TYPE HANDLING -->
    <td
      #item
      (click)="onRowClick(dataSourceItem, $event, column, item)"
      *matCellDef="let dataSourceItem"
      [class.is-copyable]="column.isClickToCopyEnabled"
      [matTooltip]="
        column.isClickToCopyEnabled ? 'Click to copy ' + column.label : ''
      "
      [style.min-width]="
        column.minWidthPx ? column.minWidthPx + 'px' : undefined
      "
      mat-cell
    >
      <ng-container *ngIf="getData(dataSourceItem, column.key) as data">
        <ng-container [ngSwitch]="getTypeOf(data)">
          <!-- CUSTOM COLUMN FOR DISPLAYING DATETIME OBJECTS -->
          <span
            *ngSwitchCase="'datetime'"
            [title]="data | dateTime: 'MM/dd/yyyy, hh:mm a (ZZZZ)'"
          >
            {{ data | dateTime: 'MM/dd/yyyy, hh:mm a' }}
          </span>
          <!-- CUSTOM COLUMN FOR DISPLAYING DATE OBJECTS -->
          <ng-container *ngSwitchCase="'date'">
            {{ getDate(data) | date: 'MM/dd/yyyy' }}
          </ng-container>
          <!-- CUSTOM COLUMN FOR DISPLAYING TIME OBJECTS -->
          <ng-container *ngSwitchCase="'time'">
            {{ getTime(data)?.toString() || emptyCellValuePlaceholder }}
          </ng-container>
          <!-- CUSTOM COLUMN FOR DISPLAYING OBJECTS -->
          <ng-container *ngSwitchCase="'object'">
            <!-- INJECTED TEMPLATE REFERENCE COLUMN -->
            <ng-container
              *ngIf="
                isTableTemplateRefObject(data)
                  ? data
                  : null as tableTemplateRefObject;
                else expandableObjectTemplate
              "
            >
              <ng-content
                *ngTemplateOutlet="
                  tableTemplateRefObject.templateRef;
                  context: isNonEmptyObject(tableTemplateRefObject.context)
                    ? tableTemplateRefObject.context
                    : null
                "
              ></ng-content>
            </ng-container>
            <!--
              DYNAMIC EXPAND/COLLAPSE COLUMN BUTTON. DISPLAYS WHEN THE COLUMN
              IS EXPANDABLE (DETECTING EXPANDABLE OBJECTS).
             -->
            <ng-template #expandableObjectTemplate>
              <ng-container
                *ngIf="
                  isTableTemplateRefExpandableObject(data)
                    ? data
                    : null as tableTemplateRefExpandableObject;
                  else defaultTemplate
                "
              >
                <alleva-button
                  (click)="
                    expandableObject =
                      expandableObject === tableTemplateRefExpandableObject
                        ? null
                        : tableTemplateRefExpandableObject;
                    $event.stopPropagation()
                  "
                  [icon]="{
                    name:
                      expandableObject === tableTemplateRefExpandableObject
                        ? 'keyboard_arrow_up'
                        : 'keyboard_arrow_down',
                  }"
                  [matTooltip]="
                    expandableObject === tableTemplateRefExpandableObject
                      ? 'Collapse'
                      : 'Expand'
                  "
                  [matTooltipPosition]="'after'"
                  class="expand-collapse-button"
                  color="primary"
                  label="View Client Allergy"
                  matTooltip="View"
                  styling="icon"
                ></alleva-button>
              </ng-container>
            </ng-template>
            <!-- A CATCH AND DISPLAY ALL FOR UNHANDLED TYPES -->
            <ng-template #defaultTemplate>
              {{ data }}
            </ng-template>
          </ng-container>
          <!-- DISPLAY PRIMITIVES -->
          <ng-container *ngSwitchDefault>
            {{ data }}
          </ng-container>
        </ng-container>
      </ng-container>
    </td>
    <!-- EMPTY CONTENT PLACEHOLDER -->
    <tr *matNoDataRow class="empty">
      <td [attr.colspan]="(columnsChanges | async)?.length || 0">
        {{ emptyListPlaceholder }}
      </td>
    </tr>
  </ng-container>
  <!-- EXPANDABLE ROW CONTENT -->
  <ng-container matColumnDef="expandedDetail">
    <td
      *matCellDef="let rowObjectData"
      [attr.colspan]="(columnKeysChanges | async)?.length ?? 1"
      mat-cell
    >
      <div
        class="expandable-details"
        [@collapseAnimation]="
          isRowExpanded(rowObjectData, expandableObject)
            ? 'expanded'
            : 'collapsed'
        "
      >
        <div
          *ngIf="
            expandableObject && isRowExpanded(rowObjectData, expandableObject)
          "
          [@exitAnimation]
        >
          <ng-content
            *ngTemplateOutlet="
              expandableObject.templateRef;
              context: isNonEmptyObject(expandableObject.context)
                ? expandableObject.context
                : null
            "
          ></ng-content>
        </div>
      </div>
    </td>
  </ng-container>
  <tr
    mat-header-row
    *matHeaderRowDef="columnKeysChanges | async; sticky: hasStickyHeader"
  ></tr>
  <tr
    *matRowDef="
      let rowObjectData;
      columns: columnKeysChanges | async;
      let i = dataIndex
    "
    [class]="i % 2 === 0 ? 'even' : 'odd'"
    mat-row
    (click)="onRowClick(rowObjectData, $event)"
  ></tr>
  <tr
    *matRowDef="let rowObjectData; columns: ['expandedDetail']"
    class="detail-row"
    mat-row
  ></tr>
</table>

<ng-template #loadingTemplate>
  <p class="loading">
    <ng-container *ngIf="(dataChanges | async) === null"
      >Loading...</ng-container
    >
    <ng-container *ngIf="(dataChanges | async) === undefined">
      <alleva-icon
        color="warn"
        inline="true"
        name="error_outline"
        type="two-tone"
      ></alleva-icon>
      Failed to load data...
    </ng-container>
  </p>
</ng-template>
