import { ClientUrgentAlertApi, ClientUrgentAlertUpdateApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  Client,
  ClientUrgentAlert,
  ClientUrgentAlertAcknowledgeUpdate,
  ClientUrgentAlertDeserializationArgs,
  ClientUrgentAlertResolveUpdate,
  ClientUrgentAlertUpdate,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Client Urgent Alerts API.
 */
@Injectable()
export class ClientUrgentAlertsService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Acknowledges an existing urgent alert for the given client and urgent alert
   *
   * @param clientId The client id to acknowledge the urgent alert for.
   * @param clientUrgentAlertId The urgent alert id to acknowledge.
   * @param clientUrgentAlertAcknowledgeUpdate The urgent alert acknowledge update.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The acknowledged urgent alert on success, undefined on error.
   */
  public acknowledge(
    clientId: Client['id'],
    clientUrgentAlertId: ClientUrgentAlert['id'],
    clientUrgentAlertAcknowledgeUpdate: ClientUrgentAlertAcknowledgeUpdate,
    deserializationArgs: ClientUrgentAlertDeserializationArgs,
  ): Observable<ClientUrgentAlert | undefined> {
    return this.httpClient
      .post<
        ClientUrgentAlertApi | undefined
      >(`${config.api.url}/clients/${clientId}/urgent-alerts/${clientUrgentAlertId}/acknowledge`, clientUrgentAlertAcknowledgeUpdate.serialize())
      .pipe(
        map((response) => {
          if (!response) {
            throw new Error('Urgent alert patch result is undefined.');
          }
          return ClientUrgentAlert.deserialize(response, deserializationArgs);
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Creates a new urgent alert for the given client.
   *
   * @param clientId The client id to create the urgent alert for.
   * @param urgentAlertUpdate The urgent alert to create.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The created urgent alert on success, undefined on error.
   * @throws An error if the urgent alert could not be created or if
   * deserialization of the returned object fails.
   */
  public create(
    clientId: Client['id'],
    urgentAlertUpdate: ClientUrgentAlertUpdate,
    deserializationArgs: ClientUrgentAlertDeserializationArgs,
  ): Observable<ClientUrgentAlert | undefined> {
    return this.httpClient
      .post<
        ClientUrgentAlertApi | undefined
      >(`${config.api.url}/clients/${clientId}/urgent-alerts`, urgentAlertUpdate.serialize())
      .pipe(
        map((response) => {
          if (!response) {
            throw new Error('Urgent alert create result is undefined.');
          }
          return ClientUrgentAlert.deserialize(response, deserializationArgs);
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return all Urgent Alerts data for the given client.
   *
   * @param clientId The client id to get the Urgent Alerts for.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The client Urgent Alerts list on success, undefined on error.
   * @throws An error if the client Urgent Alerts could not be fetched or if
   * deserialization of the returned object fails.
   */
  public getAll(
    clientId: Client['id'],
    deserializationArgs: ClientUrgentAlertDeserializationArgs,
  ): Observable<readonly ClientUrgentAlert[] | undefined> {
    return this.httpClient
      .get<
        readonly ClientUrgentAlertApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/urgent-alerts`)
      .pipe(
        map((response) => {
          if (response === undefined) {
            throw new Error('Response is undefined.');
          }
          return ClientUrgentAlert.deserializeList(
            response,
            deserializationArgs,
          );
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Resolve an existing urgent alert for the given client.
   *
   * @param clientId The client id to resolve the urgent alert for.
   * @param clientUrgentAlertId The urgent alert id to resolve.
   * @param clientUrgentAlertResolveUpdate The urgent alert resolve update.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The resolved urgent alert on success, undefined on error.
   */
  public resolve(
    clientId: Client['id'],
    clientUrgentAlertId: ClientUrgentAlert['id'],
    clientUrgentAlertResolveUpdate: ClientUrgentAlertResolveUpdate,
    deserializationArgs: ClientUrgentAlertDeserializationArgs,
  ): Observable<ClientUrgentAlert | undefined> {
    return this.httpClient
      .post<
        ClientUrgentAlertApi | undefined
      >(`${config.api.url}/clients/${clientId}/urgent-alerts/${clientUrgentAlertId}/resolve`, clientUrgentAlertResolveUpdate.serialize())
      .pipe(
        map((response) => {
          if (!response) {
            throw new Error('Urgent alert patch result is undefined.');
          }
          return ClientUrgentAlert.deserialize(response, deserializationArgs);
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Updates an existing urgent alert for the given client and urgent alert id.
   *
   * @param clientId The client id to update the urgent alert for.
   * @param urgentAlertId The urgent alert id to update.
   * @param urgentAlertUpdate The urgent alert update.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The updated urgent alert on success, undefined on error.
   */
  public update(
    clientId: Client['id'],
    urgentAlertId: ClientUrgentAlert['id'],
    urgentAlertUpdate:
      | Partial<ClientUrgentAlertUpdateApi>
      | ClientUrgentAlertUpdate,
    deserializationArgs: ClientUrgentAlertDeserializationArgs,
  ): Observable<ClientUrgentAlert | undefined> {
    return this.httpClient
      .patch<
        ClientUrgentAlertApi | undefined
      >(`${config.api.url}/clients/${clientId}/urgent-alerts/${urgentAlertId}`, urgentAlertUpdate instanceof ClientUrgentAlertUpdate ? urgentAlertUpdate.serialize() : urgentAlertUpdate)
      .pipe(
        map((response) => {
          if (!response) {
            throw new Error('Urgent alert patch result is undefined.');
          }
          return ClientUrgentAlert.deserialize(response, deserializationArgs);
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
