<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'input'">
    <mat-form-field appearance="outline">
      <input [formControl]="formControl" matInput type="text" />
      <mat-hint *ngIf="!hideHint && awaitForHint" [title]="awaitForHint">{{
        awaitForHint
      }}</mat-hint>
    </mat-form-field>
  </ng-container>
  <ng-container *ngSwitchCase="'text'">
    <span [title]="loadingText">{{ loadingText }}</span>
  </ng-container>
</ng-container>
