import { getCelsiusFrom } from 'src/app/utilities/temperature';

/**
 * A list of common validation constants used throughout the application.
 */
export const VALIDATION = {
  address: {
    maxLength: 100,
    minLength: 1,
  },
  city: {
    maxLength: 30,
    minLength: 1,
  },
  currency: {
    maxlength: 14,
  },
  email: {
    maxLength: 100,
    minLength: 3, // Example: `a@a`
  },
  firstName: {
    maxLength: 30,
    minLength: 1,
  },
  height: {
    imperial: {
      feet: {
        max: 9,
        min: 1,
      },
      inches: {
        max: 12,
        min: 0,
      },
    },
    metric: {
      centimeters: {
        max: 274.32, // 9 feet in centimeters.
        min: 1,
      },
    },
  },
  lastName: {
    maxLength: 30,
    minLength: 1,
  },
  middleName: {
    maxLength: 30,
    minLength: 1,
  },
  password: {
    maxLength: 255,
    minLength: 8,
  },
  phone: {
    maxLength: 20, // Example: `1 (555) 555-555-5555`
    minLength: 7, // Example: `5551234`
  },
  postal: {
    // @todo - Update to 10 characters in the API.
    maxLength: 8, // Example: `12345-67`
    minLength: 5, // Example: `12345`
  },
  socialSecurityNumber: {
    maxLength: 11, // Example: `123-45-6789`
    minLength: 9, // Example: `123456789`
  },
  vitals: {
    bloodSugar: {
      max: 600,
      min: 1,
    },
    bloodSugarWitness: {
      maxLength: 50,
    },
    /**
     * API NOTES:
     * - Must be less than the `Systolic` value per API.
     */
    diastolic: {
      max: 400,
      min: 20,
    },
    oxygenSaturationPercentage: {
      max: 100,
      min: 1,
    },
    pulse: {
      max: 600,
      min: 20,
    },
    respirations: {
      max: 300,
      min: 1,
    },
    supplementalOxygenFlowRate: {
      max: 10,
      min: 0.5,
    },
    /**
     * API NOTES:
     * - Must be greater than the `Diastolic` value.
     */
    systolic: {
      max: 400,
      min: 20,
    },
    temperature: {
      celsius: {
        max: getCelsiusFrom({ fahrenheit: 110 }),
        min: getCelsiusFrom({ fahrenheit: 95 }),
      },
      fahrenheit: {
        max: 110,
        min: 95,
      },
    },
    weight: {
      max: 9999, // Essentially no max value.
      min: 1,
    },
  },
};
