import { BillingRuleClaimTypeOfBillDetailsApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<BillingRuleClaimTypeOfBillDetailsApi>();

type BillingRuleClaimTypeOfBillDetailsArgs = Omit<
  ClassProperties<BillingRuleClaimTypeOfBillDetails>,
  // Omit UI only, computed properties based on core model data.
  'displayValue'
>;

export class BillingRuleClaimTypeOfBillDetails {
  public constructor(
    props: ClassProperties<BillingRuleClaimTypeOfBillDetailsArgs>,
  ) {
    this.active = props.active;
    this.code = props.code;
    this.id = props.id;
    this.name = props.name;
    this.type = props.type;

    // Computed values
    this.displayValue = `${this.code} - ${this.name}`;
  }

  /**
   * The io-ts codec for runtime type checking of the Billing Payor Rule Claim
   * Type of Bill Care API model.
   */
  public static readonly Codec = io.type(
    {
      active: io.boolean,
      code: io.string,
      id: io.number,
      name: io.string,
      type: io.number,
    },
    'BillingRuleClaimTypeOfBillDetailsApi',
  );

  @api({ key: 'active' }) public readonly active: boolean;
  @api({ key: 'code' }) public readonly code: string;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'name' }) public readonly name: string;
  @api({ key: 'type' }) public readonly type: number;

  // Computed properties
  public readonly displayValue: string;

  /**
   * Deserializes a Billing Payor Rule Claim Type of Bill Care object from the API
   * model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Billing Payor Rule Claim Type fo Bill Care object.
   * @throws An error if the value is not a valid Billing Payor Rule Claim
   * Type of Bill Care object.
   */
  public static deserialize(
    value: NonNullable<BillingRuleClaimTypeOfBillDetailsApi>,
  ): BillingRuleClaimTypeOfBillDetails {
    const decoded = decode(BillingRuleClaimTypeOfBillDetails.Codec, value);
    return new BillingRuleClaimTypeOfBillDetails({
      active: decoded.active,
      code: decoded.code,
      id: decoded.id,
      name: decoded.name,
      type: decoded.type,
    });
  }
}
