import { InsuranceAuthorizationApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  Client,
  ClientInsurance,
  InsuranceAuthorization,
  InsuranceAuthorizationDeserializationArgs,
  InsuranceAuthorizationUpdate,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ClientInsuranceAuthorizationService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Delete an insurance authorization record by id.
   *
   * @param insuranceId The associated insurance id of the authorization to delete.
   * @param insuranceAuthorizationId The id of the authorization to delete.
   * @returns True if the authorization was deleted, false otherwise.
   */
  public delete(
    clientId: Client['id'],
    insuranceId: ClientInsurance['id'],
    insuranceAuthorizationId: InsuranceAuthorization['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<
        boolean | undefined
      >(`${config.api.url}/clients/${clientId}/insurance/${insuranceId}/authorizations/${insuranceAuthorizationId}`, { observe: 'response' })
      .pipe(
        // Return bool based on 'success' status codes (200's) check.
        map((response) => response.status >= 200 && response.status < 300),
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Fetches a list of insurance authorizations for the given insurance id.
   *
   * @param clientId The id of the client to fetch authorizations for.
   * @param insuranceId The id of the insurance to fetch authorizations for.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns A list of insurance authorizations for the given insurance id.
   */
  public getList(
    clientId: Client['id'],
    insuranceId: ClientInsurance['id'],
    deserializationArgs: InsuranceAuthorizationDeserializationArgs,
  ): Observable<readonly InsuranceAuthorization[] | undefined> {
    return this.httpClient
      .get<
        readonly InsuranceAuthorizationApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/insurance/${insuranceId}/authorizations`)
      .pipe(
        map((response) =>
          response
            ? InsuranceAuthorization.deserializeList(
                response,
                deserializationArgs,
              )
            : [],
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Patch an insurance authorization record.
   *
   * @param clientId The id of the client to patch the authorization for.
   * @param insuranceId The id of the insurance to patch the authorization for.
   * @param insuranceAuthorizationId The id of the authorization to patch.
   * @param insuranceAuthorizationUpdate The values to patch.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The patched insurance authorization record, or undefined on error.
   */
  public patch(
    clientId: Client['id'],
    insuranceId: ClientInsurance['id'],
    insuranceAuthorizationId: InsuranceAuthorization['id'],
    insuranceAuthorizationUpdate: InsuranceAuthorizationUpdate,
    deserializationArgs: InsuranceAuthorizationDeserializationArgs,
  ): Observable<InsuranceAuthorization | undefined> {
    return this.httpClient
      .patch<
        InsuranceAuthorizationApi | undefined
      >(`${config.api.url}/clients/${clientId}/insurance/${insuranceId}/authorizations/${insuranceAuthorizationId}`, insuranceAuthorizationUpdate.serialize())
      .pipe(
        map((response) => {
          if (!response) {
            throw new Error('Response is undefined');
          }
          return InsuranceAuthorization.deserialize(
            response,
            deserializationArgs,
          );
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Add (post) a new insurance authorization record for the provided insurance
   * by id.
   *
   * @param clientId The id of the client to add the new authorization for.
   * @param insuranceId The id of the insurance to add the new authorization for.
   * @param insuranceAuthorizationUpdate The authorization values to post.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The new insurance authorization record, or undefined on error.
   */
  public post(
    clientId: Client['id'],
    insuranceId: ClientInsurance['id'],
    insuranceAuthorizationUpdate: InsuranceAuthorizationUpdate,
    deserializationArgs: InsuranceAuthorizationDeserializationArgs,
  ): Observable<InsuranceAuthorization | undefined> {
    return this.httpClient
      .post<
        InsuranceAuthorizationApi | undefined
      >(`${config.api.url}/clients/${clientId}/insurance/${insuranceId}/authorizations`, insuranceAuthorizationUpdate.serialize())
      .pipe(
        map((response) =>
          response
            ? InsuranceAuthorization.deserialize(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
