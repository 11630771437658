import { ClientProvidersApi, ProvidersApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  CaseManager,
  CaseManagerDeserializationArgs,
  Clinician,
  ClinicianDeserializationArgs,
  CollaborateMD,
  CollaborateMDDeserializationArgs,
  MedicalProvider,
  MedicalProviderDeserializationArgs,
  Nurse,
  NurseDeserializationArgs,
  RecreationalTherapist,
  RecreationalTherapistDeserializationArgs,
} from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the providers API.
 */
@Injectable()
export class ProvidersService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Gets a list of all providers by the given facility ID.
   *
   * @param facilityId The ID of the facility to get providers for.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns A list of all providers, or undefined on failure.
   */
  public getFacilityList(
    facilityId: number,
    deserializationArgs: CaseManagerDeserializationArgs &
      ClinicianDeserializationArgs &
      MedicalProviderDeserializationArgs &
      NurseDeserializationArgs &
      RecreationalTherapistDeserializationArgs,
  ): Observable<ProvidersResponse | undefined> {
    return this.httpClient
      .get<ProvidersApi | undefined>(`${config.api.url}/providers`, {
        params: { facilityId },
      })
      .pipe(
        map((response) => {
          if (!response) {
            return undefined;
          }
          return {
            caseManagers: response.caseManagers
              ? CaseManager.deserializeList(
                  response.caseManagers,
                  deserializationArgs,
                )
              : [],
            clinicians: response.clinicians
              ? Clinician.deserializeList(
                  response.clinicians,
                  deserializationArgs,
                )
              : [],
            medicalProviders: response.medicalProviders
              ? MedicalProvider.deserializeList(
                  response.medicalProviders,
                  deserializationArgs,
                )
              : [],
            nurses: response.nurses
              ? Nurse.deserializeList(response.nurses, deserializationArgs)
              : [],
            recreationalTherapists: response.recTherapists
              ? RecreationalTherapist.deserializeList(
                  response.recTherapists,
                  deserializationArgs,
                )
              : [],
          };
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Gets a list of all providers currently assigned to the given client.
   *
   * @param clientId The ID of the client to get providers for.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns A list of all providers assigned to the given client, or undefined on failure.
   */
  public getClientList(
    clientId: number,
    deserializationArgs: CaseManagerDeserializationArgs &
      ClinicianDeserializationArgs &
      CollaborateMDDeserializationArgs &
      NurseDeserializationArgs &
      MedicalProviderDeserializationArgs &
      RecreationalTherapistDeserializationArgs,
  ): Observable<ClientProvidersResponse | undefined> {
    return this.httpClient
      .get<
        ClientProvidersApi | undefined
      >(`${config.api.url}/clients/${clientId}/providers`)
      .pipe(
        map((response) => {
          if (!response) {
            return undefined;
          }
          return {
            caseManager: response.caseManager
              ? CaseManager.deserialize(
                  response.caseManager,
                  deserializationArgs,
                )
              : null,
            clinicians: response.clinicians
              ? Clinician.deserializeList(
                  response.clinicians,
                  deserializationArgs,
                )
              : null,
            collabMDProvider: response.collabMDProvider
              ? CollaborateMD.deserialize(
                  response.collabMDProvider,
                  deserializationArgs,
                )
              : null,
            nurses: response.nurses
              ? Nurse.deserializeList(response.nurses, deserializationArgs)
              : null,
            providers: response.providers
              ? MedicalProvider.deserializeList(
                  response.providers,
                  deserializationArgs,
                )
              : null,
            recTherapist: response.recTherapist
              ? RecreationalTherapist.deserialize(
                  response.recTherapist,
                  deserializationArgs,
                )
              : null,
          };
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Updates the medical providers assigned to the given client.
   *
   * @param clientId The ID of the client to update.
   * @param providerIds The IDs of the providers to assign to the client, null for empty.
   * @returns True if the update was successful, false if the update failed, or undefined on failure.
   */
  public updateClientMedicalProviders(
    clientId: number,
    medicalProviderIds: readonly number[] | null,
  ): Observable<boolean | undefined> {
    return this.httpClient
      .post<
        boolean | undefined
      >(`${config.api.url}/clients/${clientId}/providers/medical`, medicalProviderIds?.map((id) => ({ id })) ?? null)
      .pipe(
        map((response) => (response ? true : false)),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Updates the primary clinicians assigned to the given client.
   *
   * @param clientId The ID of the client to update.
   * @param clinicianIds The IDs of the clinicians to assign to the client, null for empty.
   * @returns True if the update was successful, false if the update failed, or undefined on failure.
   */
  public updateClientPrimaryClinicians(
    clientId: number,
    clinicianIds: readonly number[] | null,
  ): Observable<boolean | undefined> {
    return this.httpClient
      .post<
        boolean | undefined
      >(`${config.api.url}/clients/${clientId}/providers/clinicians`, clinicianIds?.map((id) => ({ id })) ?? null)
      .pipe(
        map((response) => (response ? true : false)),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Updates the case managers assigned to the given client.
   *
   * @param clientId The ID of the client to update.
   * @param caseManagerId The ID of the case manager to assign to the client, null for empty.
   * @returns True if the update was successful, false if the update failed, or undefined on failure.
   * @todo Move to the Client PATCH service.
   */
  public updateClientCaseManager(
    clientId: number,
    caseManagerId: number | null,
  ): Observable<boolean | undefined> {
    return this.httpClient
      .patch<boolean | undefined>(`${config.api.url}/clients/${clientId}`, {
        caseManager: caseManagerId ? { id: caseManagerId } : null,
      })
      .pipe(
        map((response) => (response ? true : false)),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Updates the recreational therapist assigned to the given client.
   *
   * @param clientId The ID of the client to update.
   * @param recreationalTherapistId The ID of the recreational therapist to assign to the client, null for empty.
   * @returns True if the update was successful, false if the update failed, or undefined on failure.
   * @todo Move to the Client PATCH service.
   */
  public updateClientRecreationalTherapist(
    clientId: number,
    recreationalTherapistId: number | null,
  ): Observable<boolean | undefined> {
    return this.httpClient
      .patch<boolean | undefined>(`${config.api.url}/clients/${clientId}`, {
        recTherapist: recreationalTherapistId
          ? { id: recreationalTherapistId }
          : null,
      })
      .pipe(
        map((response) => (response ? true : false)),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Updates the nurses assigned to the given client.
   *
   * @param clientId The ID of the client to update.
   * @param nurseIds The IDs of the nurses to assign to the client, null on empty.
   * @returns True if the update was successful, false if the update failed, or undefined on failure.
   */
  public updateClientNurses(
    clientId: number,
    nurseIds: readonly number[] | null,
  ): Observable<boolean | undefined> {
    return this.httpClient
      .post<
        boolean | undefined
      >(`${config.api.url}/clients/${clientId}/providers/nurses`, nurseIds?.map((id) => ({ id })) ?? null)
      .pipe(
        map((response) => (response ? true : false)),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Updates the CollabMD providers assigned to the given client.
   *
   * @param clientId The ID of the client to update.
   * @param collabMDId The ID of the CollabMD provider to assign to the client.
   * @returns True if the update was successful, false if the update failed, or undefined on failure.
   */
  public updateClientCollabMD(
    clientId: number,
    collabMDId: number | null,
  ): Observable<boolean | undefined> {
    return this.httpClient
      .post<
        boolean | undefined
      >(`${config.api.url}/clients/${clientId}/providers/collab-md`, { leadId: clientId, id: collabMDId ? collabMDId : 0 })
      .pipe(
        map((response) => (response ? true : false)),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

interface ProvidersResponse {
  caseManagers: readonly CaseManager[];
  clinicians: readonly Clinician[];
  medicalProviders: readonly MedicalProvider[];
  nurses: readonly Nurse[];
  recreationalTherapists: readonly RecreationalTherapist[];
}

interface ClientProvidersResponse {
  caseManager: CaseManager | null;
  clinicians: readonly Clinician[] | null;
  collabMDProvider: CollaborateMD | null;
  nurses: readonly Nurse[] | null;
  providers: readonly MedicalProvider[] | null;
  recTherapist: RecreationalTherapist | null;
}
