<div class="inputs-container">
  <mat-form-field [appearance]="'outline'" class="temperature-value">
    <mat-label>Body Temperature</mat-label>
    <div class="prefix-icon-container">
      <alleva-icon
        [colorHex]="baseControl.disabled ? 'var(--gray-mid-dark)' : undefined"
        [color]="hasErrors ? 'warn' : 'primary'"
        class="prefix-icon"
        name="temperature"
      ></alleva-icon>
    </div>
    <span class="input-container">
      <input
        [formControl]="formGroup.controls['value']"
        [id]="valueId"
        [required]="required"
        autocomplete="off"
        matInput
        name="Body Temperature"
        step="0.1"
        type="number"
      />
    </span>
  </mat-form-field>
  <mat-form-field [appearance]="'outline'" class="temperature-units">
    <mat-label>Units</mat-label>
    <span class="input-container">
      <mat-select
        [formControl]="formGroup.controls['units']"
        [id]="unitsId"
        [multiple]="false"
        [required]="required"
        name="Units"
      >
        <mat-option [value]="TemperatureUnitsEnum.FAHRENHEIT">
          &deg;{{ TemperatureUnitsEnum.FAHRENHEIT.toUpperCase() }}
        </mat-option>
        <mat-option [value]="TemperatureUnitsEnum.CELSIUS">
          &deg;{{ TemperatureUnitsEnum.CELSIUS.toUpperCase() }}
        </mat-option>
        <mat-option [value]="null">None</mat-option>
      </mat-select>
    </span>
  </mat-form-field>
</div>
<!-- HINT -->
<mat-hint
  *ngIf="!hasErrors && !hideHint && hint; else errorsTemplate"
  [title]="hint"
  >{{ hint }}</mat-hint
>
<!-- ERRORS -->
<ng-template #errorsTemplate>
  <div class="errors-container" *ngIf="hasErrors || !hideHint">
    <!-- TEMPERATURE VALUE INPUT VALIDATION ERRORS -->
    <ng-container *ngIf="ctrlValue.invalid && ctrlValue.touched">
      <mat-error>
        <ng-container *ngIf="ctrlValue.hasError('required')">
          Temperature is required.
        </ng-container>
        <ng-container *ngIf="ctrlValue.hasError('min')">
          Temperature must be at least
          {{ ctrlValue.errors?.['min'].min | number: '1.2-2' }}.
        </ng-container>
        <ng-container *ngIf="ctrlValue.hasError('max')">
          Temperature cannot exceed
          {{ ctrlValue.errors?.['max'].max | number: '1.2-2' }}.
        </ng-container>
      </mat-error>
    </ng-container>
    <!-- TEMPERATURE UNITS INPUT VALIDATION ERRORS -->
    <ng-container *ngIf="ctrlUnits.invalid && ctrlUnits.touched">
      <mat-error *ngIf="ctrlValue.valid || ctrlValue.untouched">
        <ng-container *ngIf="ctrlUnits.hasError('required')">
          Units is required.
        </ng-container>
      </mat-error>
    </ng-container>
  </div>
</ng-template>
