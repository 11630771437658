import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { InputAutofillComponent } from 'src/app/components/forms/input-autofill/input-autofill.component';
import { IconModule } from 'src/app/components/icon/icon.module';
import { LoadingTextModule } from 'src/app/components/loading-text/loading-text.component.module';

@NgModule({
  declarations: [InputAutofillComponent],
  exports: [InputAutofillComponent],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    LoadingTextModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class InputAutofillModule {}
