import { MedicationApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { Medication } from 'src/app/models';
import { parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Medications API.
 */
@Injectable()
export class MedicationsService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch and return Medications data.
   *
   * @param requestParameters The list of request parameters to use for the query.
   * @returns All [filtered] Medications data on success, undefined on error.
   */
  public getList(
    requestParameters?: readonly MedicationsRequestParam[],
  ): Observable<readonly Medication[] | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<readonly MedicationApi[] | undefined>(
        `${config.api.url}/medications/search`,
        {
          params,
        },
      )
      .pipe(
        map((response) => {
          if (!response) {
            throw new Error('Failed to fetch Medications data.');
          }
          return Medication.deserializeList(response);
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

/**
 * Request parameter interface for use with the Medications API.
 */
export interface MedicationsRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'name' | 'routed';
  /** The value to use for the query parameter. */
  value: string | boolean;
}
