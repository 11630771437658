/**
 * This file is the single source of truth for all route names available in
 * the application.
 */
export const ALLEVA_ROUTE_NAMES = [
  'Add Prospect',
  'Alumni',
  'Billing Application',
  'Billing Settings - Add Billing Rule',
  'Billing Settings - Billing Rules',
  'Billing Settings - Copy Billing Rule',
  'Billing Settings - Edit Billing Rule',
  'Billing Settings - Codes',
  'Billing Settings - Insurance Companies',
  'Billing Settings - Modifiers',
  'Billing Settings - Rev Codes',
  'Billing Settings',
  'Chef Dashboard',
  'Client Admission Screening',
  'Client Billing Intake',
  'Client Biopsychosocial Intake',
  'Client Calendar',
  'Client Chart',
  'Client Discharge Planning',
  'Client Doctors Orders',
  'Client Document Manager',
  'Client Edit',
  'Client Face to Face',
  'Client Homework',
  'Client List',
  'Client Meal Plan',
  'Client Medical Intake',
  'Client Medication Administration Record',
  'Client Medication',
  'Client Notes',
  'Client Rec Therapy',
  'Client Treatment Plan',
  'Dashboard',
  'Discharge Reports',
  'Doctors Orders',
  'Encounter Transmission',
  'Forbidden',
  'Historical Events',
  'Incidents',
  'Insights',
  'Lab Orders',
  'Medical Provider Scheduler',
  'Medication Destruction',
  'Medications Count',
  'Medications Management',
  'Meds Count Reports',
  'Notes Audit',
  'Notes Review',
  'Notifications',
  'Occupancy',
  'Page Not Found (404)',
  'Progress Notes',
  'Prospect Client List',
  'Referrals',
  'Restricted Access',
  'Sandbox Buttons',
  'Sandbox Editor',
  'Sandbox Forms',
  'Sandbox Icons',
  'Sandbox Loading Indicators',
  'Sandbox Messages',
  'Sandbox Print',
  'Sandbox Signatures',
  'Sandbox Tables',
  'Scheduler',
  'Settings Admission Checklist',
  'Settings Alerts Frequency',
  'Settings Alerts Permission',
  'Settings Alerts Template',
  'Settings Alleva AI',
  'Settings Bed Management',
  'Settings Client Transfer',
  'Settings Company Information',
  'Settings Daily Questionnaire',
  'Settings Dietary',
  'Settings Digital Dashboard',
  'Settings Family Portal',
  'Settings Forms 2.0 Manager',
  'Settings Forms 3.0 Manager',
  'Settings Forms Manager',
  'Settings Lab Management',
  'Settings Manage Discharge',
  'Settings Manage Groups',
  'Settings Manage Intake',
  'Settings Manage Survey',
  'Settings Manage Treatment Plan Review',
  'Settings Master Drop-Downs',
  'Settings Medical Provider',
  'Settings Office Templates',
  'Settings Order',
  'Settings Pharmacy Management',
  'Settings Profile',
  'Settings Role Management',
  'Settings Templates',
  'Settings User Management',
  'Shift Notes',
  'Shift Rounds',
  'Signature Review',
  'Utilization Review',
] as const;
