import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs';
import { isRouteData, shareSingleReplay } from 'src/app/utilities';

import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

/**
 * Service for getting the current route data and information.
 */
@Injectable({ providedIn: 'root' })
export class CurrentRouteDataService {
  public constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {}

  public readonly routeDataChanges = this.router.events.pipe(
    map((event) => event instanceof NavigationEnd),
    filter((isNavigationEndEvent) => !!isNavigationEndEvent),
    map(() => this.activatedRoute),
    map((route) => {
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    }),
    filter((route) => route.outlet === 'primary'),
    mergeMap((route) => route.data),
    map((data) => (isRouteData(data) ? data : null)),
    distinctUntilChanged(),
    shareSingleReplay(),
  );
}
