import { BillingRulePlaceOfServiceApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<BillingRulePlaceOfServiceApi>();

type BillingRulePlaceOfServiceArgs = Omit<
  ClassProperties<BillingRulePlaceOfService>,
  // Omit UI only, computed properties based on core model data.
  'displayValue'
>;

export class BillingRulePlaceOfService {
  public constructor(props: ClassProperties<BillingRulePlaceOfServiceArgs>) {
    this.code = props.code;
    this.description = props.description;
    this.id = props.id;
    this.isActive = props.isActive;
    this.name = props.name;

    // Computed values
    this.displayValue = `${this.code} - ${this.name}`;
  }

  /**
   * The io-ts codec for runtime type checking of the Billing Payor Rule Place
   * of Service API model.
   */
  public static readonly Codec = io.type(
    {
      code: io.number,
      description: io.string,
      id: io.number,
      isActive: io.boolean,
      name: io.string,
    },
    'BillingRulePlaceOfServiceApi',
  );

  @api({ key: 'code' }) public readonly code: number;
  @api({ key: 'description' }) public readonly description: string;
  @api({ key: 'id' }) public readonly id: number;
  @api({ key: 'isActive' }) public readonly isActive: boolean;
  @api({ key: 'name' }) public readonly name: string;

  // Computed properties
  public readonly displayValue: string;

  /**
   * Deserializes a Billing Payor Rule Place of Service object from the API
   * model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Billing Payor Rule Place of Service object.
   * @throws An error if the value is not a valid Billing Payor Rule Place of
   * Service object.
   */
  public static deserialize(
    value: NonNullable<BillingRulePlaceOfServiceApi>,
  ): BillingRulePlaceOfService {
    const decoded = decode(BillingRulePlaceOfService.Codec, value);
    return new BillingRulePlaceOfService({
      code: decoded.code,
      description: decoded.description,
      id: decoded.id,
      isActive: decoded.isActive,
      name: decoded.name,
    });
  }
}
