import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject, distinctUntilChanged } from 'rxjs';
import { ScreenSizeEnum } from 'src/app/enumerators';
import { shareSingleReplay } from 'src/app/utilities';

import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ScreenSizeService {
  public constructor(private readonly breakpointObserver: BreakpointObserver) {}

  private readonly currentScreenSizeSubject = new ReplaySubject<ScreenSizeEnum>(
    1,
  );

  /** The current screen size (mobile, tablet, desktop). */
  public readonly currentScreenSizeChanges = this.currentScreenSizeSubject
    .asObservable()
    .pipe(distinctUntilChanged(), shareSingleReplay());

  private isInitialized = false;

  public initialize(): void {
    if (this.isInitialized) {
      throw new Error('The screen size service is already initialized!');
    }
    this.isInitialized = true;

    this.breakpointObserver
      .observe(Object.keys(breakpointMap))
      .pipe(untilDestroyed(this))
      .subscribe((state: BreakpointState) => {
        const mapEntries = Object.entries(breakpointMap);
        for (const [breakpoint, screenSize] of mapEntries) {
          if (state.breakpoints[breakpoint]) {
            this.currentScreenSizeSubject.next(screenSize);
            break;
          }
        }
      });
  }
}

const breakpointMap: { [key: string]: ScreenSizeEnum } = {
  [Breakpoints.XSmall]: ScreenSizeEnum.MOBILE,
  [Breakpoints.Small]: ScreenSizeEnum.TABLET,
  [Breakpoints.Medium]: ScreenSizeEnum.TABLET,
  [Breakpoints.Large]: ScreenSizeEnum.DESKTOP,
  [Breakpoints.XLarge]: ScreenSizeEnum.DESKTOP,
};
