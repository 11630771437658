import { DateTime, MinuteNumbers } from 'luxon';

export type TimeArgs = Omit<Time, 'toDateTime' | 'toString'>;

export class Time {
  public constructor(props: ClassProperties<TimeArgs>) {
    this.hour = props.hour;
    this.minute = props.minute;
    this.meridiem = props.meridiem;
  }

  public readonly hour: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  public readonly minute: MinuteNumbers;
  public readonly meridiem: 'AM' | 'PM';

  /**
   * Convert the `DateTime` object to a 12 hour meridiem `Time` object.
   *
   * @param dateTime The `DateTime` object to convert to a 12 hour meridiem `Time` object.
   * @returns The 12 hour meridiem `Time` object.
   */
  public static fromDateTime(dateTime: DateTime): Time {
    return new Time({
      hour: (((dateTime.hour + 11) % 12) + 1) as Time['hour'],
      minute: dateTime.minute as MinuteNumbers,
      meridiem: dateTime.hour < 12 ? 'AM' : 'PM',
    });
  }

  /**
   * Convert the 12 hour meridiem time (`Time`) to a 24 hour `DateTime` object.
   *
   * @param day Day value to be used in returned `DateTime` object.
   * @param month Month value to be used in returned `DateTime` object.
   * @param year Year value to be used in returned `DateTime` object.
   * @returns The 24 hour `DateTime` object with supplied day, month, and year, defaulting to today's values if not provided.
   */
  public toDateTime({ day, month, year, zone }: DateArgs = {}): DateTime {
    let hour = this.hour;
    if (this.meridiem === 'PM' && hour !== 12) {
      hour += 12;
    } else if (this.meridiem === 'AM' && hour === 12) {
      hour = 0;
    }

    return DateTime.fromObject(
      {
        day,
        hour,
        minute: this.minute,
        month,
        year,
      },
      { zone },
    );
  }

  /**
   * Convert the `Time` object to a string representation of the time.
   *
   * @returns A string representation of the time in the format `HH:MM AM/PM`.
   */
  public toString(): string {
    return `${this.hour}:${
      this.minute < 10 ? `0${this.minute}` : this.minute
    } ${this.meridiem}`;
  }
}

interface DateArgs {
  day?: DateTime['day'];
  month?: DateTime['month'];
  year?: DateTime['year'];
  zone?: DateTime['zone'] | string;
}
