import { DiagnosisProblemApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { NameId } from 'src/app/models/core/name-id.model';

const api = apiDecorator<DiagnosisProblemApi>();

type DiagnosisProblemArgs = Omit<
  ClassProperties<DiagnosisProblem>,
  // Omit computed properties based on core model data.
  'displayName'
>;

export class DiagnosisProblem {
  public constructor(props: ClassProperties<DiagnosisProblemArgs>) {
    this.group = props.group;
    this.id = props.id;
    this.code = props.code;
    this.description = props.description;

    // Computed properties
    this.displayName = `${this.description} (${this.group.name})`;
  }

  /**
   * The io-ts codec for runtime type checking of the Diagnosis Problem API
   * model.
   */
  public static readonly Codec = io.type(
    {
      group: NameId.Codec,
      id: io.number,
      problemCode: io.number,
      problemDescription: io.union([io.string, io.null]),
    },
    'DiagnosisProblemApi',
  );

  @api({ key: 'problemCode' }) public readonly code: number;
  @api({ key: 'problemDescription' }) public readonly description:
    | string
    | null;
  @api({ key: 'group' }) public readonly group: NameId;
  @api({ key: 'id' }) public readonly id: number;

  // Computed properties
  public readonly displayName: string;

  /**
   * Deserializes a Diagnosis Problem object from an API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Diagnosis Problem object object.
   * @throws An error if the value is not a valid Diagnosis Problem object.
   */
  public static deserialize(
    value: NonNullable<DiagnosisProblemApi>,
  ): DiagnosisProblem {
    const decoded = decode(DiagnosisProblem.Codec, value);
    return new DiagnosisProblem({
      code: decoded.problemCode,
      description: decoded.problemDescription,
      group: NameId.deserialize(decoded.group),
      id: decoded.id,
    });
  }

  /**
   * Deserializes a list of Diagnosis Problem objects from an API
   * model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Diagnosis Problem objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Diagnosis Problem
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<DiagnosisProblemApi>>,
  ): readonly DiagnosisProblem[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Diagnosis Problem objects.');
    }
    return values.map(DiagnosisProblem.deserialize);
  }
}
