import { ReligionApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { Facility, Religion } from 'src/app/models';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ReligionService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetch the list of possible religions from the server.
   *
   * @param facilityId The facility ID to get the possible religions for.
   * @param isActive Whether to get only active religions.
   * @returns An observable of the possible religions for the given facility
   * ID, or undefined if there was an error.
   */
  public getList(
    facilityId: Facility['id'],
    isActive: boolean = true,
  ): Observable<readonly Religion[] | undefined> {
    return this.httpClient
      .get<readonly ReligionApi[] | undefined>(`${config.api.url}/religions`, {
        params: { active: isActive, facilityId },
      })
      .pipe(
        map((religions) =>
          religions ? Religion.deserializeList(religions) : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}
