import { LicensePagedListApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { License } from 'src/app/models';
import { PagedListCodec, decode, parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/** A service for fetching license data from the API. */
@Injectable()
export class LicenseService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetches a paged list of licenses from the API.
   *
   * @param requestParameters The list of query parameters to use when fetching
   * the list of licenses.
   * @returns An observable that emits the paged list of licenses, or undefined
   * if an error occurs.
   */
  public getPagedList(
    requestParameters: readonly LicenseRequestParam[],
  ): Observable<PagedList<License> | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<LicensePagedListApi | undefined>(`${config.api.url}/licenses`, {
        params,
      })
      .pipe(
        map((response) =>
          response
            ? {
                ...decode(LicensePagedListCodec, response),
                results: License.deserializeList(response.results),
              }
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

const LicensePagedListCodec = PagedListCodec(
  License.Codec,
  'LicensePagedListApi',
);

/** Request parameter interface for use with the Licenses API. */
export interface LicenseRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'active' | 'limit' | 'order' | 'pageNumber' | 'pageSize';
  /** The value to use for the query parameter. */
  value: boolean | number | keyof License;
}
