<mat-form-field [appearance]="'outline'">
  <mat-label>{{ label }}</mat-label>
  @if (!hidePrefixIcon) {
    <div class="prefix-icon-container">
      <alleva-icon
        [colorHex]="baseControl.disabled ? 'var(--gray-mid-dark)' : undefined"
        [color]="
          baseControl.touched && baseControl.invalid ? 'warn' : 'primary'
        "
        [scale]="1"
        class="prefix-icon"
        name="paid"
      ></alleva-icon>
    </div>
  }
  <span class="input-container">
    <input
      [formControl]="baseControl"
      [id]="id"
      [name]="name || id"
      [options]="options"
      [placeholder]="placeholder || ''"
      [required]="required"
      currencyMask
      matInput
    />
  </span>
  <mat-hint *ngIf="!hideHint && hint" [title]="hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="baseControl.invalid && baseControl.touched">
    <ng-container *ngIf="baseControl.hasError('required')">
      {{ label }} is required.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('min')">
      {{ label }} must be at least ${{ baseControl.errors?.['min'].min }}
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('max')">
      {{ label }} cannot exceed ${{ baseControl.errors?.['max'].max }}
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('email')">
      {{ label }} must be a valid email address.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('maxlength')">
      Must be at most
      {{ baseControl.errors?.['maxlength'].requiredLength }} characters.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('zeroNotAllowed')">
      {{ label }} must be greater than 0.
    </ng-container>
  </mat-error>
</mat-form-field>
