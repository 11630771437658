import { PAGE_NAMES_BY_ROUTE, PAGE_PERMISSIONS } from 'src/app/constants';
import { AuthenticationService } from 'src/app/services';
import { getFirstNonEmptyValueFrom, getPageRoute } from 'src/app/utilities';

import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

/** Whether to log debug messages. */
const isDebugEnabled = false;

@Injectable({ providedIn: 'root' })
export class UserPermissionGuard {
  private readonly auth = inject(AuthenticationService);
  private readonly router = inject(Router);

  public async canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    const pageRoute = getPageRoute(state.url);
    if (pageRoute === null) {
      if (isDebugEnabled) {
        // eslint-disable-next-line no-console
        console.error(`Could not determine the page route for "${state.url}"`);
      }
      return false;
    }

    await getFirstNonEmptyValueFrom(this.auth.onInitializeTrueChanges);
    const user = await getFirstNonEmptyValueFrom(this.auth.userChanges);

    const permissions = PAGE_PERMISSIONS[pageRoute];
    const hasPermission = user.hasPermission(permissions);

    if (isDebugEnabled) {
      const pageName = PAGE_NAMES_BY_ROUTE[pageRoute];
      const permissionsString =
        permissions.length > 0 ? ` "${permissions.toString()}" ` : ' ';
      if (hasPermission) {
        // eslint-disable-next-line no-console
        console.log(
          `User has the proper permission(s)${permissionsString}to view ` +
            `the "${pageName}" page on route "${pageRoute}".`,
        );
      } else {
        // eslint-disable-next-line no-console
        console.error(
          'User does NOT have the proper permission(s)' +
            `${permissionsString}to view the "${pageName}" page on route ` +
            `"${pageRoute}".`,
        );
      }
    }

    if (!hasPermission) {
      const forbiddenRoute: PageRoute = '/forbidden';
      return this.router.createUrlTree([forbiddenRoute]);
    }

    return true;
  }
}
