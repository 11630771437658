import { getPageRoute } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

/**
 * Lock out specific routes based on the current environments feature flags.
 *
 * @returns True if the user has access to the route and the environment flag
 * allows it if applicable, false otherwise.
 */
export function environmentFeaturesGuard(): CanActivateFn {
  return async (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> => {
    const router = inject(Router);

    const restrictedFallbackRoute: PageRoute = '/restricted-access';
    const routeToCheck: PageRoute | null = getPageRoute(state.url);

    if (!routeToCheck) {
      throw new Error(
        'Route is not a SPA route. Unable to check facility features with a route guard.',
      );
    }

    if (routeToCheck.startsWith('/sandbox/')) {
      const isSandboxEnabled = config.features.isSandboxEnabled;

      if (!isSandboxEnabled) {
        return router.createUrlTree([restrictedFallbackRoute]);
      }
    }

    return true;
  };
}
