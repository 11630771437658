import { FacilityApi, FacilityBaseApi } from 'api/models';
import * as io from 'io-ts';
import { DateTime } from 'luxon';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { FacilityFeatures } from 'src/app/models/facility/facility-features.model';
import { FacilitySettings } from 'src/app/models/facility/facility-settings.model';
import { Address } from 'src/app/models/user/address.model';
import { Phone } from 'src/app/models/user/phone.model';

const api = apiDecorator<FacilityApi>();
const apiBase = apiDecorator<FacilityBaseApi>();

/**
 * A base facility model. Although this is a base model, it's not an `abstract`
 * class like most other base models. This is because the facility model is
 * used in places where the full facility model is not needed and this can be
 * used and passed in its place.
 */
export class FacilityBase {
  public constructor(props: ClassProperties<FacilityBase>) {
    this.active = props.active;
    this.id = props.id;
    this.name = props.name;
  }

  /**
   * The io-ts codec for runtime type checking of the User model.
   */
  public static readonly BaseCodec = io.type(
    {
      active: io.boolean,
      id: io.number,
      name: io.string,
    },
    'FacilityBaseApi',
  );

  @apiBase({ key: 'active' }) public readonly active: boolean;
  @apiBase({ key: 'id' }) public readonly id: number;
  @apiBase({ key: 'name' }) public readonly name: string;

  /**
   * Deserializes a FacilityBase object.
   *
   * @param value The value to deserialize.
   * @returns A FacilityBase object.
   */
  public static deserialize(value: NonNullable<FacilityBaseApi>): FacilityBase {
    return new FacilityBase(decode(FacilityBase.BaseCodec, value));
  }

  /**
   * Deserializes an array of FacilityBase objects.
   *
   * @param values The values to deserialize.
   * @returns An array of FacilityBase objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<FacilityBaseApi>>,
  ): readonly FacilityBase[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Facility Base objects.');
    }
    return values.map(FacilityBase.deserialize);
  }
}

type FacilityArgs = Omit<
  ClassProperties<Facility>,
  // Omit computed properties based on core model data.
  'time'
>;

export class Facility extends FacilityBase {
  public constructor(props: ClassProperties<FacilityArgs>) {
    super(props);

    this.address = props.address;
    this.email = props.email;
    this.features = props.features;
    this.image = props.image;
    this.phone = props.phone;
    this.settings = props.settings;
    this.timeZone = props.timeZone;
  }

  public static readonly Codec = io.type(
    {
      active: io.boolean,
      address: io.union([Address.Codec, io.null]),
      email: io.union([io.string, io.null]),
      features: FacilityFeatures.Codec,
      id: io.number,
      image: io.union([io.string, io.null]),
      name: io.string,
      phone: io.union([Phone.Codec, io.null]),
      settings: FacilitySettings.Codec,
      timeZone: io.string,
    },
    'FacilityApi',
  );

  @api({ key: 'address' }) public readonly address: Address | null;
  @api({ key: 'email' }) public readonly email: string | null;
  @api({ key: 'features' }) public readonly features: FacilityFeatures;
  @api({ key: 'image' }) public readonly image: string | null;
  @api({ key: 'phone' }) public readonly phone: Phone | null;
  @api({ key: 'settings' }) public readonly settings: FacilitySettings;
  @api({ key: 'timeZone' }) public readonly timeZone: string;

  // Computed properties

  /** The current time in the facility's time zone. */
  public get time(): DateTime {
    return DateTime.local({ zone: this.timeZone });
  }

  public static override deserialize(
    value: NonNullable<FacilityApi>,
  ): Facility {
    const decoded = decode(Facility.Codec, value);
    return new Facility({
      active: decoded.active,
      address: decoded.address ? Address.deserialize(decoded.address) : null,
      email: decoded.email,
      features: FacilityFeatures.deserialize(decoded.features),
      id: decoded.id,
      image: decoded.image,
      name: decoded.name,
      phone: decoded.phone ? Phone.deserialize(decoded.phone) : null,
      settings: FacilitySettings.deserialize(decoded.settings),
      timeZone: decoded.timeZone,
    });
  }

  public static override deserializeList(
    values: ReadonlyArray<NonNullable<FacilityApi>>,
  ): readonly Facility[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Facility objects.');
    }
    return values.map(Facility.deserialize);
  }
}
