import { ReplaySubject, firstValueFrom } from 'rxjs';
import { AuthenticatedUser, Client, Facility } from 'src/app/models';
import {
  getFirstNonEmptyValueFrom,
  isCategoryMenuItem,
  shareSingleReplay,
} from 'src/app/utilities';
import { config } from 'src/configs/config';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector:
    'alleva-menu-item[currentFacility][currentUser][isCollapsed][menuItem][selectedClient]',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  /** The currently selected facility. Required. */
  @Input() public set currentFacility(value: Facility | null) {
    this.currentFacilitySubject.next(value);
  }
  private readonly currentFacilitySubject = new ReplaySubject<Facility | null>(
    1,
  );
  protected readonly currentFacilityChanges = this.currentFacilitySubject
    .asObservable()
    .pipe(shareSingleReplay());

  /** The current logged in user. Required. */
  @Input() public set currentUser(value: AuthenticatedUser | null) {
    this.currentUserSubject.next(value);
  }
  private readonly currentUserSubject =
    new ReplaySubject<AuthenticatedUser | null>(1);
  protected readonly currentUserChanges = this.currentUserSubject
    .asObservable()
    .pipe(shareSingleReplay());

  /** Whether the menu item should use a collapsed (mobile) view. */
  @Input() public set isCollapsed(value: BooleanInput) {
    this.isCollapsedSubject.next(coerceBooleanProperty(value));
  }
  private readonly isCollapsedSubject = new ReplaySubject<boolean>(1);
  protected readonly isCollapsedChanges = this.isCollapsedSubject
    .asObservable()
    .pipe(shareSingleReplay());

  /** The menu item to display. Required. */
  @Input() public menuItem!: MenuItem;

  /** The currently selected client, if any. */
  @Input() public set selectedClient(value: Client | null) {
    this.selectedClientSubject.next(value);
  }
  private readonly selectedClientSubject = new ReplaySubject<Client | null>(1);
  protected readonly selectedClientChanges = this.selectedClientSubject
    .asObservable()
    .pipe(shareSingleReplay());

  protected isDisplayed = false;
  protected isSubMenuOpen = false;

  public get categoryMenuItem(): CategoryMenuItem | null {
    return isCategoryMenuItem(this.menuItem) ? this.menuItem : null;
  }

  public get topLevelMenuItem(): TopLevelMenuItem | null {
    return isCategoryMenuItem(this.menuItem) ? null : this.menuItem;
  }

  public async ngOnInit(): Promise<void> {
    await this.setIsDisplayed();
  }

  private async setIsDisplayed(): Promise<void> {
    const currentUser = await firstValueFrom(this.currentUserChanges);
    if (!currentUser) {
      throw new Error(
        'The current user is required to determine if the menu item should be displayed.',
      );
    }

    // Handle scenario for `CategoryMenuItem` objects.
    if (isCategoryMenuItem(this.menuItem)) {
      const currentFacility = await getFirstNonEmptyValueFrom(
        this.currentFacilityChanges,
      );
      const isBillingEnabled = currentFacility.features.isBillingEnabled;
      const isInsightsEnabled = currentUser.settings.isInsightsEnabled;
      const isSandboxEnabled = config.features.isSandboxEnabled;

      const hasDisplayedMenuItems = this.menuItem.topLevelMenuItems.some(
        (topLevelMenuItem) => {
          const isTopLevelSandboxMenuItem =
            topLevelMenuItem.subLevelMenuItems?.some(
              (subLevelMenuItem) =>
                subLevelMenuItem.path === '/sandbox/buttons' ||
                subLevelMenuItem.path === '/sandbox/editor' ||
                subLevelMenuItem.path === '/sandbox/forms' ||
                subLevelMenuItem.path === '/sandbox/icons' ||
                subLevelMenuItem.path === '/sandbox/loading-indicators' ||
                subLevelMenuItem.path === '/sandbox/messages' ||
                subLevelMenuItem.path === '/sandbox/print' ||
                subLevelMenuItem.path === '/sandbox/signatures' ||
                subLevelMenuItem.path === '/sandbox/tables',
            );

          // Handle sandbox menu items.
          // These are locked out by an environment feature flag.
          if (isTopLevelSandboxMenuItem) {
            return isSandboxEnabled;
          }

          if (currentUser.role.isAdmin) {
            return true;
          }

          const hasPermission = currentUser.hasPermission(
            topLevelMenuItem.permissions,
          );

          const isTopLevelInsightsMenuItem =
            topLevelMenuItem.subLevelMenuItems?.some(
              (subLevelMenuItem) =>
                subLevelMenuItem.path === '/reports/insights',
            ) ?? false;

          // Handle sub menu item permission checks for insights specifically.
          // There are no permissions for this page, so we just check if it's
          // enabled in the user settings.
          if (isTopLevelInsightsMenuItem && isInsightsEnabled) {
            return true;
          }

          // Handle sub menu item permission checks for billing specifically.
          // The permission and facility feature flag must be enabled for the
          // billing menu item to be displayed.
          const isTopLevelBillingMenuItem =
            topLevelMenuItem.subLevelMenuItems?.some(
              (subLevelMenuItem) =>
                subLevelMenuItem.path === '/billing-offsite',
            );

          if (isTopLevelBillingMenuItem && isBillingEnabled) {
            return true;
          }

          if (!hasPermission) {
            return false;
          }

          // Handle sub menu item permission checks.
          const hasSubMenuItemPermission =
            topLevelMenuItem.subLevelMenuItems?.some((subLevelMenuItem) =>
              currentUser.hasPermission(subLevelMenuItem.permissions ?? []),
            );

          return hasPermission || hasSubMenuItemPermission;
        },
      );
      this.isDisplayed = hasDisplayedMenuItems;
      return;
    }

    // Return true for all, and early, if the user is an admin.
    if (currentUser.role.isAdmin) {
      this.isDisplayed = true;
      return;
    }

    // At this point we are now working with a `TopLevelMenuItem` as either a
    // link or container for a `SubLevelMenuItem` list.
    const topLevelMenuItem = this.menuItem;

    const hasTopLevelMenuItemPermission = currentUser.hasPermission(
      topLevelMenuItem.permissions,
    );
    if (!hasTopLevelMenuItemPermission) {
      this.isDisplayed = false;
      return;
    }

    const hasDisplayedSubLevelMenuItems =
      topLevelMenuItem.subLevelMenuItems?.some((menuItem) =>
        currentUser.hasPermission(menuItem.permissions ?? []),
      ) ?? false;
    const hasPath = !!topLevelMenuItem.path;

    if (hasDisplayedSubLevelMenuItems) {
      if (hasPath) {
        throw new Error(
          'A top level menu item cannot have both sub level menu items and ' +
            'a link.',
        );
      }
      this.isDisplayed = true;
      return;
    }

    // With the `SubLevelMenuItem` list scenario handled, we can now check to
    // make sure the `TopLevelMenuItem` has a link (path) to link to, since this
    // is the last possible scenario for a `TopLevelMenuItem` to be displayed.
    if (!hasPath) {
      throw new Error(
        `If a top level menu item doesn't have sub level menu items, then ` +
          `it must be a link, but the 'path' wasn't provided.`,
      );
    } else {
      this.isDisplayed = true;
      return;
    }
  }
}
