<mat-form-field [appearance]="'outline'">
  <!-- INPUT LABEL -->
  <mat-label *ngIf="label as inputLabel">{{ inputLabel }}</mat-label>
  <!-- INPUT (OPTIONS MENU OPEN) -->
  <ng-container
    *ngIf="displayNestedSearchInput"
    [ngTemplateOutlet]="inputTemplate"
  ></ng-container>
  <!-- ANGULAR MATERIAL SELECT -->
  <mat-select
    #matSelect
    (focus)="onFocus('dropdown', $event)"
    (focusout)="onFocusOut('dropdown', $event)"
    (openedChange)="onPanelToggle()"
    [ariaLabel]="label"
    [compareWith]="compareWith"
    [formControl]="baseControl"
    [hidden]="displayNestedSearchInput"
    [multiple]="multiple"
  >
    <!-- DISPLAY VALUES -->
    <mat-select-trigger>
      @if (!matSelect.panelOpen) {
        <!-- Show the selected values or number of selected items when not focused -->
        @if (!multiple && matSelect.value) {
          {{ getDisplayValue(matSelect.value) || '—' }}
        } @else if (matSelect.value?.length === 1) {
          {{ getDisplayValue(matSelect.value[0]) }}
        } @else if (matSelect.value?.length > 1) {
          {{ matSelect.value.length }} selected
        } @else {
          No values selected
        }
      } @else {
        <ng-container [ngTemplateOutlet]="inputTemplate"></ng-container>
      }
      <!-- INPUT (OPTIONS MENU CLOSED) -->
    </mat-select-trigger>
    <!-- INPUT TEMPLATE -->
    <ng-template #inputTemplate>
      <div class="input-container">
        <input
          #searchInput
          (focus)="onFocus('input', $event)"
          (focusout)="onFocusOut('input', $event)"
          (input)="filter(searchInput.value)"
          (click)="$event.stopPropagation()"
          (keydown)="onInputKeyDown($event)"
          [(ngModel)]="searchValue"
          [placeholder]="placeholder || 'Search...'"
          type="text"
        />
        <alleva-icon
          (click)="clearSearch($event)"
          *ngIf="searchValue !== ''"
          colorHex="var(--gray-mid-dark)"
          matSuffix
          name="clear"
          scale="0.85"
        ></alleva-icon>
      </div>
    </ng-template>
    <div class="clear-select-all-container">
      <!-- SELECT ALL OPTION -->
      <ng-container *ngIf="listChanges | async as options">
        <button
          (click)="toggleAll()"
          *ngIf="selectAllEnabled && multiple"
          [disabled]="!options.length || baseControl.disabled"
          class="select-all-dropdown-option"
          label="Select All"
          mat-button
        >
          <alleva-icon
            [name]="
              isArray(baseControl.value) &&
              baseControl.value.length === options.length
                ? 'check_box'
                : 'check_box_outline_blank'
            "
          ></alleva-icon>
          Select All
        </button>
      </ng-container>
      <!-- CLEAR ALL OPTION -->
      <button
        (click)="clear()"
        *ngIf="isClearable"
        [disabled]="!baseControl.value || baseControl.disabled"
        class="clear-dropdown-option"
        label="Clear Dropdown"
        mat-button
      >
        <alleva-icon color="warn" name="close"></alleva-icon>
        {{ clearLabel || 'Clear' }}
      </button>
    </div>
    <!-- OPTIONS -->
    @for (option of listChanges | async; track option) {
      <mat-option
        (click)="$event.stopPropagation()"
        [hidden]="!(filteredListChanges | async)?.includes(option)"
        [value]="option"
      >
        <span class="label">{{ getDisplayValue(option) }}</span>
      </mat-option>
    }
    <!-- NO OPTIONS FOUND -->
    <mat-option
      *ngIf="
        searchValue.length > 0 && (filteredListChanges | async)?.length === 0
      "
      (click)="$event.stopPropagation()"
      [value]="null"
      disabled
    >
      No results found.
    </mat-option>
  </mat-select>
  <!-- HINT -->
  <mat-hint *ngIf="!hideHint && hint" [title]="hint">{{ hint }}</mat-hint>
  <!-- ERRORS -->
  <mat-error *ngIf="baseControl.invalid && baseControl.touched">
    <ng-container *ngIf="baseControl.hasError('required')">
      {{ label }} is required.
    </ng-container>
    <ng-container *ngIf="baseControl.hasError('maxSelection')">
      You can only select up to {{ max }} items.
    </ng-container>
  </mat-error>
</mat-form-field>

<ng-template #loadingTemplate>
  <alleva-loading-text [label]="label" type="input"></alleva-loading-text>
</ng-template>
