<form [formGroup]="formGroup">
  <div class="list-query">
    <div class="search">
      <!-- SEARCH INPUT -->
      <alleva-input
        (keyup.enter)="search()"
        [formControl]="formGroup.controls['search']"
        [isClearable]="true"
        class="search-input"
        hideHint
        label="Search"
        placeholder="Search"
      ></alleva-input>
      <!-- SEARCH BUTTON -->
      <alleva-button
        (click)="search()"
        [disabled]="
          formGroup.controls['search'].value === '' ||
          formGroup.controls['search'].value === null ||
          isLoading
        "
        class="search-button"
        color="accent"
        label="Submit Search"
        styling="flat"
        >Submit</alleva-button
      >
    </div>
    <div class="search-in">
      <!-- FACILITY SELECT RADIO -->
      <alleva-input-radio
        [formControl]="formGroup.controls['searchIn']"
        boldLabel
        color="accent"
        label="Search in:"
      >
        <alleva-input-radio-option [value]="SearchInEnum.CURRENT_FACILITY">
          {{ SearchInEnum.CURRENT_FACILITY }}
        </alleva-input-radio-option>
        <alleva-input-radio-option [value]="SearchInEnum.ALL_FACILITIES">
          {{ SearchInEnum.ALL_FACILITIES }}
        </alleva-input-radio-option>
      </alleva-input-radio>
    </div>
  </div>
  <div class="client-status-keys">
    <alleva-client-status-badge-key-display></alleva-client-status-badge-key-display>
  </div>
  <div class="list-view-actions">
    <!-- VIEW STYLE BUTTON - LIST VIEW (DEFAULT) -->
    <alleva-button
      (click)="viewStyle = ViewStyleEnum.TABLE"
      [icon]="{
        name: 'view_list',
        color: viewStyle === ViewStyleEnum.TABLE ? 'accent' : 'primary',
      }"
      label="List View"
      matTooltip="List View"
      styling="icon"
    ></alleva-button>
    <!-- VIEW STYLE BUTTON - GRID VIEW -->
    <alleva-button
      (click)="viewStyle = ViewStyleEnum.GRID"
      [icon]="{
        name: 'grid_view',
        color: viewStyle === ViewStyleEnum.GRID ? 'accent' : 'primary',
      }"
      label="Grid View"
      matTooltip="Grid View"
      styling="icon"
    ></alleva-button>
  </div>
</form>

<!-- SEARCH RESULTS TAB HEADERS -->
<alleva-tabs [isStretched]="true" [isVertical]="false">
  <alleva-tab
    #clientsTab
    [titleBadgeCount]="(clientsChanges | async)?.length || 0"
    title="Clients"
    titleBadgeColor="accent"
    ><ng-template allevaTabTitleTemplate></ng-template
  ></alleva-tab>
  <alleva-tab
    #prospectiveClientsTab
    [titleBadgeCount]="(prospectiveClientsChanges | async)?.length || 0"
    title="Prospects"
    titleBadgeColor="accent"
    ><ng-template allevaTabTitleTemplate></ng-template
  ></alleva-tab>
  <alleva-tab
    #referralsTab
    [titleBadgeCount]="(referralUsersChanges | async)?.length || 0"
    title="Referrals"
    titleBadgeColor="accent"
    ><ng-template allevaTabTitleTemplate></ng-template
  ></alleva-tab>
  <alleva-tab
    #usersTab
    [titleBadgeCount]="(usersChanges | async)?.length || 0"
    title="Users"
    titleBadgeColor="accent"
    ><ng-template allevaTabTitleTemplate></ng-template
  ></alleva-tab>
</alleva-tabs>

<!-- TAB: CLIENTS -->
<ng-container *ngIf="clientsTab.isActive">
  <ng-container
    *ngIf="clientsChanges | async as clients; else noResultsTemplate"
    [ngSwitch]="viewStyle"
  >
    <ng-container *ngSwitchCase="ViewStyleEnum.GRID">
      <alleva-cards-grid
        (itemClicked)="onClientClick($event)"
        *ngSwitchCase="ViewStyleEnum.GRID"
        [items]="clients"
      ></alleva-cards-grid>
      <ng-container
        *ngIf="hasSearched && clients.length === 0"
        [ngTemplateOutlet]="noResultsTemplate"
        [ngTemplateOutletContext]="{ type: 'Clients' }"
      ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ViewStyleEnum.TABLE">
      <alleva-table
        (rowClick)="onClientClick($event)"
        [columns]="clientTableColumns"
        [data]="clients"
        [hasStickyHeader]="true"
        [isNthRowHighlighted]="true"
        [isRowClickEnabled]="true"
        [isSlim]="true"
        color="primary"
        emptyCellValuePlaceholder="—"
      ></alleva-table>
    </ng-container>
  </ng-container>
</ng-container>

<!-- TAB: PROSPECTIVE CLIENTS -->
<ng-container *ngIf="prospectiveClientsTab.isActive">
  <ng-container
    *ngIf="
      prospectiveClientsChanges | async as prospectiveClients;
      else noResultsTemplate
    "
    [ngSwitch]="viewStyle"
  >
    <ng-container *ngSwitchCase="ViewStyleEnum.GRID">
      <alleva-cards-grid
        *ngSwitchCase="ViewStyleEnum.GRID"
        [isClickable]="false"
        [items]="prospectiveClients"
      ></alleva-cards-grid>
      <ng-container
        *ngIf="hasSearched && prospectiveClients.length === 0"
        [ngTemplateOutlet]="noResultsTemplate"
        [ngTemplateOutletContext]="{ type: 'Prospective Clients' }"
      ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ViewStyleEnum.TABLE">
      <alleva-table
        [columns]="prospectiveClientTableColumns"
        [data]="prospectiveClients"
        [hasStickyHeader]="true"
        [isNthRowHighlighted]="true"
        [isSlim]="true"
        color="primary"
        emptyCellValuePlaceholder="—"
      ></alleva-table>
    </ng-container>
  </ng-container>
</ng-container>

<!-- TAB: REFERRALS -->
<ng-container *ngIf="referralsTab.isActive">
  <ng-container
    *ngIf="
      referralUsersChanges | async as referralUsers;
      else noResultsTemplate
    "
    [ngSwitch]="viewStyle"
  >
    <ng-container *ngSwitchCase="ViewStyleEnum.GRID">
      <alleva-cards-grid
        *ngSwitchCase="ViewStyleEnum.GRID"
        [isClickable]="false"
        [items]="referralUsers"
      ></alleva-cards-grid>
      <ng-container
        *ngIf="hasSearched && referralUsers.length === 0"
        [ngTemplateOutlet]="noResultsTemplate"
        [ngTemplateOutletContext]="{ type: 'Referrals' }"
      ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ViewStyleEnum.TABLE">
      <alleva-table
        [columns]="referralsTableColumns"
        [data]="referralUsers"
        [hasStickyHeader]="true"
        [isNthRowHighlighted]="true"
        [isRowClickEnabled]="false"
        [isSlim]="true"
        color="primary"
        emptyCellValuePlaceholder="—"
      ></alleva-table>
    </ng-container>
  </ng-container>
</ng-container>

<!-- TAB: USERS -->
<ng-container *ngIf="usersTab.isActive">
  <ng-container
    *ngIf="usersChanges | async as users; else noResultsTemplate"
    [ngSwitch]="viewStyle"
  >
    <ng-container *ngSwitchCase="ViewStyleEnum.GRID">
      <alleva-cards-grid
        (itemClicked)="onUserClick($event)"
        *ngSwitchCase="ViewStyleEnum.GRID"
        [items]="users"
      ></alleva-cards-grid>
      <ng-container
        *ngIf="hasSearched && users.length === 0"
        [ngTemplateOutlet]="noResultsTemplate"
        [ngTemplateOutletContext]="{ type: 'Users' }"
      ></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ViewStyleEnum.TABLE">
      <alleva-table
        [columns]="usersTableColumns"
        [data]="users"
        [hasStickyHeader]="true"
        [isNthRowHighlighted]="true"
        [isRowClickEnabled]="false"
        [isSlim]="true"
        color="primary"
        emptyCellValuePlaceholder="—"
      ></alleva-table>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #noResultsTemplate let-type="type">
  <div *ngIf="isLoading" class="is-loading">
    <alleva-loading-indicator [isCentered]="true"></alleva-loading-indicator>
  </div>
  <div *ngIf="!isLoading" [class.has-searched]="hasSearched" class="no-results">
    {{
      hasSearched
        ? 'No results found for "' + previouslySearched + '" in ' + type + '.'
        : 'Enter a value above to search for.'
    }}
  </div>
</ng-template>

<ng-template #displayImageTemplateRef let-profileImage="profileImage">
  <img
    [src]="profileImage || 'assets/images/clients/temp.png' | authBlob | async"
  />
</ng-template>

<ng-template #emailTemplateRef let-email="email">
  <alleva-icon name="mail" [inline]="true"></alleva-icon>
  {{ email || '—' }}
</ng-template>

<ng-template #isActiveTemplateRef let-isActive="isActive">
  {{ isActive | yesNo }}
</ng-template>

<ng-template #phoneTemplateRef let-phoneDisplay="phoneDisplay">
  <alleva-icon name="phone" [inline]="true"></alleva-icon>
  {{ phoneDisplay }}
</ng-template>

<ng-template #statusTemplate let-client="client">
  <alleva-client-status-badge [client]="client"></alleva-client-status-badge>
</ng-template>
