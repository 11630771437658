import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  OidcSecurityService,
  OpenIdConfiguration,
} from 'angular-auth-oidc-client';
import { AuthResponseApi } from 'api/models';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  firstValueFrom,
  of,
  take,
  takeWhile,
  withLatestFrom,
} from 'rxjs';
import { AuthenticatedUser, authResponseCodec } from 'src/app/models';
import {
  decode,
  isNumber,
  navigateOffsiteTo,
  shareSingleReplay,
} from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService, StorageTypeEnum } from './storage.service';
import { UserService } from './user.service';

/**
 * A class for handling the authentication of the user.
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public constructor(
    private readonly http: HttpClient,
    private readonly oidc: OidcSecurityService,
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly userService: UserService,
  ) {}

  private readonly isInitializedSubject = new BehaviorSubject<boolean>(false);
  public readonly onInitializeTrueChanges = this.isInitializedSubject.pipe(
    // Only emit when initialized.
    filter((initialized) => initialized === true),
    // Stop emitting after the first `true` value.
    take(1),
    // Cache the last emitted value for all new subscribers.
    shareSingleReplay(),
  );

  private readonly isLoggingOutSubject = new BehaviorSubject<boolean>(false);
  public readonly isLoggingOutChanges = this.isLoggingOutSubject
    .asObservable()
    .pipe(shareSingleReplay());

  private readonly returnUrlSubject = new BehaviorSubject<string | null>(null);
  public readonly returnUrlChanges = this.returnUrlSubject.asObservable();

  private readonly userChangesSubject =
    new BehaviorSubject<AuthenticatedUser | null>(null);
  public readonly userChanges = this.userChangesSubject
    .asObservable()
    .pipe(shareSingleReplay());

  /**
   * Initialize the authentication service, load the user data into memory
   * for the current session.
   */
  public async initialize(): Promise<void> {
    const isInitialized = await firstValueFrom(this.isInitializedSubject);
    if (isInitialized) {
      throw new Error('The authentication service is already initialized!');
    }

    // Continue with the oidc authentication.
    this.oidc
      .checkAuth()
      .pipe(
        withLatestFrom(this.userChanges),
        takeWhile(([_authResponse, user]) => user === null),
        take(1),
        untilDestroyed(this),
      )
      .subscribe(async ([authResponse, _user]) => {
        // Note: Upon first run or initialization, this token is newly retrieved from the server.
        // Subsequent calls only check the local/browser token, thus we cannot determine if the server has revoked the tokens.
        if (authResponse.isAuthenticated) {
          const authResponseDecoded = decode(authResponseCodec, authResponse);
          const user =
            await this.getUserProfileInformation(authResponseDecoded);

          // If the user is not found or this token has been revoked from the server, we need to re-authenticate.
          if (!user) {
            console.warn('Failed to fetch user profile information.');
            this.oidc.authorize();
            return;
          }

          const subdomain = extractSubdomain();
          if (subdomain !== user.clientTenantId) {
            console.warn(
              'User is attempting to access the wrong tenant. ' +
                `Expected: "${subdomain}", Actual: "${user.clientTenantId}".`,
            );
            this.oidc.authorize();
            return;
          }

          this.setAuthenticatedUser(user);
          this.isInitializedSubject.next(true);

          const homePage: PageRoute = '/dashboard';
          const returnUrl =
            this.returnUrlSubject.getValue() ||
            window.location.pathname ||
            homePage;

          // Redirect the user to URL they were trying to access.
          this.router.navigateByUrl(returnUrl);
        } else {
          // Keep track of the URL the user was trying to access.
          this.returnUrlSubject.next(window.location.pathname);
          // eslint-disable-next-line no-console
          console.info('User authorization initialized.');
          this.oidc.authorize();
        }
      });
  }

  /**
   * Logout the user. This will remove the user from session storage and
   * redirect the user to the login page.
   *
   * @param idToken The authenticated user idToken to use for logout, since
   * sometimes our local authenticated user object is not available.
   * @param shouldLogoutRemotely A boolean indicating whether the logout action
   * should also be performed remotely. Default is true.
   */
  public async logout(
    idToken?: AuthenticatedUser['idToken'],
    shouldLogoutRemotely = true,
  ): Promise<void> {
    this.isLoggingOutSubject.next(true);

    const user = await firstValueFrom(this.userChanges);

    // Clear session storage and local storage.
    this.clearAllStorage();

    // Log out on the server
    if (shouldLogoutRemotely) {
      const id_token_hint = user?.idToken ?? idToken;
      await this.logoutOnServer(id_token_hint);
    } else {
      this.oidc.authorize();
    }
  }

  private async logoutOnServer(
    id_token_hint: string | undefined,
  ): Promise<void> {
    const logoutUrl = await firstValueFrom(this.oidc.getEndSessionUrl());
    if (logoutUrl) {
      await navigateOffsiteTo(logoutUrl);
    } else {
      try {
        if (id_token_hint) {
          const subdomain = extractSubdomain();
          const isLocalHost = window.location.port === '4200';
          const logoutRedirectUri = isLocalHost
            ? `http://${subdomain}:4200`
            : `https://${subdomain}.allevasoft.com/#/logout`;

          // Logout, then redirect to the login page.
          await navigateOffsiteTo(
            `${config.oidc.authAuthority}/connect/endsession`,
            {
              queryParams: {
                post_logout_redirect_uri: logoutRedirectUri,
                id_token_hint,
              },
            },
          );
        } else {
          throw new Error('No idToken provided for logout.');
        }
      } catch (error) {
        console.error(error);
        this.isLoggingOutSubject.next(false);
      }
    }
  }

  private async clearAllStorage(): Promise<void> {
    this.storageService.clearAll(StorageTypeEnum.LOCAL_STORAGE);
    this.storageService.clearAll(StorageTypeEnum.SESSION_STORAGE);
    this.storageService.clearAll(StorageTypeEnum.LOCAL_FORAGE);

    // Clear the user from memory
    this.userChangesSubject.next(null);
  }

  public async redirectUserToBilling(): Promise<void> {
    await navigateOffsiteTo(config.billingAppUrl, { openInNewTab: true });
  }

  /**
   * Verify the password for the given user id.
   *
   * @param user The user to verify the password for.
   * @param password The password to verify.
   * @returns True if the password is valid, false otherwise.
   */
  public verifyUserPassword(
    user: AuthenticatedUser,
    password: string,
  ): Observable<boolean> {
    return this.http.post<boolean>(config.verifyPasswordUrl, { password }).pipe(
      catchError((error: unknown) => {
        console.error(error);
        return of(false);
      }),
    );
  }

  /**
   * Get the user profile information from the Alleva EMR API.
   *
   * @param authResponseApi The auth response from the auth server.
   * @returns The authenticated user or null if there's an issue.
   */
  private async getUserProfileInformation(
    authResponseApi: AuthResponseApi,
  ): Promise<AuthenticatedUser | null> {
    const userId: number | undefined = isNumber(
      authResponseApi.userData.RehabUserId,
    )
      ? Number(authResponseApi.userData.RehabUserId)
      : undefined;

    if (!userId) {
      throw new Error('Invalid user id provided by the auth server.');
    }

    // Fetch additional user information from the Alleva API.
    const { result: emrUser, httpErrorResponse } = await firstValueFrom(
      this.userService.getEmrUser({
        accessToken: authResponseApi.accessToken,
        id: userId,
      }),
    );

    if (!emrUser) {
      if (httpErrorResponse) {
        // Catastrophic error, proceed to logout.
        this.logout(authResponseApi.idToken);
        return null;
      } else {
        // Failed to fetch user information or user doesnt exist.
        return null;
      }
    }

    // Combine the auth response with the user information into one model.
    return AuthenticatedUser.deserialize(authResponseApi, emrUser);
  }

  /**
   * Set the authenticated user state.
   *
   * @param authenticatedUser The authenticated user state to store and track.
   */
  private setAuthenticatedUser(authenticatedUser: AuthenticatedUser): void {
    this.userChangesSubject.next(authenticatedUser);
  }
}

type OpenIdConfig = OpenIdConfiguration & {
  clientId: string | undefined;
  responseType: string;
  scope: string;
};

const protocol = window.location.protocol; // "https:"
const host = window.location.host; // "example.com"
const pathname = window.location.pathname; // "/clients/list"

export const openIdConfiguration: OpenIdConfig = {
  authority: config.oidc.authAuthority,
  autoCleanStateAfterAuthentication: true,
  clientId: config.oidc.authClientId,
  customParamsAuthRequest: {
    // Params required by the auth server request.
    // client_id                  // Passed automatically.
    // code_challenge             // Passed automatically.
    // code_challenge_method      // Passed automatically.
    // response_type              // Passed automatically.
    // scope                      // Passed automatically.
    // state                      // Passed automatically.
    acr_values: `tenant:${extractSubdomain()}`,
  },
  ignoreNonceAfterRefresh: true,
  logLevel: config.oidc.logLevel,
  // postLoginRoute: '/clients/list' as PageRoute,
  ngswBypass: true,
  postLogoutRedirectUri: window.location.origin,
  redirectUrl: `${protocol}//${host}${pathname}`,
  responseType: 'code',
  triggerRefreshWhenIdTokenExpired: false,
  scope: [
    'openid',
    'profile',
    'offline_access',
    'https://authorization.allevasoft.com/api:read',
    'https://authorization.allevasoft.com/api:write',
    'https://authorization.allevasoft.com/api:delete',
  ].join(' '),
  silentRenew: true,
  useRefreshToken: true,
};

/**
 * Get the current subdomain of the hosted application.
 *
 * @returns The subdomain of the current URL, or 'localhost' for local dev.
 */
function extractSubdomain(): string {
  const hostname = window.location.hostname;
  const domainParts = hostname.split('.');

  if (domainParts.length <= 2) {
    return 'localhost';
  }

  return domainParts[0];
}
