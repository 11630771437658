import { ClientLevelOfCareApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import { ClientLevelOfCare, Facility } from 'src/app/models';
import { parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for getting levels of care (treatments).
 */
@Injectable()
export class LevelOfCareService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Gets all of the levels of care for the given facility ID.
   *
   * @param facilityId The facility ID to get the levels of care for.
   * @returns An observable of the levels of care for the given facility ID,
   * or undefined if there was an error.
   */
  public getAllByFacilityId(
    facilityId: Facility['id'],
    requestParameters?: readonly LevelOfCareRequestParam[],
  ): Observable<readonly ClientLevelOfCare[] | undefined> {
    // Construct the HTTP parameters from the request parameters.
    const params = parseHttpParams(requestParameters)?.append(
      'facilityId',
      facilityId.toString(),
    );
    return this.httpClient
      .get<
        readonly ClientLevelOfCareApi[] | undefined
      >(`${config.api.url}/levels-of-care`, { params })
      .pipe(
        map((response) =>
          response ? ClientLevelOfCare.deserializeList(response) : [],
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

/**
 * Request parameter interface for use with the Client API.
 */
export interface LevelOfCareRequestParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'active' | 'name' | 'order';
  /** The value to use for the query parameter. */
  value: boolean | ClientLevelOfCare['name'] | keyof ClientLevelOfCare;
}
