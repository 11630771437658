import { firstValueFrom } from 'rxjs';
import { AuthenticationService } from 'src/app/services';
import { getFirstNonEmptyValueFrom } from 'src/app/utilities';

import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';

/**
 * A guard that checks if the user is authenticated.
 *
 * @returns True if the user is authenticated, false otherwise.
 */
export function authenticationGuard(): CanActivateFn {
  return async (
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Promise<boolean> => {
    const authenticationService = inject(AuthenticationService);

    // Wait for the auth service to be initialized.
    await getFirstNonEmptyValueFrom(
      authenticationService.onInitializeTrueChanges,
    );

    const user = await firstValueFrom(authenticationService.userChanges);

    return user !== null;
  };
}
