import {
  AllergenApi,
  AllergenPagedListApi,
  AllergyReactionApi,
  AllergyTypeApi,
  ClientAllergyApi,
} from 'api/models';
import * as io from 'io-ts';
import { Observable, catchError, map, of } from 'rxjs';
import { SortOrderEnum } from 'src/app/enumerators';
import {
  Allergen,
  AllergyType,
  Client,
  ClientAllergy,
  ClientAllergyDeserializationArgs,
  ClientAllergyUpdate,
  NameId,
} from 'src/app/models';
import { decode, parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the allergies API.
 */
@Injectable()
export class AllergiesService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Delete the allergy for the given client ID and allergy ID.
   *
   * @param clientId The client ID to delete the allergy for.
   * @param clientAllergyId The ID of the allergy to delete.
   * @returns True on success, false on error.
   */
  public deleteClientAllergy(
    clientId: Client['id'],
    clientAllergyId: ClientAllergy['id'],
  ): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(
        `${config.api.url}/clients/${clientId}/allergies/${clientAllergyId}`,
      )
      .pipe(
        catchError((error: unknown) => {
          console.error(error);
          return of(false);
        }),
      );
  }

  /**
   * Fetch and return the paged data for all allergens.
   *
   * @param requestParameters The request parameters to use for the API call.
   * @returns All paged allergens model data on success, undefined on error.
   */
  public getPagedList(
    requestParameters?: readonly AllergenRequestParam[],
  ): Observable<AllergenPagedList | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<AllergenPagedListApi | undefined>(`${config.api.url}/allergens`, {
        params,
      })
      .pipe(
        map((response) =>
          response
            ? {
                ...decode(AllergenPagedListCodec, response),
                results: Allergen.deserializeList(response.results),
              }
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch the allergy reactions list from the API.
   *
   * @param requestParameters The request parameters to use for the API call.
   * @returns The list of allergy reactions on success, undefined on error.
   */
  public getAllergyReactionsList(
    requestParameters?: readonly AllergenRequestParam[],
  ): Observable<readonly NameId[] | undefined> {
    const params = parseHttpParams(requestParameters);
    return this.httpClient
      .get<readonly AllergyReactionApi[]>(
        `${config.api.url}/allergens/reactions`,
        {
          params,
        },
      )
      .pipe(
        map((response) => NameId.deserializeList(response)),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch the allergy type list from the API.
   *
   * @returns The list of allergy type on success, undefined on error.
   */
  public getAllergyTypeList(): Observable<readonly AllergyType[] | undefined> {
    return this.httpClient
      .get<
        readonly AllergyTypeApi[] | undefined
      >(`${config.api.url}/allergens/type`)
      .pipe(
        map((response) => {
          if (!response) {
            throw new Error('Response for "Allergy Types" GET is undefined.');
          }
          return AllergyType.deserializeList(response);
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Fetch and return data for all Client Allergy resources by client id.
   *
   * @param clientId The client ID to fetch the allergy list for.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns All Client Allergy resource data on success, undefined on error.
   */
  public getClientAllergyList(
    clientId: Client['id'],
    deserializationArgs: ClientAllergyDeserializationArgs,
  ): Observable<readonly ClientAllergy[] | undefined> {
    return this.httpClient
      .get<
        readonly ClientAllergyApi[] | undefined
      >(`${config.api.url}/clients/${clientId}/allergies`)
      .pipe(
        map((response) =>
          response
            ? ClientAllergy.deserializeList(response, deserializationArgs)
            : undefined,
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update the client allergy for the given client ID and client allergy ID.
   *
   * @param clientId The client ID to patch the allergy for.
   * @param clientAllergyId The client allergy ID to patch.
   * @param clientAllergyUpdate The client allergy update data.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The updated client allergy on success, undefined on error.
   */
  public patchClientAllergy(
    clientId: Client['id'],
    clientAllergyId: ClientAllergy['id'],
    clientAllergyUpdate: ClientAllergyUpdate,
    deserializationArgs: ClientAllergyDeserializationArgs,
  ): Observable<ClientAllergy | undefined> {
    return this.httpClient
      .patch<ClientAllergyApi>(
        `${config.api.url}/clients/${clientId}/allergies/${clientAllergyId}`,
        clientAllergyUpdate.serialize(),
      )
      .pipe(
        map((response) =>
          ClientAllergy.deserialize(response, deserializationArgs),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Create a new client allergy for the given client ID.
   *
   * @param clientId The client ID to create the allergy for.
   * @param clientAllergyUpdate The client allergy update data.
   * @param deserializationArgs The deserialization arguments needed to
   * deserialize the object(s).
   * @returns The created client allergy on success, undefined on error.
   */
  public postClientAllergy(
    clientId: Client['id'],
    clientAllergyUpdate: ClientAllergyUpdate,
    deserializationArgs: ClientAllergyDeserializationArgs,
  ): Observable<ClientAllergy | undefined> {
    return this.httpClient
      .post<ClientAllergyApi>(
        `${config.api.url}/clients/${clientId}/allergies`,
        clientAllergyUpdate.serialize(),
      )
      .pipe(
        map((response) =>
          ClientAllergy.deserialize(response, deserializationArgs),
        ),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }
}

const AllergenPagedListCodec = io.type(
  {
    currentCount: io.number,
    currentPage: io.number,
    pageSize: io.number,
    results: io.readonlyArray(Allergen.Codec),
    totalCount: io.number,
    totalPages: io.number,
  },
  'AllergenPagedListApi',
);

export interface AllergenPagedList {
  readonly currentCount: number;
  readonly currentPage: number;
  readonly pageSize: number;
  readonly results: readonly Allergen[];
  readonly totalCount: number;
  readonly totalPages: number;
}

export interface AllergenRequestParam extends RequestParameter {
  key: 'limit' | 'name' | 'order' | 'pageNumber' | 'pageSize' | 'sort';
  value:
    | keyof AllergenApi // For `sort`.
    | SortOrderEnum // For `order`.
    | string // For `name`.
    | number; // For `limit`, `pageNumber`, and `pageSize`.
}
