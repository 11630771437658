import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BloodPressureArmEnum,
  BloodPressurePositionEnum,
} from 'src/app/enumerators';
import { VitalsBloodPressure } from 'src/app/models';
import { isNonEmptyValue } from 'src/app/utilities';

import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CustomFormGroupDirective } from 'src/app/components/forms/custom-form-group.directive';

/**
 * An input component for an arm & position reading.
 *
 * @requires `formControl` attribute to be set.
 * @requires `label` attribute to be set.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-input-arm-position[formControl][label]',
  templateUrl: './input-arm-position.component.html',
  styleUrls: ['./input-arm-position.component.scss'],
})
export class InputArmPositionComponent
  extends CustomFormGroupDirective<ArmPosition>
  implements OnInit
{
  /**
   * The id of the arm form control, automatically generated if not provided.
   */
  @Input()
  public armId = `alleva-input-arm${++uniqueArmId}`;

  /**
   * The id of the position form control, automatically generated if not provided.
   */
  @Input()
  public positionId = `alleva-input-position${++uniquePositionId}`;

  // Override the following defaults to undefined to show they're unused here.
  public override placeholder = undefined;

  protected override readonly formGroup = new FormGroup<ArmPositionFormGroup>({
    // Sub control for tracking the state of the users arm reading.
    arm: new FormControl<VitalsBloodPressure['arm'] | null>(null, [
      Validators.required,
    ]),
    // Sub control for tracking the state of the users position reading.
    position: new FormControl<VitalsBloodPressure['position'] | null>(null, [
      Validators.required,
    ]),
  });

  protected readonly BloodPressureArmEnum = BloodPressureArmEnum;
  protected readonly BloodPressurePositionEnum = BloodPressurePositionEnum;

  protected readonly ctrlArm = this.formGroup.controls.arm;
  protected readonly ctrlPosition = this.formGroup.controls.position;

  public override async ngOnInit(): Promise<void> {
    super.ngOnInit();

    if (!this.baseControl) {
      throw new Error(
        'InputArmPositionComponent requires a formControl attribute to be set.',
      );
    }

    // Set initial values.
    this.formGroup.setValue({
      arm: this.baseControl.value?.arm ?? null,
      position: this.baseControl.value?.position ?? null,
    });

    // If the base control is not required, then the sub controls should not be required.
    if (!this.baseControl.hasValidator(Validators.required)) {
      this.ctrlArm.removeValidators(Validators.required);
      this.ctrlPosition.removeValidators(Validators.required);
    }

    this.formGroup.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(({ arm, position }) => {
        if (
          isNonEmptyValue(arm) &&
          isNonEmptyValue(position) &&
          this.formGroup.valid
        ) {
          this.baseControl.setValue({ arm, position });
          this.baseControl.markAsDirty();
        } else if (!isNonEmptyValue(arm) && !isNonEmptyValue(position)) {
          this.baseControl.patchValue(null);
          this.baseControl.markAsPristine();
        } else {
          this.baseControl.patchValue(null, { emitEvent: false });
          this.baseControl.markAsPristine();
        }

        // If any values are invalid (cm, ft, in, unit), mark the baseControl as invalid.
        if (this.formGroup.invalid) {
          this.baseControl.setErrors({ required: true }, { emitEvent: false });
          this.baseControl.markAsTouched();
          this.baseControl.markAsDirty();
        } else {
          this.baseControl.setErrors(null, { emitEvent: false });
        }
      });
  }
}

interface ArmPositionFormGroup {
  arm: FormControl<VitalsBloodPressure['arm'] | null>;
  position: FormControl<VitalsBloodPressure['position'] | null>;
}

/** A unique ID for each form field arm input. */
let uniqueArmId = 0;

/** A unique ID for each form field position input. */
let uniquePositionId = 0;
