import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, interval, of, switchMap } from 'rxjs';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

/**
 * A component that displays an animated loading text indicator.
 *
 * @example "Loading", "Loading.", "Loading..", "Loading...", repeat.
 */
@UntilDestroy()
@Component({
  selector: 'alleva-loading-text',
  templateUrl: './loading-text.component.html',
  styleUrls: ['./loading-text.component.scss'],
})
export class LoadingTextComponent implements OnInit {
  /**
   * Await for this value before displaying the loading text.
   *
   * @default Do not await, display the loading text immediately.
   */
  @Input() public set awaitFor(value: AwaitFor | null | undefined) {
    this.awaitForSubject.next(value);
  }
  private readonly awaitForSubject = new BehaviorSubject<
    AwaitFor | undefined | null
  >(undefined);
  protected readonly awaitForChanges = this.awaitForSubject.asObservable();

  /**
   * The "hint" string value to display while loading is true.
   */
  @Input() public awaitForHint?: string;

  /**
   * The maximum amount of time that passes before an error is displayed.
   *
   * @default 180000 Milliseconds (180 seconds, or 3 minutes).
   */
  @Input() public displayErrorAfterMs = 180000;

  /**
   * The `hide-hint-subscript` attribute controlling whether the hint is shown
   * as a subscript.
   *
   * Warning: This may also hide error message "hints" depending.
   */
  @HostBinding('class.hide-hint-subscript')
  @Input()
  public set hideHint(value: BooleanInput) {
    this.#hideHint = coerceBooleanProperty(value);
  }
  public get hideHint(): boolean {
    return this.#hideHint;
  }
  #hideHint = false;

  /**
   * The label to display what is currently being loaded.
   *
   * @default undefined (no label)
   */
  @Input() public label?: string;

  /**
   * The type of loader to display.
   */
  @Input() public type: 'input' | 'text' = 'text';

  protected readonly formControl = new FormControl<string | null>(null);
  protected loadingText = 'Loading';

  /**
   * The amount of time to wait between loading text animation updates.
   */
  private readonly intervalMs = 500;

  public ngOnInit(): void {
    this.formControl.disable();

    /**
     * Every X ms, update the loading text animation.
     * @example "Loading", "Loading.", "Loading..", "Loading...", repeat.
     *
     * If we have to "await" a value before displaying the loading text, then
     * we will only display the loading text if the value is truthy.
     */
    this.awaitForChanges
      .pipe(
        switchMap((awaitFor) =>
          awaitFor === null ? of(awaitFor) : interval(this.intervalMs),
        ),
        untilDestroyed(this),
      )
      .subscribe((intervalMs) => {
        if (intervalMs === null) {
          this.loadingText = this.label ? `${this.label}` : 'Pending...';
        } else if (
          this.loadingText.startsWith('Pending') ||
          this.loadingText === this.label
        ) {
          this.loadingText = 'Loading';
          this.loadingText = this.getNextLoadingText(intervalMs);
        } else {
          this.loadingText = this.getNextLoadingText(intervalMs);
        }

        this.formControl.setValue(this.loadingText);
      });
  }

  private getNextLoadingText(intervalMs: number): string {
    if (intervalMs >= this.displayErrorAfterMs) {
      const errorMessage = this.label
        ? `Error loading "${this.label}"`
        : 'Error loading';
      this.logError(`${errorMessage} input data.`);
      return `${errorMessage}...`;
    } else if (this.loadingText === 'Loading') {
      return this.label ? `Loading "${this.label}".` : 'Loading.';
    } else if (this.loadingText.endsWith('...')) {
      return this.label ? `Loading "${this.label}"` : 'Loading';
    } else {
      return this.loadingText + '.';
    }
  }

  private logError(message: string): void {
    // @todo - Add error to an Alleva logging service (ie app-insights).
    console.warn(message);
  }
}

export type AwaitFor = NonNullable<
  Primitive | readonly Primitive[] | object | readonly object[]
>;
