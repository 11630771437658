import { FacilityFeaturesApi } from 'api/models';
import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

const api = apiDecorator<FacilityFeaturesApi>();

export class FacilityFeatures {
  public constructor(props: ClassProperties<FacilityFeatures>) {
    this.isBillingEnabled = props.isBillingEnabled;
    this.isCollabMDEnabled = props.isCollabMDEnabled;
    this.isDetoxAssessmentsEnabled = props.isDetoxAssessmentsEnabled;
    this.isETTEnabled = props.isETTEnabled;
    this.isNurseEnabled = props.isNurseEnabled;
    this.isRecTherapistEnabled = props.isRecTherapistEnabled;
  }

  public static readonly Codec = io.type(
    {
      isBillingEnabled: io.boolean,
      isCollabMDEnabled: io.boolean,
      isDetoxAssessmentsEnabled: io.boolean,
      isETTEnabled: io.boolean,
      isNurseEnabled: io.boolean,
      isRecTherapistEnabled: io.boolean,
    },
    'FacilityFeaturesApi',
  );

  @api({ key: 'isBillingEnabled' }) public readonly isBillingEnabled: boolean;
  @api({ key: 'isCollabMDEnabled' }) public readonly isCollabMDEnabled: boolean;
  @api({ key: 'isDetoxAssessmentsEnabled' })
  public readonly isDetoxAssessmentsEnabled: boolean;
  @api({ key: 'isETTEnabled' }) public readonly isETTEnabled: boolean;
  @api({ key: 'isNurseEnabled' }) public readonly isNurseEnabled: boolean;
  @api({ key: 'isRecTherapistEnabled' })
  public readonly isRecTherapistEnabled: boolean;

  public static deserialize(
    value: NonNullable<FacilityFeaturesApi>,
  ): FacilityFeatures {
    const decoded = decode(FacilityFeatures.Codec, value);
    return new FacilityFeatures({
      isBillingEnabled: decoded.isBillingEnabled,
      isCollabMDEnabled: decoded.isCollabMDEnabled,
      isDetoxAssessmentsEnabled: decoded.isDetoxAssessmentsEnabled,
      isETTEnabled: decoded.isETTEnabled,
      isNurseEnabled: decoded.isNurseEnabled,
      isRecTherapistEnabled: decoded.isRecTherapistEnabled,
    });
  }
}
