import * as io from 'io-ts';
import { apiDecorator } from 'src/app/decorators';
import { decode } from 'src/app/utilities';

import { AllevaAIPromptApi } from './../../../../api/models/alleva-ai-api.model';

const api = apiDecorator<AllevaAIPromptApi>();

type AllevaAIPromptArgs = Omit<AllevaAIPrompt, ''>;

export class AllevaAIPrompt {
  public constructor(props: ClassProperties<AllevaAIPromptArgs>) {
    this.description = props.description;
    this.prompt = props.prompt;
    this.id = props.id;
    this.title = props.title;
  }

  /**
   * The io-ts codec for runtime type checking of the Alleva AI Prompt API
   * model.
   */
  public static readonly Codec = io.type(
    {
      description: io.union([io.string, io.null]),
      id: io.union([io.number, io.null]),
      prompt: io.union([io.string, io.null]),
      title: io.union([io.string, io.null]),
    },
    'AllevaAIPromptApi',
  );

  @api({ key: 'description' }) public readonly description: string | null;
  @api({ key: 'id' }) public readonly id: number | null;
  @api({ key: 'prompt' }) public readonly prompt: string | null;
  // @api({ key: 'promptNumber' }) public readonly promptNumber: number | null;
  @api({ key: 'title' }) public readonly title: string | null;

  /**
   * Deserializes an Alleva AI Prompt object from the API model.
   *
   * @param value The value to deserialize.
   * @returns The deserialized Alleva AI Prompt object.
   * @throws An error if the value is not a valid Alleva AI Prompt object.
   */
  public static deserialize(
    value: NonNullable<AllevaAIPromptApi>,
  ): AllevaAIPrompt {
    const decoded = decode(AllevaAIPrompt.Codec, value);
    return new AllevaAIPrompt({
      ...decoded,
    });
  }

  /**
   * Deserializes a list of Alleva AI Prompt objects from the API model.
   *
   * @param values The values to deserialize.
   * @returns The deserialized Alleva AI Prompt objects.
   * @throws An error if the values are not an array.
   * @throws An error if any of the values are not valid Alleva AI Prompt
   * objects.
   */
  public static deserializeList(
    values: ReadonlyArray<NonNullable<AllevaAIPromptApi>>,
  ): readonly AllevaAIPrompt[] {
    if (!Array.isArray(values)) {
      throw new Error('Expected array of Alleva AI Prompt objects.');
    }
    return values.map(AllevaAIPrompt.deserialize);
  }
}

export class AllevaAIPromptUpdate extends AllevaAIPrompt {
  public constructor(props: ClassProperties<AllevaAIPromptUpdate>) {
    super(props);
  }

  public serialize(): AllevaAIPromptApi {
    return {
      description: this.description,
      id: this.id,
      prompt: this.prompt,
      title: this.title,
    };
  }
}
