import {
  ReplaySubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  startWith,
} from 'rxjs';
import { Client } from 'src/app/models';
import { FacilityService } from 'src/app/services';
import { getPageRoute, shareSingleReplay } from 'src/app/utilities';

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { isSettingsBillingTabUrl } from 'src/app/pages/settings/billing/is-settings-billing-tab-url';

@Component({
  selector:
    'alleva-menu-sub-level-item[isCollapsed][selectedClient][subLevelMenuItem]',
  templateUrl: './menu-sub-level-item.component.html',
  styleUrls: ['./menu-sub-level-item.component.scss'],
})
export class MenuSubLevelItemComponent {
  public constructor(
    private readonly facilityService: FacilityService,
    private readonly router: Router,
  ) {}

  /** Whether the menu item should use a collapsed (mobile) view. */
  @Input() public set isCollapsed(value: BooleanInput) {
    this.isCollapsedSubject.next(coerceBooleanProperty(value));
  }
  private readonly isCollapsedSubject = new ReplaySubject<boolean>(1);
  protected readonly isCollapsedChanges = this.isCollapsedSubject
    .asObservable()
    .pipe(shareSingleReplay());

  /** The menu item to display. Required. */
  @Input() public subLevelMenuItem!: SubLevelMenuItem;

  /** The currently selected client, if any. */
  @Input() public set selectedClient(value: Client | null) {
    this.selectedClientSubject.next(value);
  }
  private readonly selectedClientSubject = new ReplaySubject<Client | null>(1);
  protected readonly selectedClientChanges =
    this.selectedClientSubject.asObservable();

  protected readonly subMenuItemLinkChanges = combineLatest([
    this.facilityService.currentFacilityChanges.pipe(
      map((facility) => `/facility/${facility.id}`),
      distinctUntilChanged(),
    ),
    this.selectedClientChanges,
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.router.url),
      startWith(this.router.url),
      distinctUntilChanged(),
    ),
  ]).pipe(
    map(([facilityUrl, selectedClient, currentUrl]) => {
      const path = this.subLevelMenuItem.path;
      switch (path) {
        case '/clients/:id':
        case '/clients/:id/calendar':
        case '/clients/:id/discharge-planning':
        case '/clients/:id/doctors-orders':
        case '/clients/:id/document-manager':
        case '/clients/:id/homework':
        case '/clients/:id/meal-plan':
        case '/clients/:id/medication-administration-record':
        case '/clients/:id/medication':
        case '/clients/:id/notes':
        case '/clients/:id/rec-therapy':
        case '/clients/:id/treatment-plan': {
          if (!selectedClient) {
            throw new Error('No selected client found.');
          }
          const clientSpecificPath = path.replace(
            ':id',
            selectedClient.id.toString(),
          );
          return facilityUrl + clientSpecificPath;
        }
        case '/settings/billing':
        case '/settings/billing/billing-codes':
        case '/settings/billing/insurance-companies':
        case '/settings/billing/modifiers':
        case '/settings/billing/rev-codes':
        case '/settings/billing/rules': {
          const baseSettingsBillingUrl: PageRoute = '/settings/billing';

          if (!currentUrl.includes(baseSettingsBillingUrl)) {
            return facilityUrl + path;
          } else if (currentUrl.endsWith(baseSettingsBillingUrl)) {
            return `${facilityUrl + path}/${
              'billing-codes' satisfies SettingsBillingTabUrl
            }`;
          }

          const crudUrls: PageRoute[] = [
            '/settings/billing/rules/add',
            '/settings/billing/rules/:id/copy',
            '/settings/billing/rules/:id/edit',
          ];
          const currentPageRoute = getPageRoute(currentUrl);
          const isCrudUrl = crudUrls.some((url) =>
            currentPageRoute?.endsWith(url),
          );
          if (isCrudUrl) {
            return facilityUrl + baseSettingsBillingUrl;
          }

          const billingRouteId = currentUrl.split('/').pop();
          if (billingRouteId === 'billing') {
            return facilityUrl + path;
          } else if (isSettingsBillingTabUrl(billingRouteId)) {
            return currentUrl;
          } else {
            throw new Error('Invalid billing route id:' + billingRouteId);
          }
        }
        default: {
          return facilityUrl + path;
        }
      }
    }),
    shareSingleReplay(),
  );

  protected readonly tooltipDisplayChanges = this.isCollapsedChanges.pipe(
    map((isCollapsed) => {
      if (!isCollapsed) {
        return '';
      }

      return 'Go to the "' + this.subLevelMenuItem.label + '" page.';
    }),
  );
}
