import { RevenueCodeApi, RevenueCodePagedListApi } from 'api/models';
import { Observable, catchError, map, of } from 'rxjs';
import {
  RevenueCode,
  RevenueCodeCreate,
  RevenueCodeUpdate,
} from 'src/app/models';
import { PagedListCodec, decode, parseHttpParams } from 'src/app/utilities';
import { config } from 'src/configs/config';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * A service for interacting with the Revenue Codes API.
 */
@Injectable()
export class RevenueCodeService {
  public constructor(private readonly httpClient: HttpClient) {}

  /**
   * Fetches a list of revenue codes based on the request parameters, or all revenue codes if no parameters are provided.
   *
   * @param requestParameters The list of query parameters to use for the paged
   * request.
   * @returns A list of revenue codes. Undefined on error.
   */
  public getPagedList(
    requestParameters?: readonly RevenueCodePagedListParam[],
  ): Observable<PagedList<RevenueCode> | undefined> {
    if (
      requestParameters?.some(
        (param) => param.key === 'active' && param.value === true,
      ) &&
      requestParameters?.some(
        (param) => param.key === 'active' && param.value === false,
      )
    ) {
      requestParameters = requestParameters.filter(
        (param) => param.key !== 'active',
      );
    }

    return this.httpClient
      .get<
        RevenueCodePagedListApi | undefined
      >(`${config.api.url}/billing/revenue-codes`, { params: parseHttpParams(requestParameters) })
      .pipe(
        map((response) => {
          if (response === undefined) {
            return undefined;
          }

          const revenueCodePagedList: PagedList<RevenueCode> = {
            ...decode(RevenueCodePagedListCodec, response),
            results: RevenueCode.deserializeList(response.results),
          };
          return revenueCodePagedList;
        }),
        catchError((error: unknown) => {
          console.error(error);
          return of(undefined);
        }),
      );
  }

  /**
   * Update a revenue code.
   *
   * @param revenueCodeUpdate The revenue code update data.
   * @returns The successfully updated revenue code, or undefined on error.
   */
  public put(
    revenueCodeUpdate: RevenueCodeUpdate,
  ): Observable<RevenueCode | HttpErrorResponseApi> {
    return this.httpClient
      .put<RevenueCodeApi>(
        `${config.api.url}/billing/revenue-codes/${revenueCodeUpdate.id}`,
        revenueCodeUpdate.serialize(),
        { observe: 'response' },
      )
      .pipe(
        map((response) => {
          if (!response.body) {
            throw new Error('Rev Code PUT response from API is empty!');
          }
          return RevenueCode.deserialize(response.body);
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of(error);
          }
          throw error;
        }),
      );
  }

  /**
   * Create a new Revenue Code.
   *
   * @param revenueCodeCreate The revenue code to post.
   * @returns The successfully updated revenue code, or undefined on error.
   */
  public post(
    revenueCodeCreate: RevenueCodeCreate,
  ): Observable<RevenueCode | HttpErrorResponseApi> {
    return this.httpClient
      .post<RevenueCodeApi>(
        `${config.api.url}/billing/revenue-codes`,
        revenueCodeCreate.serialize(),
        { observe: 'response' },
      )
      .pipe(
        map((response) => {
          if (!response.body) {
            throw new Error('Rev Code POST response from API is empty!');
          }
          return RevenueCode.deserialize(response.body);
        }),
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            return of(error);
          }
          throw error;
        }),
      );
  }
}

const RevenueCodePagedListCodec = PagedListCodec(
  RevenueCode.Codec,
  'RevenueCodePagedListApi',
);

/**
 * Request parameter interface for use with the Revenue Code API.
 */
export interface RevenueCodePagedListParam extends RequestParameter {
  /** The list of query parameter keys available for use. */
  key: 'active' | 'facilityId' | 'order' | 'pageNumber' | 'pageSize' | 'search';
  /** The value to use for the query parameter. */
  value: boolean | number | string;
}
