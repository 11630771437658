/** The unique resource identifier for a route resource used globally in routing. */
const id: RouteResourceIdentifier = ':id';

/**
 * Single source of truth for all the page route URL's in the application as a
 * string array.
 *
 * Note: Routes cannot contain numbers. Numbers in routes are reserved for
 * dynamic identifiers (id's).
 */
export const ALLEVA_ROUTE_URLS = (
  [
    '/alumni',
    '/billing-offsite',
    '/calendar/historical-events',
    '/calendar/medical-provider-scheduler',
    '/calendar/scheduler',
    '/chef-dashboard',
    `/clients/${id}/admission-screening`,
    `/clients/${id}/billing-intake`,
    `/clients/${id}/biopsychosocial-intake`,
    `/clients/${id}/calendar`,
    `/clients/${id}/discharge-planning`,
    `/clients/${id}/doctors-orders`,
    `/clients/${id}/document-manager`,
    `/clients/${id}/edit`,
    `/clients/${id}/face-to-face`,
    `/clients/${id}/homework`,
    `/clients/${id}/meal-plan`,
    `/clients/${id}/medical-intake`,
    `/clients/${id}/medication-administration-record`,
    `/clients/${id}/medication`,
    `/clients/${id}/notes`,
    `/clients/${id}/rec-therapy`,
    `/clients/${id}/treatment-plan`,
    `/clients/${id}`,
    '/clients/list',
    '/dashboard',
    '/doctors-orders',
    '/encounter-transmission',
    '/forbidden',
    '/incidents',
    '/lab-orders',
    '/medications/count',
    '/medications/destruction',
    '/medications/management',
    '/not-found',
    '/notifications',
    '/occupancy',
    '/progress-notes',
    '/prospective/add',
    '/prospective/list',
    '/referrals',
    '/reports/discharge-reports',
    '/reports/insights',
    '/reports/meds-count-reports',
    '/restricted-access',
    '/review-screen/notes-audit',
    '/review-screen/notes-review',
    '/review-screen/signature-review',
    '/sandbox/buttons',
    '/sandbox/editor',
    '/sandbox/forms',
    '/sandbox/icons',
    '/sandbox/loading-indicators',
    '/sandbox/messages',
    '/sandbox/print',
    '/sandbox/signatures',
    '/sandbox/tables',
    '/settings/admission-checklist',
    '/settings/alerts-frequency',
    '/settings/alerts-permission',
    '/settings/alerts-template',
    '/settings/alleva-ai',
    '/settings/beds',
    '/settings/billing',
    '/settings/billing/billing-codes',
    '/settings/billing/rules',
    '/settings/billing/rules/add',
    `/settings/billing/rules/${id}/copy`,
    `/settings/billing/rules/${id}/edit`,
    '/settings/billing/insurance-companies',
    '/settings/billing/modifiers',
    '/settings/billing/rev-codes',
    '/settings/client-transfer',
    '/settings/company-information',
    '/settings/daily-questionnaire',
    '/settings/dietary',
    '/settings/digital-dashboard',
    '/settings/discharge',
    '/settings/family-portal',
    '/settings/forms',
    '/settings/forms-two',
    '/settings/advanced-forms',
    '/settings/groups',
    '/settings/intakes',
    '/settings/labs',
    '/settings/master-dropdowns',
    '/settings/medical-providers',
    '/settings/office-templates',
    '/settings/orders',
    '/settings/pharmacies',
    '/settings/profile',
    '/settings/roles',
    '/settings/surveys',
    '/settings/templates',
    '/settings/treatment-plan-review',
    '/settings/users',
    '/shifts/shift-notes',
    '/shifts/shift-rounds',
    '/utilization-review',
  ] as const
)
  // Ensure data integrity of the route URLs.
  .map((route) => {
    const containsNumberRegExp = /\d+/;
    // If the string contains a number, throw an error. We don't need to worry
    // about a `404` route for SEO either since our application is behind a
    // login, we use `not-found` for that.
    if (containsNumberRegExp.test(route)) {
      throw new Error(
        `URL "${route}" should not contain a number. ` +
          `Numbers in routes are reserved for the dynamic identifier "${id}".`,
      );
    }
    return route;
  });
